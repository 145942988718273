import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  products: [],
  products2: [],
  loading: false,
  error: "",
}
export const productListSlice = createSlice({
  name: "productlist",
  initialState,
  reducers: {
    //product List
    product_List_Init: (state) => {
      return { ...state, loading: true }
    },
    product_List_Success: (state, action) => {
      return { ...state, loading: false, products: action.payload }
    },
    product_List_Success2: (state, action) => {
      return { ...state, loading: false, products2: action.payload }
    },
    product_List_Fail: (state, action) => {
      return { ...state, loading: false, error: action.payload }
    },
  },
})

export const {
  //product List
  product_List_Init,
  product_List_Success,
  product_List_Success2,
  product_List_Fail,
} = productListSlice.actions
export default productListSlice.reducer
