import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	item: '',
	loading: false,
	guessItems: '',
}
export const updateGuesstEmailSlice = createSlice({
	name: 'updateGuesstEmail',
	initialState,
	reducers: {
		//product List
		updateGuesstEmail_Add_Item: (state, action) => {
			const item = action.payload

			return {
				...state,
				guessItems: [...state.guessItems, item],
			}
		},
		updateGuesstEmail_Remove_Item: (state) => {
			state.guessItems.splice(0, 1)
			return { ...state, guessItems: state.guessItems }
		},
		updateGuesstEmail_Clear_Item: (state) => {
			return {
				...state,
				guessItems: [],
			}
		},
	},
})
export const {
	//product List
	updateGuesstEmail_Add_Item,
	updateGuesstEmail_Remove_Item,
	updateGuesstEmail_Clear_Item,
} = updateGuesstEmailSlice.actions
export default updateGuesstEmailSlice.reducer
