import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	error: false,
	msssuccess: false,
	userInfo: '',
}
export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		login_Init: (state) => {
			return { ...state, loading: true }
		},
		login_Succes: (state, action) => {
			return { ...state, loading: false, userInfo: action.payload }
		},
		login_Failt: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		login_MssSucces: (state, action) => {
			return { ...state, loading: false, msssuccess: action.payload }
		},
		logout_Auth: (state) => {
			return {}
		},
	},
})

export const {
	login_Init,
	login_Succes,
	login_Failt,
	logout_Auth,
	login_MssSucces,
} = userSlice.actions
export default userSlice.reducer
