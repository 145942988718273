import React from "react"
import Backdrop from "@mui/material/Backdrop"
import Typography from "@mui/material/Typography"
// import logo from "../../img/Taboo_Beachclub_Logo_Editable.png";
import logo from "../../img/LogoHeaderV2.png"

import Lottie from "lottie-react"
import Loader from "../../Lottie/Loading.json"

const style = {
  height: 300,
}

const LoadingSpinnner = (props) => {
  return (
    <div>
      <Backdrop
        sx={{ zIndex: "9999", backgroundColor: "rgba(0, 0, 0, 0.9)" }}
        open={props.open}
        // open="true"
      >
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "350px", height: "80px", marginBottom: "-80px" }}
          />
          <Lottie
            animationData={Loader}
            loop={true}
            autoplay={true}
            style={style}
          />
        </div>
      </Backdrop>
    </div>
  )
}
export default LoadingSpinnner
