import { PRODUCT_PACK_BY_IDZONA } from '../Constants/routesConstants'
import actions from '../Components/AxiosCalls/AxiosAuth'

import {
	cartPacks_Add_Item,
	cartPacks_Remove,
	cartPacks_Remove_Item,
	cartPacks_Clear_Items,
} from '../Slices/CartPacksSlice/cartPacksSlice'
// import {
// 	cartPacksRemove_Add_Item,
// 	cartPacksRemove_Remove_Item,
// 	cartPacksRemove_Remove,
// 	cartPacksRemove_Clear_Items,
// } from '../Slices/CartPacksSlice/cartPacksRemoveSlice.js'

export const addToCartPacks =
	(id, qty, Fulldate) => async (dispatch, getState) => {
		let todayDate =
			Fulldate.getFullYear() +
			'-' +
			String(Fulldate.getMonth() + 1).padStart(2, '0') +
			'-' +
			String(Fulldate.getDate()).padStart(2, '0')
		//dispatch(cartPacks_Clear_Items())
		actions
			.get(PRODUCT_PACK_BY_IDZONA, id)
			.then((response) => {
				const data = {
					productId: response.data.lngIdPaquetes,
					Nombre: response.data.txtNombrePaquete,
					precio: response.data.dblPrecio,
					countInStock: response.data.numStock,
					qty,
					fechCompra: todayDate,
					descripcion: response.data.txtDescripCorta,
				}

				dispatch(cartPacks_Add_Item(data))

				sessionStorage.setItem(
					'cartPacksItems',
					JSON.stringify(getState().cartPacks.cartItemsPacks)
				)
			})
			.catch((error) => {})
	}

export const removeFromCartPacks = (id) => (dispatch, getState) => {
	dispatch(cartPacks_Remove(id))

	sessionStorage.setItem(
		'cartPacksItems',
		JSON.stringify(getState().cartPacks.cartItemsPacks)
	)
}

export const removeFromCartitemPacks =
	(id, qty) => async (dispatch, getState) => {
		if (qty === 1) {
			dispatch(cartPacks_Remove(id))
		}
		dispatch(cartPacks_Remove_Item(id))

		sessionStorage.setItem(
			'cartPacksItems',
			JSON.stringify(getState().cartPacks.cartItemsPacks)
		)
	}
export const cleanCartPacks = () => (dispatch) => {
	dispatch(cartPacks_Clear_Items())
	sessionStorage.removeItem('cartPacksItems')
}
