
import React, {useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref){
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

export default function MySnackBar(props){
    //const classes = useStyles();
    const [state] = useState({
        vertical:'top',
        horizontal: 'center',
    });
    const {vertical, horizontal} = state;

    return(
        <div style={{width: '100%'}}>
            <Snackbar maxSnack={3} anchorOrigin={{vertical, horizontal}} open={props.Open} autoHideDuration={3500} onClose={props.HandleClose} style={{zIndex:3000}}>
                <Alert onClose={props.HandleClose} severity={props.Type}>
                    {props.Message}
                </Alert>
            </Snackbar>
        </div>
    );
}

