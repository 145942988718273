/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { Suspense, lazy, useState } from "react"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import Link from "@mui/material/Link"
import "../Styles/StyleFooter.css"
import logoGrupoRN from "../img/logos-footer/GrupoRosaNegra.webp"
import logoRNdayclub from "../img/logos-footer/RosaNegraBeachClub.webp"
import logoRN from "../img/logos-footer/RosaNegra.webp"
// import logodelivery from '../img/logos-footer/Delivery.webp'
import logoCaprice from "../img/logos-footer/Caprice.webp"
import logoCraftPositivo from "../img/logos-footer/CraftPositivo.webp"
import logoTora from "../img/logos-footer/Tora.webp"
import logoTaboo from "../img/logos-footer/Taboo.webp"
import logoTabooBC from "../img/logos-footer/Taboo-Beach-Club.webp"
// import logoParole from '../img/logos-footer/Parole.webp'
import logoParole from "../img/logos-footer/LargeParole.webp"
import logoTantra from "../img/logos-footer/Tantra.webp"
import logoChamabao from "../img/logos-footer/Chambao.webp"
import logoFunky from "../img/logos-footer/Funky.webp"
import logoMamazzita from "../img/logos-footer/Mamazzita.webp"
import logoConfessions from "../img/logos-footer/Confessions.webp"
import logoLargeTamara from "../img/logos-footer/LargeTamara.webp"
// import CarrouselFooter from '../Components/CarrouselFooter'
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { useTranslation } from "react-i18next"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Button from "@mui/material/Button"

const CarrouselFooter = lazy(() => import("../Components/CarrouselFooter"))

const Footer = () => {
  const [t] = useTranslation("global")

  //	::::::::::::::::::::::::::::::::::::::  Estados De Tabs
  const [tabIndex, setTabIndex] = useState(0)
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex)
    setTimeout(() => {
      window.scrollTo(0, 1300)
    }, 500)
  }
  const handleTabClick = (event) => {
    if (event === tabIndex) {
      setTabIndex(0)
    }
  }
  return (
    // <div>
    //   <button>hola</button>
    // </div>
    <div className="container-f">
      {/* {window.screen.width <= 600 ? (
    		<>
    			<Suspense fallback={<div></div>}>
    				<div className='container-img-footer'>
    					<CarrouselFooter />
    				</div>
    			</Suspense>
    		</>
    	) : ( */}
      <>
        <div className="container-img-footer1"></div>

        <div className="container-img-footer">
          <div className="container-header-rn">
            <div className="container-logoRN">
              <LazyLoadImage
                src={logoGrupoRN}
                className="logoRN"
                alt="Grupo RosaNegra"
                title="Grupo RosaNegra"
              />
            </div>
          </div>

          <div className="container-partner">
            <a
              href="https://rosanegra.com.mx/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoRN}
                className="logoPR"
                alt="RosaNegra"
                title="RosaNegra"
              />
            </a>

            <a
              href="https://www.parole.mx/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoParole}
                className="logoPR"
                alt="Parole"
                title="Parole"
              />
            </a>

            <a
              href="https://torarestaurant.com.mx/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoTora}
                className="logoPR"
                alt="Tora"
                title="Tora"
              />
            </a>

            <a
              href="https://taboorestaurant.com.mx/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoTaboo}
                className="logoPR"
                alt="Taboo Restaurant"
                title="Taboo Restaurant"
              />
            </a>

            <a
              href="https://chambaorestaurant.com/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoChamabao}
                className="logoPR"
                alt="Chambao"
                title="Chambao"
              />
            </a>

            <a
              href="https://mamazzita.com.mx/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoMamazzita}
                className="logoPR"
                alt="Mamazzita"
                title="Mamazzita"
              />
            </a>
            <a
              // href="https://capricerestaurant.com/es"
              href="https://www.craftrestaurant.com.mx/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                // src={logodelivery}
                src={logoCraftPositivo}
                className="logoPR"
                alt="Craft"
                title="Craft"
              />
            </a>

            <a
              href="https://confessions.com.mx/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoConfessions}
                className="logoPR"
                alt="Confessions"
                title="Confessions"
              />
            </a>

            <a
              href="https://funkygeisha.com.mx/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoFunky}
                className="logoPR"
                alt="Funky Geisha"
                title="Funky Geisha"
              />
            </a>

            <a
              href="https://rosanegrabeachclub.com/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoRNdayclub}
                className="logoPR"
                alt="RosaNegra Beach Day Club"
                title="RosaNegra Beach Day Club"
              />
            </a>

            <a
              href="https://tantrabeachclub.com/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoTantra}
                className="logoPR"
                alt="Tantra"
                title="Tantra"
              />
            </a>
            <a
              href="https://taboobeachclub.com.mx/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoTabooBC}
                className="logoPR"
                alt="RosaNegra Beach Club"
                title="RosaNegra Beach Club"
              />
            </a>

            <a
              href="https://tamarabeachclub.com/es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={logoLargeTamara}
                className="logoPR"
                alt="Tamara Beach"
                title="Tamara Beach"
              />
            </a>

            {/* <div className='flex'>

    					</div>

    					<div className='flex'>
    					</div> */}
          </div>

          <div className="flex-column mt-30">
            <div className="flex mb2">
              <Link
                href="https://facebook.com/rosanegrabeachtulum"
                alt="dsd"
                target="_blank"
              >
                <FacebookIcon sx={{ fontSize: 40, color: "#ffffff", mr: 5 }} />
              </Link>
              <Link
                href="https://www.instagram.com/rosanegrabeach_tulum"
                alt="dsd"
                target="_blank"
              >
                <InstagramIcon sx={{ fontSize: 40, color: "#ffffff", mr: 5 }} />
              </Link>
              <Link
                href="https://www.tripadvisor.com.mx/Restaurant_Review-g23240074-d23780639-Reviews-RosaNegra_Beach_Tulum-Tulum_Beach_Tulum_Yucatan_Peninsula.html"
                alt="dsd"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50px"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 2304 1408"
                >
                  <path
                    fill="#ffffff"
                    d="M651 805q0 39-27.5 66.5T558 899q-39 0-66.5-27.5T464 805q0-38 27.5-65.5T558 712q38 0 65.5 27.5T651 805zm1154-1q0 39-27.5 66.5T1711 898t-66.5-27.5T1617 804t27.5-66t66.5-27t66.5 27t27.5 66zm-1040 1q0-79-56.5-136T572 612t-136.5 56.5T379 805t56.5 136.5T572 998t136.5-56.5T765 805zm1153-1q0-80-56.5-136.5T1725 611q-79 0-136 56.5T1532 804t56.5 136.5T1725 997t136.5-56.5T1918 804zm-1068 1q0 116-81.5 197.5T572 1084q-116 0-197.5-82T293 805t82-196.5T572 527t196.5 81.5T850 805zm1154-1q0 115-81.5 196.5T1725 1082q-115 0-196.5-81.5T1447 804t81.5-196.5T1725 526q116 0 197.5 81.5T2004 804zm-964 3q0-191-135.5-326.5T578 345q-125 0-231 62T179 575.5T117 807t62 231.5T347 1207t231 62q191 0 326.5-135.5T1040 807zm668-573q-254-111-556-111q-319 0-573 110q117 0 223 45.5T984.5 401t122 183t45.5 223q0-115 43.5-219.5t118-180.5T1491 284t217-50zm479 573q0-191-135-326.5T1726 345t-326.5 135.5T1264 807t135.5 326.5T1726 1269t326-135.5T2187 807zm-266-566h383q-44 51-75 114.5T2189 470q110 151 110 337q0 156-77 288t-209 208.5t-287 76.5q-133 0-249-56t-196-155q-47 56-129 179q-11-22-53.5-82.5T1024 1168q-80 99-196.5 155.5T578 1380q-155 0-287-76.5T82 1095T5 807q0-186 110-337q-9-51-40-114.5T0 241h365Q514 141 720 84.5T1152 28q224 0 421 56t348 157z"
                  />
                </svg>
              </Link>
            </div>
            {/* <div className="flex">
              {window.screen.width <= 600 ? (
                <>
                  <Box>
                    <Tabs
                      value={tabIndex}
                      variant="fullWidth"
                      onChange={handleTabChange}
                    >
                      <div></div>
                      <Tab
                        onClick={() => handleTabClick(1)}
                        icon={
                          <FacebookIcon
                            sx={{ fontSize: 40, color: "#ffffff", mr: 1 }}
                          />
                        }
                      />
                      <Tab
                        onClick={() => handleTabClick(2)}
                        icon={
                          <InstagramIcon
                            sx={{ fontSize: 40, color: "#ffffff", mr: 1 }}
                          />
                        }
                      />
                      <Tab
                        onClick={() => handleTabClick(3)}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50px"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 2304 1408"
                          >
                            <path
                              fill="#ffffff"
                              d="M651 805q0 39-27.5 66.5T558 899q-39 0-66.5-27.5T464 805q0-38 27.5-65.5T558 712q38 0 65.5 27.5T651 805zm1154-1q0 39-27.5 66.5T1711 898t-66.5-27.5T1617 804t27.5-66t66.5-27t66.5 27t27.5 66zm-1040 1q0-79-56.5-136T572 612t-136.5 56.5T379 805t56.5 136.5T572 998t136.5-56.5T765 805zm1153-1q0-80-56.5-136.5T1725 611q-79 0-136 56.5T1532 804t56.5 136.5T1725 997t136.5-56.5T1918 804zm-1068 1q0 116-81.5 197.5T572 1084q-116 0-197.5-82T293 805t82-196.5T572 527t196.5 81.5T850 805zm1154-1q0 115-81.5 196.5T1725 1082q-115 0-196.5-81.5T1447 804t81.5-196.5T1725 526q116 0 197.5 81.5T2004 804zm-964 3q0-191-135.5-326.5T578 345q-125 0-231 62T179 575.5T117 807t62 231.5T347 1207t231 62q191 0 326.5-135.5T1040 807zm668-573q-254-111-556-111q-319 0-573 110q117 0 223 45.5T984.5 401t122 183t45.5 223q0-115 43.5-219.5t118-180.5T1491 284t217-50zm479 573q0-191-135-326.5T1726 345t-326.5 135.5T1264 807t135.5 326.5T1726 1269t326-135.5T2187 807zm-266-566h383q-44 51-75 114.5T2189 470q110 151 110 337q0 156-77 288t-209 208.5t-287 76.5q-133 0-249-56t-196-155q-47 56-129 179q-11-22-53.5-82.5T1024 1168q-80 99-196.5 155.5T578 1380q-155 0-287-76.5T82 1095T5 807q0-186 110-337q-9-51-40-114.5T0 241h365Q514 141 720 84.5T1152 28q224 0 421 56t348 157z"
                            />
                          </svg>
                        }
                      />
                    </Tabs>
                  </Box>
                </>
              ) : (
                <Box>
                  <Tabs
                    value={tabIndex}
                    variant=""
                    onChange={handleTabChange}
                    onClick={() => handleTabClick()}
                  >
                    <div></div>
                    <Tab
                      onClick={() => handleTabClick(1)}
                      icon={
                        <FacebookIcon sx={{ fontSize: 30, color: "#ffffff" }} />
                      }
                    />
                    <Tab
                      onClick={() => handleTabClick(2)}
                      icon={
                        <InstagramIcon
                          sx={{ fontSize: 30, color: "#ffffff" }}
                        />
                      }
                    />
                    <Tab
                      onClick={() => handleTabClick(3)}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40px"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 2304 1408"
                        >
                          <path
                            fill="#ffffff"
                            d="M651 805q0 39-27.5 66.5T558 899q-39 0-66.5-27.5T464 805q0-38 27.5-65.5T558 712q38 0 65.5 27.5T651 805zm1154-1q0 39-27.5 66.5T1711 898t-66.5-27.5T1617 804t27.5-66t66.5-27t66.5 27t27.5 66zm-1040 1q0-79-56.5-136T572 612t-136.5 56.5T379 805t56.5 136.5T572 998t136.5-56.5T765 805zm1153-1q0-80-56.5-136.5T1725 611q-79 0-136 56.5T1532 804t56.5 136.5T1725 997t136.5-56.5T1918 804zm-1068 1q0 116-81.5 197.5T572 1084q-116 0-197.5-82T293 805t82-196.5T572 527t196.5 81.5T850 805zm1154-1q0 115-81.5 196.5T1725 1082q-115 0-196.5-81.5T1447 804t81.5-196.5T1725 526q116 0 197.5 81.5T2004 804zm-964 3q0-191-135.5-326.5T578 345q-125 0-231 62T179 575.5T117 807t62 231.5T347 1207t231 62q191 0 326.5-135.5T1040 807zm668-573q-254-111-556-111q-319 0-573 110q117 0 223 45.5T984.5 401t122 183t45.5 223q0-115 43.5-219.5t118-180.5T1491 284t217-50zm479 573q0-191-135-326.5T1726 345t-326.5 135.5T1264 807t135.5 326.5T1726 1269t326-135.5T2187 807zm-266-566h383q-44 51-75 114.5T2189 470q110 151 110 337q0 156-77 288t-209 208.5t-287 76.5q-133 0-249-56t-196-155q-47 56-129 179q-11-22-53.5-82.5T1024 1168q-80 99-196.5 155.5T578 1380q-155 0-287-76.5T82 1095T5 807q0-186 110-337q-9-51-40-114.5T0 241h365Q514 141 720 84.5T1152 28q224 0 421 56t348 157z"
                          />
                        </svg>
                      }
                    />
                  </Tabs>
                </Box>
              )}
            </div> */}
            <div>
              <p className="p-footer">
                ROSANEGRA BEACH CLUB © 2022. {t("footer.Reserved")}
              </p>

              <p className="p-footer">
                {t("footer.Developed")}
                <span
                  className="a-footer"
                  // target='_blank'
                  // href='https://fullpass.com.mx/'
                  // href=''
                  // rel='nofollow'
                >
                  &nbsp; FULLPASS
                </span>
              </p>
            </div>
          </div>

          {/* <Box className="w70 w70Footer flexAuto">
            {tabIndex === 1 && (
              <div className="footer-Icons mb5 blue" id="section1">
                <div className="footer-Icons-Header">
                  <span>FACEBOOK</span>
                </div>
                <div className="footer-Icons-Body">
                  <Link
                    href="https://www.facebook.com/TabooTulum"
                    underline="none"
                    color="inherit"
                    target="_blank"
                  >
                    Tulum | &nbsp;
                  </Link>
                  <Link
                    href="https://www.facebook.com/tabooloscabos"
                    underline="none"
                    color="inherit"
                    target="_blank"
                  >
                    Los Cabos | &nbsp;
                  </Link>
                  <Link
                    href="https://www.facebook.com/tabooislamujeres/"
                    underline="none"
                    color="inherit"
                    target="_blank"
                  >
                    Isla Mujeres
                  </Link>
                </div>
              </div>
            )}
            {tabIndex === 2 && (
              <div className="footer-Icons mb5">
                <div className="footer-Icons-Header">
                  <span>INSTAGRAM</span>
                </div>
                <div className="footer-Icons-Body">
                  <Link
                    href="https://www.instagram.com/TABOOTULUM/"
                    underline="none"
                    color="inherit"
                    target="_blank"
                  >
                    Tulum | &nbsp;
                  </Link>
                  <Link
                    href="https://www.instagram.com/tabooloscabos/"
                    underline="none"
                    color="inherit"
                    target="_blank"
                  >
                    Los Cabos | &nbsp;
                  </Link>
                  <Link
                    href="https://www.instagram.com/tabooislamujeres/"
                    underline="none"
                    color="inherit"
                    target="_blank"
                  >
                    Isla Mujeres
                  </Link>
                </div>
              </div>
            )}
            {tabIndex === 3 && (
              <div className="footer-Icons mb5">
                <div className="footer-Icons-Header">
                  <span>TRIPADVISOR</span>
                </div>
                <div className="footer-Icons-Body">
                  <Link
                    href="https://www.tripadvisor.com/Restaurant_Review-g150813-d15352519-Reviews-Taboo-Tulum_Yucatan_Peninsula.html"
                    underline="none"
                    color="inherit"
                    target="_blank"
                  >
                    Tulum | &nbsp;
                  </Link>
                  <Link
                    href="https://www.tripadvisor.com/Restaurant_Review-g152515-d23653105-Reviews-Taboo_Cabo-Cabo_San_Lucas_Los_Cabos_Baja_California.html"
                    underline="none"
                    color="inherit"
                    target="_blank"
                  >
                    Los Cabos | &nbsp;
                  </Link>
                  <Link
                    href="https://www.tripadvisor.com/Restaurant_Review-g150810-d25153958-Reviews-Taboo_Isla_Mujeres-Isla_Mujeres_Yucatan_Peninsula.html"
                    underline="none"
                    color="inherit"
                    target="_blank"
                  >
                    Isla Mujeres
                  </Link>
                </div>
              </div>
            )}
          </Box> */}
        </div>
      </>
    </div>
  )
}

export default Footer
