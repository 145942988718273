import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import requests from '../AxiosCalls/AxiosCall'
import Button from '@mui/material/Button'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { LIST_DISCOUNT_BY_ID } from '../../Constants/routesConstants'
import { useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		// backgroundColor: theme.palette.common.white,
		// color: '#545454',
		backgroundColor: '#544331',
		color: '#FFFFFF',
		textTransform: 'uppercase',
		fontWeight: '600',
		fontSize: window.screen.width <= 600 ? 9 : null,
		border: 0,
	},
	[`&.${tableCellClasses.body}`]: {
		color: '#545454',
		padding: window.screen.width <= 600 ? 13 : null,
		fontSize: window.screen.width <= 600 ? 8 : null,
		border: 0,
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}))

const Discounts = () => {
	const [t, i18n] = useTranslation('global')
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(5)
	const userLogin = useSelector((state) => state.user)
	const { userInfo } = userLogin
	const [_discount, _setDiscount] = useState([])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const getRegistros = () => {
		requests
			.get(LIST_DISCOUNT_BY_ID + userInfo.id)
			.then((response) => {
				_setDiscount(response)
			})
			.catch((error) => {})
	}
	useEffect(() => {
		getRegistros()
	}, [])

	const columns = [
		{
			id: 'discount',
			label: t('Profile-Discounts.Discount'),
			minWidth: window.screen.width <= 600 ? 45 : 80,
		},
		{
			id: 'limit',
			label: t('Profile-Discounts.Limit'),
			minWidth: window.screen.width <= 600 ? 40 : 100,
		},
		{
			id: 'discountcode',
			label: t('Profile-Discounts.Discountcode'),
			minWidth: window.screen.width <= 600 ? 65 : 100,
		},
	]
	function createData(discount, limit, discountcode) {
		return { discount, limit, discountcode }
	}
	return (
		<div className='ContainerTableBilling animated fadeIn fast'>
			<p className='text-center font-weight6 my1'>
				{t('Profile-Discounts.Title')}
			</p>
			<Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
				{_discount.length === 0 ? (
					<Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
						<p className='text-center font-weight6 my1'>
							{t('Profile-Discounts.NoDiscountsList')}
						</p>
					</Paper>
				) : (
					<TableContainer sx={{ maxHeight: 440 }}>
						<Table stickyHeader aria-label='sticky table'>
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<StyledTableCell
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
										>
											<span>{column.label}</span>
										</StyledTableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{_discount
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<StyledTableRow
												hover
												role='checkbox'
												tabIndex={-1}
												key={row.lngDescuentoId}
												className='font-weight6'
											>
												<StyledTableCell>{row.dblPrcentaje} %</StyledTableCell>
												<StyledTableCell>{row.fecLimite}</StyledTableCell>
												<StyledTableCell>
													<Tooltip title='Copiar'>
														<Button
															className='chip-succes'
															size='small'
															endIcon={
																<ContentCopyIcon sx={{ color: '#007420' }} />
															}
															onClick={() =>
																navigator.clipboard.writeText(row.txtCodigo)
															}
														>
															{row.txtCodigo}
														</Button>
													</Tooltip>
												</StyledTableCell>
											</StyledTableRow>
										)
									})}
							</TableBody>
						</Table>
					</TableContainer>
				)}
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component='div'
					count={_discount.length}
					// count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		
		</div>
	)
}

export default Discounts
