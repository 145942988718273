import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	error: false,
	msssuccess: false,
	userInfo: '',
}
export const applyCouponSlice = createSlice({
	name: 'applyCoupon',
	initialState,
	reducers: {
		applyCoupon_MssSucces: (state, action) => {
			return { ...state, loading: false, msssuccess: action.payload }
		},
	},
})

export const { applyCoupon_MssSucces } = applyCouponSlice.actions
export default applyCouponSlice.reducer
