import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	userInfo: '',
	error: '',
	msssuccess: false,
}

export const userConfirmEmailSlice = createSlice({
	name: 'userConfirmation',
	initialState,
	reducers: {
		userConfirmation_Init: (state) => {
			return { ...state, loading: true }
		},
		userConfirmation_Success: (state, action) => {
			return { ...state, loading: false, userInfo: action.payload }
		},
		userConfirmation_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		userConfirmation_MssSucces: (state, action) => {
			return { ...state, loading: false, msssuccess: action.payload }
		},
	},
})

export const {
	userConfirmation_Init,
	userConfirmation_Success,
	userConfirmation_Fail,
	userConfirmation_MssSucces,
} = userConfirmEmailSlice.actions
export default userConfirmEmailSlice.reducer
