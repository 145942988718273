/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { useTranslation } from 'react-i18next'
import '../../Styles/Modals/ModalDiscount.css'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})
 
const ModalDiscount = (props) => {
	// const dispatch = useDispatch()
	const [t, i18n] = useTranslation('global')

	const handleClose = () => {
		props.handleCloseAlertDiscount()
	}

	return (
		<div>
			<Dialog
				fullScreen
				open={props.openAlertDiscount}
				onClose={
					props.handleCloseAlertDiscount ? props.handleCloseAlertDiscount : null
				}
				TransitionComponent={Transition}
				PaperProps={{
					style: {
						background:
							window.screen.width <= 600
								? 'linear-gradient(38deg, rgba(0, 0, 0, 0.36) 30%, rgb(0 0 0 / 31%) 100%)'
								: // : ' linear-gradient(38deg, rgba(0,0,0,0.9108018207282913) 30%,  rgba(157,55,15,0.9108018207282913) 100%)',
								  'linear-gradient(38deg, rgba(0, 0, 0, 0.36) 30%, rgb(0 0 0 / 31%) 100%)',
					},
				}}
				className={'container-modal, backdropfilter'}
			>
				<Box className='container-alertDiscount'>
					<div className='container-data-discount'>
						<div className='flex-end mt1'>
							<IconButton
								aria-label='delete'
								onClick={function () {
									handleClose()
								}}
							>
								<CloseIcon sx={{ fontSize: 35, color: 'white' }} />
							</IconButton>
						</div>

						<div className='title-alert-discount'>
								{i18n.language === 'En' ? (
									<span>{props._Porcentaje.txtTituloEn}</span>
								) : (
									<span>{props._Porcentaje.txtTitulo}</span>
								)}
								{/* {t('ModalDiscount.Congratulations')} */}
						</div>

						<div className='items-porcent'>
							<div>
								<span className='porcent-discount'>
									{props._Info.dblPrcentaje} <span className='porcent'>%</span>
								</span>
							</div>
							<div className='off-discount-porcent'>
								{i18n.language === 'En' ? (
									<span>{props._Porcentaje.txtDescuentoEn} </span>
								) : (
									<span>{props._Porcentaje.txtDescuento} </span>
								)}
								{/* <span>{t('ModalDiscount.Off')} </span> */}
							</div>
						</div>

						<div className='flex-column-between mt-15'>

							<div>
								{i18n.language === 'En' ? (
									<p className='subtitle-alert-discount' dangerouslySetInnerHTML={{
										__html:props._Porcentaje.txtCrossellingEn,
									}}></p>
									// <>{props._Porcentaje.txtCrossellingEn}</>
								) : (
									<p className='subtitle-alert-discount' dangerouslySetInnerHTML={{
										__html:props._Porcentaje.txtCrosselling,
									}}></p>
									// <>{props._Porcentaje.txtCrosselling}</>
								)}
								{/* {t('7Paso-Confirmation.Congratulations-2')}
								{props._Info.dblPrcentaje}% {t('7Paso-Confirmation.discount')}  */}
							</div>
							<div className='btn-discount-center'>

								<Button
									className='btn-discount'
									onClick={function () {
										handleClose()
									}}
								>
									{t('ModalDiscount.Btn-Next')}
								</Button>
							</div>
						</div>
					</div>
				</Box>
			</Dialog>
		</div>
	)
}

export default ModalDiscount
