import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	cartId: '',
	loading: false,
	error: '',
}

export const cartOrderSlice = createSlice({
	name: 'cartOrder',
	initialState,
	reducers: {
		cartOrder_Init: (state) => {
			return { ...state, loading: true }
		},
		cartOrder_Success: (state, action) => {
			return {
				...state,
				cartId: action.payload,
			}
		},
		cartOrder_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		cartOrder_Remove: (state) => {
			return {
				...state,
				cartId: null,
			}
		},
		cartOrder_AddCarId: (state, action) => {
			return {
				...state,
				cartId: action.payload,
			}
		},
	},
})

export const {
	cartOrder_Init,
	cartOrder_Success,
	cartOrder_Fail,
	cartOrder_Remove,
	cartOrder_AddCarId,
} = cartOrderSlice.actions
export default cartOrderSlice.reducer
