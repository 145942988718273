import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    loading: false,
    events: ''
}

export const eventListSlice = createSlice({
    name: 'eventList',
    initialState,
    reducers: {
        eventList_Init: (state) => {
            return { ...state, loading: true }
        }, 
        eventList_Success: (state,action) => {
            return { ...state, loading: false, events: action.payload }
        },
        eventList_Fail: (state,action) => {
            return { ...state, loading: false, events: action.payload }            
        }
    }
})

export const { 
    eventList_Init,
    eventList_Success,
    eventList_Fail } = eventListSlice.actions;
export default eventListSlice.reducer;