import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	products: [],
	loading: false,
}

export const productListPackageSlice = createSlice({
	name: 'productListPack',
	initialState,
	reducers: {
		//product List
		product_Package_List_Init: (state) => {
			return { ...state, loading: true, products: [] }
		},
		product_Package_List_Success: (state, action) => {
			return { ...state, loading: false, products: action.payload }
		},
		product_Package_List_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
	},
})

export const {
	//product List
	product_Package_List_Init,
	product_Package_List_Success,
	product_Package_List_Fail,
} = productListPackageSlice.actions
export default productListPackageSlice.reducer
