import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	success: false,
	error: '',
}
export const userUpdatePassSlice = createSlice({
	name: 'userUpdatePass',
	initialState,
	reducers: {
		user_UpdatePass_Init: (state) => {
			return { ...state, loading: true }
		},
		user_UpdatePass_Succes: (state, action) => {
			return { ...state, loading: false, success: true }
		},
		user_UpdatePass_Failt: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
	},
})

export const { user_UpdatePass_Init, user_UpdatePass_Succes, user_UpdatePass_Failt } =
userUpdatePassSlice.actions
export default userUpdatePassSlice.reducer