import axios from "axios"
import Dictionary from "./Dictionary"
import { useDispatch } from "react-redux"
import { logout } from "../../Actions/UserActions"
const getBaseUrl = Dictionary.baseUrls.getBaseUrl

axios.interceptors.request.use(
  async (config) => {
    const tok = JSON.parse(localStorage.getItem("userInfo"))
    if (tok === null) {
      config.headers = {
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      }
      return config
    } else {
      config.headers = {
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${tok.access_Token}`,
      }
      return config
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // if (error.response.message === 'Network Error' && !error.response) {
    // 	throw error.response
    // }

    if (
      error.response.status === 401 &&
      error.response.headers["www-authenticate"]?.includes("The token expired")
    ) {
      const dispatch = useDispatch()
      dispatch(logout())
      return
    }
    if (
      error.response.status === 401 &&
      error.response.headers["www-authenticate"]?.includes("invalid_token")
    ) {
      const dispatch = useDispatch()
      dispatch(logout())
      //window.sessionStorage.removeItem('userInfo')

      return
    }
    if (error.response.status === 500) {
    }

    // Do something with response error
    return Promise.reject(error.response)
  }
)
const responseBody = (response) => response?.data

const actions = {
  get: (url, id = "") =>
    axios
      .get(!id ? url : `${url}/${id}`, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  getParam: (url, body) =>
    axios
      .get(url, body, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  getBody: (url, body) =>
    axios
      .post(url, body, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  post: (url, body, id = "") =>
    axios
      .post(!id ? url : `${url}/${id}`, body, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  put: (url, body, id = "") =>
    axios
      .put(!id ? url : `${url}/${id}`, body, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  delete: (url, id = "") =>
    axios
      .delete(!id ? url : `${url}/${id}`, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  Url: getBaseUrl,
}

export default actions
