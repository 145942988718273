import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	user: '',
	error: '',
}
export const useDetailSlice = createSlice({
	name: 'userDetail',
	initialState,
	reducers: {
		user_Detail_Init: (state) => {
			return { ...state, loading: true }
		},
		user_Detail_Succes: (state, action) => {
			return { ...state, loading: false, user: action.payload }
		},
		user_Detail_Failt: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		user_Detail_Reset: (state) => {
			return { ...state, user: {} }
		},
	},
})

export const {
	user_Detail_Init,
	user_Detail_Succes,
	user_Detail_Failt,
	user_Detail_Reset,
} = useDetailSlice.actions
export default useDetailSlice.reducer
