import React, { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import "../../Styles/Profile/Profile.css"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"

import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getUserDetails, updateUser } from "../../Actions/UserActions"
import requests from "../AxiosCalls/AxiosCall"
import {
  GETLISTLADA,
  GET_LIST_LADA_BY_ID,
  GET_LIST_PAIS,
  GET_LIST_PAIS_BY_ID,
} from "../../Constants/routesConstants"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

import Billing from "./TableBilling"
import TableQR from "./TableQR"
import Discounts from "./Discounts"
import ChangePassword from "./ChangePassword"
import Select from "react-select"
import { parse, isBefore, differenceInYears } from "date-fns"
const AntTabs = styled(Tabs)({
  borderBottom: "0px solid #fff",
  "& .MuiTabs-indicator": {
    backgroundColor: "#fff",
  },
})
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    marginRight: window.screen.width <= 600 ? null : theme.spacing(1),
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: window.screen.width <= 600 ? 13.5 : 17,
    fontFamily: [].join(","),
    "&:hover": {
      backgroundColor: "#ffffff08",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#544330",
      fontWeight: "600",
      backgroundColor: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
)

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    fontSize: window.screen.width <= 600 ? 13 : 18,
    padding: window.screen.width <= 600 ? "20px 7px 3px" : null,
  },
  "& label.Mui-focused": {
    color: "#545454",
    fontWeight: "600",
    zIndex: 0,
  },
  "& label": {
    color: "#545454",
    fontWeight: "600",
    fontSize: window.screen.width <= 600 ? 13 : 18,
    zIndex: 0,
  },
  "& .MuiFilledInput-root": {
    border: 0,
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#0000000f",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:hover": {
      // backgroundColor: 'transparent',
    },
    "&.Mui-focused": {
      backgroundColor: "#0000000f",
      boxShadow: 0,
      borderColor: "#0000000f",
    },
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Profile = (props) => {
  const [t, i18n] = useTranslation("global")
  const dispatch = useDispatch()
  const [value, setValue] = useState(0)
  const [currency, setCurrency] = useState("MX-México")
  const [_BirthDate, setBirthDate] = useState(null)
  const userLogin = useSelector((state) => state.user)
  const { userInfo } = userLogin
  const userDetails = useSelector((state) => state.userDetail)
  const { user } = userDetails
  const userUpdate = useSelector((state) => state.userUpdate)
  const { loading, success } = userUpdate

  const [gent, setGent] = useState("")
  const handleGent = (event) => {
    setGent(event.target.value)
    setUserDataUpate({ ...userDataUpdate, bolGenero: event.target.value })
  }
  const [userDataUpdate, setUserDataUpate] = useState({
    lngIdCliente: "",
    txtNombre: "",
    txtApellidos: "",
    txtTelefono: "",
    txtEmail: "",
    intIdCtLada: "",
    txtEdad: "",
    txtAñoNacimiento: "",
    txtMesNacimiento: "",
    txtDiaNacimiento: "",
    txtContrasena: "",
    bolMailing: "",
    intIdPais: "",
    txtCiudad: "",
    txtComentarios: "",
  })
  const handleUserDataUpdate = (userData) => {
    setUserDataUpate({
      ...userDataUpdate,
      txtNombre: userData.txtNombre === null ? "" : userData.txtNombre,
      txtApellidos: userData.txtApellidos === null ? "" : userData.txtApellidos,
      txtTelefono: userData.txtTelefono === null ? "" : userData.txtTelefono,
      txtEmail: userData.txtEmail === null ? "" : userData.txtEmail,
      txtEdad: userData.txtEdad === null ? "" : userData.txtEdad,
      intIdCtLada: userData.intIdCtLada === null ? "" : userData.intIdCtLada,
      txtAñoNacimiento:
        userData.txtAñoNacimiento === null ? "" : userData.txtAñoNacimiento,
      txtMesNacimiento:
        userData.txtMesNacimiento === null ? "" : userData.txtMesNacimiento,
      txtDiaNacimiento:
        userData.txtDiaNacimiento === null ? "" : userData.txtDiaNacimiento,
      lngIdCliente: userData.lngIdCliente === null ? "" : userData.lngIdCliente,
      txtContrasena:
        userData.txtContrasena === null ? "" : userData.txtContrasena,
      bolMailing: userData.bolMailing === null ? "" : userData.bolMailing,
      intIdPais: userData.intIdPais === null ? "" : userData.intIdPais,
      txtCiudad: userData.txtCiudad === null ? "" : userData.txtCiudad,
      txtComentarios:
        userData.txtComentarios === null ? "" : userData.txtComentarios,
    })
    setGent(userData.bolGenero)
    setBirthDate(userData.fecNacimiento)
  }

  // const handleDateChange = (year, month, day) => {
  //   if (year && month && day) {
  //     const selectedDate = parse(
  //       `${year}-${month}-${day}`,
  //       "yyyy-MM-dd",
  //       new Date()
  //     )
  //     const currentDate = new Date()

  //     if (isBefore(selectedDate, currentDate)) {
  //       const calculatedAge = differenceInYears(currentDate, selectedDate)
  //       setUserDataUpate((prevData) => ({
  //         ...prevData,
  //         txtEdad: calculatedAge.toString(),
  //       }))
  //     } else {
  //       setUserDataUpate((prevData) => ({ ...prevData, txtEdad: "" }))
  //     }
  //   }
  // }
  const handleTextUserDataUpdate = (event) => {
    setUserDataUpate({
      ...userDataUpdate,
      [event.target.name]: event.target.value,
      // [event.target.name]: event.target.checked,
    })
  }
  const handleTextUserDataUpdateBool = (event) => {
    setUserDataUpate({
      ...userDataUpdate,
      [event.target.name]: event.target.checked,
    })
  }

  const [editarForm, setEditarForm] = useState(true)
  const handleEditarForm = () => {
    setEditarForm(!editarForm)
  }

  const submitUpdate = () => {
    const timer = setTimeout(() => {
      dispatch(updateUser(userDataUpdate, props))
    }, 500)
    return () => clearTimeout(timer)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangee = (event) => {
    setCurrency(event.target.value)
  }

  /*::::::::::::::::::::::::::::::::::::::::: Lista Lada */
  const [listLada, setListLada] = useState([])
  const getListLada = () => {
    requests
      .get(GETLISTLADA)
      .then((response) => {
        setListLada(response)
      })
      .catch((err) => {})
  }
  const [lada, setLada] = useState({})
  const getLadaById = (id) => {
    requests
      .get(GET_LIST_LADA_BY_ID + id)
      .then((response) => {
        setLada(response)
      })
      .catch((err) => {})
  }
  /*::::::::::::::::::::::::::::::::::::::::: Lista Paises */
  const [listaPais, setListaPais] = useState([])

  const getListaPais = () => {
    requests
      .get(GET_LIST_PAIS)
      .then((response) => {
        setListaPais(response)
      })
      .catch((err) => {})
  }
  const [pais, setPais] = useState({})
  const getPaisById = (id) => {
    requests
      .get(GET_LIST_PAIS_BY_ID + id)
      .then((response) => {
        setPais(response)
      })
      .catch((err) => {})
  }
  useEffect(() => {
    getListLada()
    getListaPais()

    if (userInfo) {
      dispatch(getUserDetails(userInfo.id, props)).then((getState) => {
        handleUserDataUpdate(getState.user)
        if (getState.user.intIdPais !== null) {
          getPaisById(getState.user.intIdPais)
        }
        if (getState.user.intIdCtLada !== null) {
          getLadaById(getState.user.intIdCtLada)
        }
        if (i18n.language === "En") {
          switch (parseInt(getState.user.txtMesNacimiento)) {
            case 1:
              return setMonthEnValue("January")
            case 2:
              return setMonthEnValue("February")
            case 3:
              return setMonthEnValue("March")
            case 4:
              return setMonthEnValue("April")
            case 5:
              return setMonthEnValue("May")
            case 6:
              return setMonthEnValue("June")
            case 7:
              return setMonthEnValue("July")
            case 8:
              return setMonthEnValue("August")
            case 9:
              return setMonthEnValue("September")
            case 10:
              return setMonthEnValue("October")
            case 11:
              return setMonthEnValue("November")
            case 12:
              return setMonthEnValue("December")
            default:
              return setMonthEnValue("")
          }
        } else {
          switch (parseInt(getState.user.txtMesNacimiento)) {
            case 1:
              return setMonthEnValue("Enero")
            case 2:
              return setMonthEnValue("Febrero")
            case 3:
              return setMonthEnValue("Marzo")
            case 4:
              return setMonthEnValue("Abril")
            case 5:
              return setMonthEnValue("Mayo")
            case 6:
              return setMonthEnValue("Junio")
            case 7:
              return setMonthEnValue("Julio")
            case 8:
              return setMonthEnValue("Agosto")
            case 9:
              return setMonthEnValue("Septiembre")
            case 10:
              return setMonthEnValue("Octubre")
            case 11:
              return setMonthEnValue("Noviembre")
            case 12:
              return setMonthEnValue("Diciembre")
            default:
              return setMonthEnValue("")
          }
        }
      })
    }
  }, [userInfo, dispatch])

  const Age = []
  for (let i = 18; i <= 100; i++) {
    Age.push({
      value: i,
      label: i.toString(),
    })
  }
  const Year = []

  for (let i = 1920; i <= 2004; i++) {
    Year.push({
      value: i,
      label: i.toString(),
    })
  }
  const MonthEn = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ]
  const MonthEs = [
    {
      value: 1,
      label: "Enero",
    },
    {
      value: 2,
      label: "Febrero",
    },
    {
      value: 3,
      label: "Marzo",
    },
    {
      value: 4,
      label: "Abril",
    },
    {
      value: 5,
      label: "Mayo",
    },
    {
      value: 6,
      label: "Junio",
    },
    {
      value: 7,
      label: "Julio",
    },
    {
      value: 8,
      label: "Agosto",
    },
    {
      value: 9,
      label: "Septiembre",
    },
    {
      value: 10,
      label: "Octubre",
    },
    {
      value: 11,
      label: "Noviembre",
    },
    {
      value: 12,
      label: "Diciembre",
    },
  ]
  const Day = [
    {
      value: 1,
      label: "01",
    },
    {
      value: 2,
      label: "02",
    },
    {
      value: 3,
      label: "03",
    },
    {
      value: 4,
      label: "04",
    },
    {
      value: 5,
      label: "05",
    },
    {
      value: 6,
      label: "06",
    },
    {
      value: 7,
      label: "07",
    },
    {
      value: 8,
      label: "08",
    },
    {
      value: 9,
      label: "09",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 11,
      label: "11",
    },
    {
      value: 12,
      label: "12",
    },
    {
      value: 13,
      label: "13",
    },
    {
      value: 14,
      label: "14",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 16,
      label: "16",
    },
    {
      value: 17,
      label: "17",
    },
    {
      value: 18,
      label: "18",
    },
    {
      value: 19,
      label: "19",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 21,
      label: "21",
    },
    {
      value: 22,
      label: "22",
    },
    {
      value: 23,
      label: "23",
    },
    {
      value: 24,
      label: "24",
    },
    {
      value: 25,
      label: "25",
    },
    {
      value: 26,
      label: "26",
    },
    {
      value: 27,
      label: "27",
    },
    {
      value: 28,
      label: "28",
    },
    {
      value: 29,
      label: "29",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 31,
      label: "31",
    },
  ]
  const [monthEnValue, setMonthEnValue] = useState(null)

  return (
    <div className="container-portada-p">
      <div className="cardprofile animated fadeIn fast">
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              backgroundColor: "#544330",
              display: "flex",
              justifyContent: window.screen.width <= 600 ? null : "center",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          >
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
            >
              <AntTab
                className="tab"
                label={t("Profile.TProfile")}
                {...a11yProps(0)}
              />
              <AntTab
                className="tab"
                label={t("Profile.TBilling")}
                {...a11yProps(1)}
              />
              <AntTab
                className="tab"
                label={t("Profile.TQR")}
                {...a11yProps(2)}
              />

              <AntTab
                className="tab"
                label={t("Profile.TDiscounts")}
                {...a11yProps(3)}
              />
              <AntTab
                className="tab"
                // label={t('Profile.TForgot')}
                label={t("Profile.ChangePass")}
                {...a11yProps(4)}
              />
            </AntTabs>
          </Box>
          <TabPanel
            value={value}
            index={0}
            className="ContainerFormProfile animated fadeIn fast"
          >
            <div className="flex-start gap1">
              <Avatar className="AvatarProfile" user={userInfo}>
                {userInfo.nombre.substring(0, 1).toUpperCase()}
              </Avatar>
              <div>
                <p className="my0 font-weight6 fs20 fs20movilprofile">
                  {user.txtNombre === undefined ? "" : user.txtNombre}{" "}
                  {user.txtApellidos === undefined ? "" : user.txtApellidos}
                </p>
                {/* <span className='font-weight6' onClick={handleEditarForm}>
									{editarForm === true
										? t('Profile.BtnEditar')
										: t('Profile.BtnDescartar')}
								</span> */}
                <Button
                  className="txteditar"
                  variant="text"
                  onClick={handleEditarForm}
                >
                  {editarForm === true
                    ? t("Profile.BtnEditar")
                    : t("Profile.BtnDescartar")}
                </Button>
              </div>
            </div>
            <div className="mt2">
              <Grid container spacing={2}>
                <Grid item xs={6} lg={6}>
                  <RedditTextField
                    disabled={editarForm}
                    label={t("Profile.FName")}
                    fullWidth
                    id="firstName"
                    variant="filled"
                    name="txtNombre"
                    onChange={handleTextUserDataUpdate}
                    value={userDataUpdate.txtNombre}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <RedditTextField
                    disabled={editarForm}
                    label={t("Profile.FLastName")}
                    fullWidth
                    id="lastName"
                    variant="filled"
                    name="txtApellidos"
                    onChange={handleTextUserDataUpdate}
                    value={userDataUpdate.txtApellidos}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <RedditTextField
                    disabled={editarForm}
                    variant="filled"
                    fullWidth
                    id="email"
                    name="txtEmail"
                    label={t("Profile.FMail")}
                    placeholder="example@email.com"
                    onChange={handleTextUserDataUpdate}
                    value={userDataUpdate.txtEmail}
                  />
                </Grid>
                <Grid item xs={5} lg={3}>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isDisabled
                          ? "#E0E0E0"
                          : "#F0F0F0",
                        border: "none",
                        padding: "14px 0px 8px 0px",
                        "@media (max-width: 600px)": {
                          fontSize: "11px",
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                      }),
                    }}
                    // placeholder={pais.txtPais}
                    placeholder={lada.txtNomLada + lada.txtNumLada}
                    required
                    isDisabled={editarForm}
                    options={listLada.map((option) => ({
                      label: option.txtNomLada + option.txtNumLada,
                      value: option.intIdCtLada,
                    }))}
                    onChange={(selectedOption) =>
                      handleTextUserDataUpdate({
                        target: {
                          name: "intIdCtLada",
                          value: selectedOption.value,
                        },
                      })
                    }
                  />
                  {/* <RedditTextField
                    disabled={editarForm}
                    variant="filled"
                    fullWidth
                    id="intIdCtLada"
                    name="intIdCtLada"
                    select
                    label={t("Profile.FPhone")}
                    value={userDataUpdate.intIdCtLada}
                    onChange={handleTextUserDataUpdate}
                    // helperText='Please select your currency'
                  >
                    {listLada.map((option) => (
                      <MenuItem
                        key={option.intIdCtLada}
                        value={option.intIdCtLada}
                      >
                        {option.txtNomLada}
                        {option.txtNumLada}
                      </MenuItem>
                    ))}
                  </RedditTextField> */}
                </Grid>
                <Grid item xs={7} lg={9}>
                  <RedditTextField
                    disabled={editarForm}
                    variant="filled"
                    fullWidth
                    id="numberphone"
                    label={t("Profile.FPhoneHere")}
                    name="txtTelefono"
                    onChange={handleTextUserDataUpdate}
                    value={userDataUpdate.txtTelefono}
                  />
                </Grid>
                {/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: paises */}
                <Grid item xs={6} lg={6}>
                  {/* <RedditTextField
										disabled={editarForm}
										variant='filled'
										fullWidth
										id='intIdPais'
										name='intIdPais'
										select
										label={t('Profile.Country')}
										value={userDataUpdate.intIdPais}
										onChange={handleTextUserDataUpdate}
									>
										{listaPais.map((option) => (
											<MenuItem key={option.intIdPais} value={option.intIdPais}>
												{option.txtPais}
											</MenuItem>
										))}
									</RedditTextField> */}
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isDisabled
                          ? "#E0E0E0"
                          : "#F0F0F0",
                        border: "none",
                        padding: "14px 0px 8px 0px",
                        "@media (max-width: 600px)": {
                          fontSize: "11px",
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                        fontSize: "15px",
                      }),
                      //   menuPortal: (base) => ({ ...base, zIndex: 1000 }),
                    }}
                    placeholder={pais.txtPais}
                    required
                    isDisabled={editarForm}
                    options={listaPais.map((option) => ({
                      label: option.txtPais,
                      value: option.intIdPais,
                    }))}
                    onChange={(selectedOption) =>
                      handleTextUserDataUpdate({
                        target: {
                          name: "intIdPais",
                          value: selectedOption.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <RedditTextField
                    disabled={editarForm}
                    variant="filled"
                    fullWidth
                    id="txtCiudad"
                    label={t("Profile.City")}
                    name="txtCiudad"
                    onChange={handleTextUserDataUpdate}
                    value={userDataUpdate.txtCiudad}
                  />
                </Grid>
                {/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: paises */}

                {/* <Grid item xs={6} lg={3}>
                  <RedditTextField
                    disabled
                    variant="filled"
                    fullWidth
                    id="txtEdad"
                    name="txtEdad"
                    label={t("SignUp.FAge")}
                    value={userDataUpdate.txtEdad}
                    onChange={handleTextUserDataUpdate}
                  />
                </Grid> */}
                <Grid item xs={6} lg={4}>
                  {/* <RedditTextField
										disabled={editarForm}
										variant='filled'
										fullWidth
										focused
										id='txtAñoNacimiento'
										name='txtAñoNacimiento'
										select
										label={t('SignUp.Year')}
										value={userDataUpdate.txtAñoNacimiento}
										onChange={handleTextUserDataUpdate}
									>
										{Year.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</RedditTextField> */}
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isDisabled
                          ? "#E0E0E0"
                          : "#F0F0F0",
                        border: "none",
                        padding: "14px 0px 8px 0px",
                        "@media (max-width: 600px)": {
                          fontSize: "11px",
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                      }),
                    }}
                    placeholder={userDataUpdate.txtAñoNacimiento}
                    required
                    isDisabled={editarForm}
                    options={Year.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    onChange={(selectedOption) => {
                      handleTextUserDataUpdate({
                        target: {
                          name: "txtAñoNacimiento",
                          value: selectedOption.value,
                        },
                      })
                      // handleDateChange(
                      //   selectedOption.value,
                      //   userDataUpdate.txtMesNacimiento,
                      //   userDataUpdate.txtDiaNacimiento
                      // )
                    }}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isDisabled
                          ? "#E0E0E0"
                          : "#F0F0F0",
                        border: "none",
                        padding: "14px 0px 8px 0px",
                        "@media (max-width: 600px)": {
                          fontSize: "11px",
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                      }),
                    }}
                    placeholder={monthEnValue}
                    required
                    isDisabled={editarForm}
                    options={
                      i18n.language === "En"
                        ? MonthEn.map((option) => ({
                            label: option.label,
                            value: option.value,
                          }))
                        : MonthEs.map((option) => ({
                            label: option.label,
                            value: option.value,
                          }))
                    }
                    // onChange={(selectedOption) => handleTextUserDataUpdate({
                    // 	target: { name: 'txtMesNacimiento', value: selectedOption.value }
                    // })}
                    onChange={(selectedOption) => {
                      handleTextUserDataUpdate({
                        target: {
                          name: "txtMesNacimiento",
                          value: selectedOption.value,
                        },
                      })
                      // handleDateChange(
                      //   userDataUpdate.txtAñoNacimiento,
                      //   selectedOption.value,
                      //   userDataUpdate.txtDiaNacimiento
                      // )
                    }}
                  />
                  {/* <RedditTextField
										disabled={editarForm}
										variant='filled'
										fullWidth
										focused
										id='txtMesNacimiento'
										name='txtMesNacimiento'
										select
										label={t('SignUp.Month')}
										value={userDataUpdate.txtMesNacimiento}
										onChange={handleTextUserDataUpdate}
									>
										{i18n.language === 'En'
											? MonthEn.map((option, index) => (
													<MenuItem key={index} value={option.value}>
														{option.label}
													</MenuItem>
											  ))
											: MonthEs.map((option, index) => (
													<MenuItem key={index} value={option.value}>
														{option.label}
													</MenuItem>
											  ))}
									</RedditTextField> */}
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isDisabled
                          ? "#E0E0E0"
                          : "#F0F0F0",
                        border: "none",
                        padding: "14px 0px 8px 0px",
                        "@media (max-width: 600px)": {
                          fontSize: "11px",
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#544331",
                        fontWeight: 500,
                      }),
                    }}
                    placeholder={userDataUpdate.txtDiaNacimiento}
                    required
                    isDisabled={editarForm}
                    options={Day.map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    onChange={(selectedOption) => {
                      handleTextUserDataUpdate({
                        target: {
                          name: "txtDiaNacimiento",
                          value: selectedOption.value,
                        },
                      })
                      // handleDateChange(
                      //   userDataUpdate.txtAñoNacimiento,
                      //   userDataUpdate.txtMesNacimiento,
                      //   selectedOption.value
                      // )
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <RedditTextField
                    disabled={editarForm}
                    variant="filled"
                    fullWidth
                    multiline
                    id="txtComentarios"
                    name="txtComentarios"
                    label={t("SignUp.comments")}
                    value={userDataUpdate.txtComentarios}
                    onChange={handleTextUserDataUpdate}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className="container-Checkbox">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={editarForm}
                            id="bolMailing"
                            name="bolMailing"
                            onChange={handleTextUserDataUpdateBool}
                            checked={userDataUpdate.bolMailing}
                            color="success"
                          />
                        }
                        label={t("5Paso-Information.CheckMark")}
                      />
                    </FormGroup>
                  </div>
                </Grid>
              </Grid>
              {/* {success && <Message variant='bg-red-200'>{success}</Message>} */}
              <div className="flex-between mt2 ">
                <div>{success}</div>

                {editarForm === true ? (
                  <></>
                ) : (
                  <>
                    <Button
                      className="btn-profile"
                      variant="contained"
                      onClick={submitUpdate}
                    >
                      {t("Profile.BtnSaveChanges")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Billing props={props} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TableQR props={props} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Discounts props={props} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ChangePassword props={props} />
          </TabPanel>
        </Box>
      </div>
    </div>
  )
}

export default Profile
