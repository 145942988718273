import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	userInfo: '',
	error: '',
	msssuccess: false,
}

export const userRegisterSlice = createSlice({
	name: 'userRegister',
	initialState,
	reducers: {
		userRegister_Init: (state) => {
			return { ...state, loading: true }
		},
		userRegister_Success: (state, action) => {
			return { ...state, loading: false, userInfo: action.payload }
		},
		userRegister_Failt: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		userRegister_MssSucces: (state, action) => {
			return { ...state, loading: false, msssuccess: action.payload }
		},
	},
})

export const {
	userRegister_Init,
	userRegister_Success,
	userRegister_Fail,
	userRegister_MssSucces,
} = userRegisterSlice.actions
export default userRegisterSlice.reducer
