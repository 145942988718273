/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import DescriptionIcon from "@mui/icons-material/Description"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import StepLabel from "@mui/material/StepLabel"
import Accordion from "@mui/material/Accordion"
import actions from "../AxiosCalls/AxiosAuth"
import { styled } from "@mui/material/styles"
import Check from "@mui/icons-material/Check"
import Tooltip from "@mui/material/Tooltip"
import Stepper from "@mui/material/Stepper"
import Button from "@mui/material/Button"
import Step from "@mui/material/Step"
import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import QRCode from "qrcode.react"

import { QR_BY_ID_ORDER } from "../../Constants/routesConstants"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import PurchaseDetail from "../Modals/PurchaseDetail"

import "../../Styles/PaymentFlow/st7Confirmation.css"
import "../../Styles/PaymentFlow/PaymentStepper.css"
// import bannerPromocion from '../../img/Banner_mamazzita.webp'
import bannerPromocion from "../../img/Banners Venta Cruzada FullPass.webp"
// import ModalDiscount from '../Modals/ModalDiscount'

import { addToCart, removeFromCart } from "../../Actions/CartActions"
import {
  addToCartPacks,
  removeFromCartitemPacks,
} from "./../../Actions/CartPacksActions"

import { useDispatch, useSelector } from "react-redux"
import { cleanCart } from "../../Actions/CartActions"
import { cleanCartPacks } from "../../Actions/CartPacksActions"
import { getMatrixDiscount } from "../../Actions/CartOrderActions"

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
// import localStorage from 'redux-persist/es/storage'

import { createOrderItem_Success } from "../../Slices/CartOrderSlice/createOrderItemSlice"
import { LazyLoadImage } from "react-lazy-load-image-component"
import ModalDiscount from "../Modals/ModalDiscount"

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 21,
    width: "100%",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(0,0,0) 0%,rgb(0,0,0) 50%,rgb(0,0,0) 100%)",
      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.9)",
      height: 3,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(0,0,0) 0%,rgb(0,0,0) 50%,rgb(0,0,0) 100%)",
      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.9)",
      height: 3,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#868686",
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#333333",
  zIndex: 1,
  color: "#fff",
  width: 38,
  height: 38,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(0,0,0) 0%, rgb(0,0,0) 50%, rgb(0,0,0) 100%)",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.9)",
    width: 50,
    height: 50,
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(51 51 51) 0%, rgb(51 51 51) 50%, rgb(51 51 51) 100%)",
    width: 50,
    height: 50,
  }),
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      <Check className="QontoStepIcon-completedIcon" />
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const St7Confirmation = (props) => {
  const [t, i18n] = useTranslation("global")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [_Info, setInfo] = useState([])
  const [_Porcentaje, setPorcentaje] = useState([])
  const [activeStep, setActiveStep] = useState(4)

  //pasos
  const steps = [
    t("PaymentStepper.Cart"),
    t("PaymentStepper.Information"),
    t("PaymentStepper.Chechout"),
    t("PaymentStepper.Paymant"),
    t("PaymentStepper.Confirmation"),
  ]

  //Estados FQA
  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  //Navegacion al home
  const backTohome = () => {
    navigate("/")
  }

  //Obtener Datos
  const getOrder = useSelector((state) => state.getOrderItems)
  const { cartProduct, ordercomp } = getOrder
  const cartOrder = useSelector((state) => state.cartOrder)

  // const getDiscount = useSelector((state) => state.getMatrixDiscount)
  // const { discount } = getDiscount
  // console.log(discount)

  const createOrderItem = useSelector((state) => state.createOrderItem)
  const { cartId } = createOrderItem
  // console.log('cartId:', cartId)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(cleanCart())
    dispatch(cleanCartPacks())
    getRegistro()
  }, [dispatch])

  const getRegistro = () => {
    //pruebas
    //let newObject = 1340
    // console.log(JSON.parse(newObject))

    //produccion
    let newObject = window.localStorage.getItem("carritoId")

    actions
      /* El ID por defecto aqui es el de cardOrder pero el que trae la informacion es Lng_idCarritoProducto  */
      .get(QR_BY_ID_ORDER + newObject) //validar

      //.get(QR_BY_ID_ORDER + 1341) //validar

      .then((response) => {
        dispatch(createOrderItem_Success(newObject))
        localStorage.removeItem("carritoId")

        setInfo(response.info)
        setPorcentaje(response.porcentaje)

        if (response.info.dblPrcentaje > 0) {
          handleCloseAlertDiscount()
        }
      })

      .catch((error) => {
        // console.log('error de qr', error.data.title)
        // console.log('error de qr', error.data.title)
      })
  }

  //state open Full dialog Request Invoice
  const [openViewPurchases, setOpenViewPurchases] = useState(false)
  const [BillingId, setBillingId] = useState()
  const handleOpenViewPurchases = (id) => {
    if (openViewPurchases === false) {
      setOpenViewPurchases(!openViewPurchases)
      setBillingId(id)
    } else {
      setOpenViewPurchases(!openViewPurchases)
      setBillingId(null)
    }
  }

  /* ---------------------------------------------------------------------------------- */
  //state mnodal Alert Discount
  const [openAlertDiscount, setOpenAlertDiscount] = useState(false)
  const handleCloseAlertDiscount = () => {
    setOpenAlertDiscount(!openAlertDiscount)
  }

  return (
    <div>
      <div className="container-Pay">
        <Box sx={{ width: "100%" }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step
                key={label}
                sx={{
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      fontSize: window.screen.width <= 600 ? 0 : 18,
                      fontWeight: window.screen.width <= 600 ? null : "600",
                      textTransform:
                        window.screen.width <= 600 ? null : "uppercase",
                      color:
                        window.screen.width <= 600 // Just text label (COMPLETED)
                          ? "transparent"
                          : "#00000099",
                    },

                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                    {
                      fontSize: window.screen.width <= 600 ? 12 : 18,
                      fontWeight: "600",
                      color: "#000000", // Just text label (ACTIVE)
                      textTransform: "uppercase",
                      marginBottom: 0,
                    },
                  "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel":
                    {
                      fontSize: window.screen.width <= 600 ? 0 : 18,
                      fontWeight: window.screen.width <= 600 ? null : "600",
                      textTransform:
                        window.screen.width <= 600 ? null : "uppercase",
                      color:
                        window.screen.width <= 600 // Just text label (DISABLED)
                          ? "transparent"
                          : "#00000099",
                    },
                }}
              >
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className="componentsContainer">
            <>
              <div className="flex-between gap2 flex-between-container-movil animated fadeIn fast">
                {/* *************** SECTION 1 ********************* */}
                <div className="container-qr-Section1">
                  <div className="container-qr">
                    <div className="flex-column text-center">
                      <p className="upperCase font-weight6 fs30 co-black mb1 fs-qr-title-movil">
                        {t("7Paso-Confirmation.Title")}
                        {/* <p>{ordercomp.txtFolio}</p> */}
                        <p>{_Info.txtFolio}</p>
                      </p>
                      <p className="font-weight6 fs30 my1 co-black fs-qr-subtitle-movil upperCase">
                        {_Info.cliente}
                      </p>
                      <p className="font-weight3 fs13 mt4 mb4 co-light-gray">
                        {t("7Paso-Confirmation.Subtitle")}
                      </p>

                      <div className="containerQr">
                        <QRCode value={_Info.txtQr} size={200} level={"L"} />
                      </div>

                      {_Info.dblPrcentaje === 0 ? null : (
                        <>
                          <p className="font-weight3 fs13 mt4 co-light-gray">
                            {t("7Paso-Confirmation.Congratulations-2")}
                            {_Info.dblPrcentaje}%{" "}
                            {t("7Paso-Confirmation.Congratulations-1")}
                          </p>
                          <div className="box-code ">
                            <p className="font-weight6">{_Info.txtCodigo}</p>
                            <Tooltip title="Copy to clipboard">
                              <IconButton aria-label="Copy">
                                <ContentCopyIcon
                                  sx={{ color: "#007420" }}
                                  onClick={() =>
                                    navigator.clipboard.writeText(
                                      _Info.txtCodigo
                                    )
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <p className="font-weight3 fs13 mt3 mb5 co-light-gray mb-movil">
                            {_Info.dblPrcentaje}%{" "}
                            {t("7Paso-Confirmation.discount")}
                          </p>
                        </>
                      )}

                      <div className="box-facturacion-st7 mt2">
                        <div className="flex-between ">
                          <p className="bold toCapitalize fs20">
                            {t("7Paso-Confirmation.st7TitleInvoice")}
                          </p>
                          <DescriptionIcon
                            sx={{ color: "#333333", fontSize: 30 }}
                          />
                        </div>

                        <div>
                          <p>{t("7Paso-Confirmation.st7Body")}</p>
                        </div>
                      </div>

                      <a
                        href="https://www.opentable.com.mx/restref/client/?rid=1208545&restref=1208545&partysize=2&datetime=2023-01-31T19%3A00&lang=en-US&r3uid=4auI-yIDCg&ot_source=Restaurant%20website&color=1&corrid=5d39ce60-a41f-4121-8fe5-fb68d2062c96"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="mt2">
                          <LazyLoadImage
                            style={{ width: "100%" }}
                            src={bannerPromocion}
                          />
                        </div>
                      </a>
                    </div>
                    <div className="flex-between  mt2">
                      <Button
                        className="btn-edit-stepper-confirmation"
                        variant="outlined"
                        onClick={backTohome}
                        // onClick={props.previousStep}
                      >
                        {t("7Paso-Confirmation.Btn-Back")}
                      </Button>
                      <Button
                        onClick={function () {
                          handleOpenViewPurchases(_Info.lngIdOrdenCompra)
                        }}
                        className="btn-next-stepper-confirmation"
                        variant="outlined"
                      >
                        {t("7Paso-Confirmation.Btn-Purchase")}
                      </Button>
                    </div>
                  </div>
                </div>
                {/* *************** SECTION 2 ********************* */}
                <div className="container-qr-Section2">
                  <div className="container-qr2">
                    <div className="container-header-qr3">
                      <p className="co-white fs18 font-weight6 upperCase">
                        {t("7Paso-Confirmation.FrequentlyQuestions")}
                      </p>
                    </div>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            fontWeight: "600",
                            color: "#545454",
                          }}
                        >
                          {t("7Paso-Confirmation.HelpMyTransaction")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{t("FAQS.contactYouBody")}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            fontWeight: "600",
                            color: "#545454",
                          }}
                        >
                          {t("7Paso-Confirmation.InformationSecure")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{t("PrivacyNoticeII.II.Body1")}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
              <PurchaseDetail
                BillingId={BillingId}
                open={openViewPurchases}
                onClose={handleOpenViewPurchases}
              />
            </>
          </div>
        </Box>
      </div>
      <React.Suspense>
        <ModalDiscount
          openAlertDiscount={openAlertDiscount}
          handleCloseAlertDiscount={handleCloseAlertDiscount}
          _Info={_Info}
          _Porcentaje={_Porcentaje}
        />
      </React.Suspense>
    </div>
  )
}
export default St7Confirmation
