import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	item: '',
	loading: false,
	guessItems: '',
}
export const removeGuestSlice = createSlice({
	name: ' removeGuest',
	initialState,
	reducers: {
		//product List
		removeGuest_Add_Item: (state, action) => {
			const item = action.payload

			return {
				...state,
				guessItems: [...state.guessItems, item],
			}
		},
		removeGuest_Remove_Item: (state) => {
			state.guessItems.splice(0, 1)
			return { ...state, guessItems: state.guessItems }
		},
		removeGuest_Clear_Item: (state) => {
			return {
				...state,
				guessItems: [],
			}
		},
	},
})
export const {
	//product List
	removeGuest_Add_Item,
	removeGuest_Remove_Item,
	removeGuest_Clear_Item,
} = removeGuestSlice.actions
export default removeGuestSlice.reducer
