import React from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import requests from '../AxiosCalls/AxiosCall'
import { CHANGE_PASSWORD } from '../../Constants/routesConstants'
import { AlertSucces } from '../../Slices/Alert/alertSlice'
import { AlertCollapse } from '../Alerts/Alerts'
import {
	user_Update_Succes,
} from '../../Slices/UserSlice/userUpdateSlice'

const RedditTextField = styled((props) => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'label + &': {
		marginTop: theme.spacing(3),
	},
	'& .MuiInputBase-input': {
		fontSize: window.screen.width <= 600 ? 13 : 18,
		padding: window.screen.width <= 600 ? '20px 7px 3px' : null,
	},
	'& label.Mui-focused': {
		color: '#545454',
		fontWeight: '600',
	},
	'& label': {
		color: '#545454',
		fontWeight: '600',
		fontSize: window.screen.width <= 600 ? 13 : 18,
	},
	'& .MuiFilledInput-root': {
		border: 0,
		overflow: 'hidden',
		borderRadius: 4,
		backgroundColor: '#0000000f',
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),

		'&:hover': {
			// backgroundColor: 'transparent',
		},
		'&.Mui-focused': {
			backgroundColor: '#0000000f',
			boxShadow: 0,
			borderColor: '#0000000f',
		},
	},
}))
const ChangePassword = (props) => {
	const [t, i18n] = useTranslation('global')
	const userDetails = useSelector((state) => state.userDetail)
	const { user } = userDetails
	const dispatch = useDispatch()
	const userUpdate = useSelector((state) => state.userUpdate)
	const { loading, success } = userUpdate

	const formik = useFormik({
		initialValues: {
			lngIdCliente: user.lngIdCliente,
			txtContrasenaActual: '',
			txtContrasenaNueva: '',
			txtContrasenaNuevaConfirma: '',
		},
		validationSchema: yup.object({
			txtContrasenaActual: yup.string().required('El campo es requerido'),
			txtContrasenaNueva: yup.string().required('El campo es requerido'),
			txtContrasenaNuevaConfirma: yup
				.string()
				.required('El campo es requerido'),
		}),
		onSubmit: (values) => {
			props.props.setOpenLoadingScreen()
			requests
				.put(CHANGE_PASSWORD, values)
				.then((response) => {
					const message = (
						<AlertCollapse
							severity='success'
							variant='filled'
							mensaje={response}
						/>
					)
					dispatch(user_Update_Succes(message))
					dispatch(AlertSucces(true))
					props.props.setCloseLoadingScreen()
				})
				.catch((error) => {
					const message = (
						<AlertCollapse
							severity='error'
							variant='filled'
							mensaje={error.data}
						/>
					)
					dispatch(user_Update_Succes(message))
					dispatch(AlertSucces(true))
					props.props.setCloseLoadingScreen()
				})
		},
	})
	return (
		<div className='ContainerForgotPassword animated fadeIn fast'>
			<p className='text-center font-weight6 my1'>{t('Profile.Reset')}</p>
			<Box component='form' noValidate onSubmit={formik.handleSubmit}>
				<div className='flex-column mt3'>
					<RedditTextField
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.txtContrasenaActual}
						required
						className='TextFieldReset'
						variant='filled'
						focused
						id='txtContrasenaActual'
						name='txtContrasenaActual'
						label={t('Profile.Current')}
						// helperText={t('Profile.DCurrent')}
						error={
							formik.touched.currentPasswor &&
							Boolean(formik.errors.currentPasswor)
						}
						helperText={
							formik.touched.currentPasswor && formik.errors.currentPasswor
						}
					/>
					<RedditTextField
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.txtContrasenaNueva}
						required
						variant='filled'
						id='txtContrasenaNueva'
						name='txtContrasenaNueva'
						focused
						label={t('Profile.New')}
						className='TextFieldReset'
						// helperText={t('Profile.Dnew')}
						error={
							formik.touched.txtContrasenaNueva &&
							Boolean(formik.errors.txtContrasenaNueva)
						}
						helperText={
							formik.touched.txtContrasenaNueva &&
							formik.errors.txtContrasenaNueva
						}
					/>
					<RedditTextField
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.txtContrasenaNuevaConfirma}
						required
						variant='filled'
						id='txtContrasenaNuevaConfirma'
						name='txtContrasenaNuevaConfirma'
						focused
						label={t('Profile.Confirm')}
						className='TextFieldReset'
						// helperText={t('Profile.DConfirm')}
						error={
							formik.touched.txtContrasenaNuevaConfirma &&
							Boolean(formik.errors.txtContrasenaNuevaConfirma)
						}
						helperText={
							formik.touched.txtContrasenaNuevaConfirma &&
							formik.errors.txtContrasenaNuevaConfirma
						}
					/>
				</div>
				<div className='flex-between mt3'>
					<div>{success}</div>
					<Button className='btn-profile' variant='contained' type='submit'>
						{t('Profile.BtnSaveChanges')}
					</Button>
				</div>
			</Box>
		</div>
	)
}

export default ChangePassword
