import { React, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Lottie from 'lottie-react'
import PaymentProcess from '../../../src/Lottie/payment-process.json'
import requests from '../AxiosCalls/AxiosCall'
import { useTranslation } from 'react-i18next'
// import localStorage from 'redux-persist/es/storage'

const Confirmation3Ds = ({
	setMsjLoadingScreen,
	setOpenLoadingScreen,
	setCloseLoadingScreen,
	setOpenSnackBar,
	setMessageSnackBar,
	setTypeSnackBar,
}) => {
	const navigate = useNavigate()
	const [t, i18n] = useTranslation('global')
	const { reference } = useParams()

	let dataBodyLocal = JSON.parse(localStorage.getItem('dataBodyLocal'))

	useEffect(() => {
		confirmPayment()
	}, [])

	const confirmPayment = () => {
		setOpenLoadingScreen()
		setMsjLoadingScreen('Validando Transacción')

		// console.log(dataBodyLocal)
		const ipgTransactionId = JSON.parse(
			localStorage.getItem('ipgTransactionId')
		)
		const type3ds = JSON.parse(localStorage.getItem('3dsSinIframe'))
			? JSON.parse(localStorage.getItem('3dsSinIframe'))
			: ''
		const HashCo = JSON.parse(localStorage.getItem('CoHas'))
			? JSON.parse(localStorage.getItem('CoHas'))
			: ''

		const SendBody = {
			transactionId: ipgTransactionId,
			cRes: reference,
			type3ds: type3ds,
			securityCode: HashCo,
			cardholderName: dataBodyLocal.cardholderName,
			city: dataBodyLocal.city,
			zip: dataBodyLocal.zip,
			state: dataBodyLocal.state,
			country: dataBodyLocal.country,

			number: dataBodyLocal.number,
			month: dataBodyLocal.month,
			year: dataBodyLocal.year,
		}
		requests
			.post('/Payments/ConfirmPayment/', SendBody)
			.then((response) => {
				localStorage.removeItem('CoHas')
				ValidPaymentTransaction()

				return
			})
			.catch((error) => {
				// console.log(error.data.data)
				localStorage.removeItem('CoHas')
				localStorage.removeItem('ipgTransactionId')
				localStorage.removeItem('3dsSinIframe')

				setTypeSnackBar('warning')

				if (i18n.language === 'En') {
					setMessageSnackBar(error.data.messageEN)
				} else {
					setMessageSnackBar(error.data.messageES)
				}

				// setMessageSnackBar(error.data.messageES)
				setOpenSnackBar(true)
				setCloseLoadingScreen()
				navigate('/Pay')
				return
			})
	}
	const ValidPaymentTransaction = () => {
		const ipgTransactionId = JSON.parse(
			localStorage.getItem('ipgTransactionId')
		)
		const IdCarritos = JSON.parse(localStorage.getItem('carritoId'))

		const SendBody = {
			transactionId: ipgTransactionId,
			IdCarrito: IdCarritos,
			cardholderName: dataBodyLocal.cardholderName,
			city: dataBodyLocal.city,
			zip: dataBodyLocal.zip,
			state: dataBodyLocal.state,
			country: dataBodyLocal.country,

			number: dataBodyLocal.number,
			month: dataBodyLocal.month,
			year: dataBodyLocal.year,
		}
		requests
			.post('/Payments/SecondTransaction/', SendBody)
			.then((response) => {
				setTypeSnackBar('success')
				if (i18n.language === 'En') {
					setMessageSnackBar(response.messageEN)
				} else {
					setMessageSnackBar(response.messageES)
				}
				setOpenSnackBar(true)
				setCloseLoadingScreen()
				localStorage.removeItem('CoHas')
				localStorage.removeItem('ipgTransactionId')
				localStorage.removeItem('3dsSinIframe')
				navigate('/Confirmation')
				return
			})
			.catch((error) => {
				localStorage.removeItem('CoHas')
				localStorage.removeItem('ipgTransactionId')
				localStorage.removeItem('3dsSinIframe')

				setTypeSnackBar('warning')
				if (i18n.language === 'En') {
					setMessageSnackBar(error.data.messageEN)
				} else {
					setMessageSnackBar(error.data.messageES)
				}
				setOpenSnackBar(true)
				setCloseLoadingScreen()
				navigate('/Pay')
				return
			})
	}

	return (
		<div style={{ width: '550px', margin: 'auto' }}>
			<Lottie animationData={PaymentProcess} loop={true} autoplay={true} />;
		</div>
	)
}
export default Confirmation3Ds
