import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	product: '',
	loading: false,
	success: false,
	error: '',
}

export const productReviewSlice = createSlice({
	name: 'ProductReview',
	initialState,
	reducers: {
		//product Review
		product_Review_Init: (state) => {
			return {
				...state,
				loading: false,
			}
		},
		product_Review_Success: (state) => {
			return {
				...state,
				loading: false,
				success: true,
			}
		},
		product_Review_Fail: (state, action) => {
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		},
		product_Create_Review_Reset: () => {
			return {}
		},
	},
})

export const {
	//product Review
	product_Review_Init,
	product_Review_Success,
	product_Review_Fail,
} = productReviewSlice.actions
export default productReviewSlice.reducer
