import { createTheme } from '@mui/material/styles'
const Theme = createTheme({
	zIndex: {
        modal: 2010,
    },
	palette: {
		type: 'light',
		primary: {
			main: '#544331',
			contrastText: '#ffffff',
		},
		secondary: {
			main: '#f50057',
		},
		text: {
			primary: '#544331',
			secondary: '#544331',
			disabled: '#544331',
			hint: '#544331',
		},
		background: {
			default: '#ffffff',
			paper: '#ffffff',
		},
		divider: 'rgba(0,0,0,0.12)',
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					// this is styles for the new variants MuiInputBase-input MuiOutlinedInput-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input

					'&.subvariant-hovered': {
						'& fieldset': {
							border: 'none',
						},
						'& .MuiInputBase-input + fieldset': {
							border: `1px solid black`,
						},
						'& .MuiInputBase-input:hover + fieldset': {
							border: `1px solid black`,
						},
						'& label': {
							color: 'black',
						},
					},

					//MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary Mui-focused MuiInputBase-formControl MuiInputBase-sizeSmall subvariant-select css-qlr4xr-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root

					'&.subvariant-select': {
						'& fieldset': {
							border: 'none',
						},

						'& .MuiOutlinedInput-notchedOutline ': {
							border: `1px solid black`,
							color: 'black',
						},
					},
				},
			},
		},

		MuiTabs: {
			styleOverrides: {
			  indicator: {
				backgroundColor: '#ffffff',  // Cambia el color del indicador a blanco
			  },
			},
		  },
	},
	typography: {
		fontFamily: ['DM Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
			','
		),
	},
})
export default Theme
