import React, { useEffect, useState } from 'react'
import '../../Styles/Modals/ViewQR.css'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import QRCode from 'qrcode.react'

import { useTranslation } from 'react-i18next'
import { QR_BY_ID_ORDER, QR_BY_ID_ORDER_PROFILE } from '../../Constants/routesConstants'
import requests from '../AxiosCalls/AxiosCall'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

export const ViewQR = (props) => {
	const [t, i18n] = useTranslation('global')
	const handleClose = () => {
		props.onClose()
	}
	const [_Espacios, setEspacios] = useState([])
	const [_Package, setPackage] = useState([])
	const [_Info, setInfo] = useState([])
	useEffect(() => {
		getRegistro()
	}, [props.QRpurchaseId])
	const getRegistro = () => {
		if (props.QRpurchaseId != null) {
			requests
				.get(QR_BY_ID_ORDER_PROFILE + props.QRpurchaseId)
				.then((response) => {
					setInfo(response.info)
					setEspacios(response.products)
					setPackage(response.packages)
				})
				.catch((error) => {})
		}
	}

	return (
		<div>
			<Dialog
				fullScreen
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				TransitionComponent={Transition}
				PaperProps={{
					style: {
						background:
							window.screen.width <= 600
								? 'white'
								: 'linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)',
					},
				}}
				className={'container-modal, backdropfilter'}
			>
				<div className='container-modal-viewQR'>
					<div className='header-modal-viewQR flex-end '>
						<IconButton className='co-black' onClick={handleClose}>
							<CloseIcon className='co-black' sx={{ fontSize: 35 }} />
						</IconButton>
					</div>
					<div className='container-body-ViewQR flex-column text-center'>
						<p className='upperCase font-weight6 fs30 co-black mb1 text-movil-title-viewQR'>
							{t('7Paso-Confirmation.Title')}
						</p>
						<p className='font-weight6 fs30 my1 co-black fs-movil-subtitle-viewQr toUpperCase'>
							{_Info.cliente}
						</p>
						<p className='font-weight3 fs13 mt4 mb4 co-light-gray mt-movil-viewQR'>
							{t('7Paso-Confirmation.Subtitle')}
						</p>

						<div className='modal-Container-Qr'>
							<div className='modal-Qr'>
								<QRCode value={_Info.txtQr} size={250} level={'L'} />
							</div>
						</div>

						<p className='font-weight3 fs13 mt3 mb5 co-light-gray mb-movil'>
							{t('7Paso-Confirmation.uponArrival')}
						</p>
					</div>
				</div>
			</Dialog>
		</div>
	)
}
