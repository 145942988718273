/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Badge from "@mui/material/Badge"

import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"

import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { removeFromCart } from "../Actions/CartActions"
import { removeFromCartPacks } from "../Actions/CartPacksActions"
import { PAY_CHECKOUT } from "../Constants/routesConstants"
import { logout } from "../Actions/UserActions"

import FormatNumber from "../Components/Utils/FormatNumber"

import Login from "../Components/Login/Login"
import AccountMenu from "./AccountMenu"
import logoheader from "../img/LogoHeaderV2.png"

import "../Styles/StyleHeader.css"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Header = (props) => {
  const navigate = useNavigate()
  const [t, i18n] = useTranslation("global")
  const [openMenu, setOpenMenu] = useState(false)
  const [openCart, setOpenCart] = useState(false)
  const [openLogin, setOpenLogin] = useState(false)
  const [openChekout, setOpenChekout] = useState(false)
  const [openSubMenu, setOpenSubMenu] = useState(false)

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu)
    setOpenCart(false)
  }
  const handleOpenCart = () => {
    setOpenCart(!openCart)
    setOpenMenu(false)
  }

  const handleOpenSubMenu = () => {
    setOpenSubMenu(!openSubMenu)
  }

  const handleOpenlogin = () => {
    setOpenLogin(!openLogin)
  }
  const handleCloseLogin = () => {
    setOpenLogin(false)
  }
  const handleOpenChekout = () => {
    setOpenChekout(!openChekout)
  }

  const logoutHandler = () => {
    dispatch(logout())
    handleOpenMenu()
  }
  const user = useSelector((state) => state.user)
  const { userInfo } = user

  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const cartPacks = useSelector((state) => state.cartPacks)
  const { cartItemsPacks } = cartPacks

  let CountItem = cartItems.length + cartItemsPacks.length

  useEffect(() => {}, [dispatch])
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }
  const removeFromCartPacksHandler = (id) => {
    dispatch(removeFromCartPacks(id))
  }

  const total = () => {
    let sumaproduc = cartItems
      .reduce((acc, item) => acc + item.qty * item.precio, 0)
      .toFixed(2)
    let sumapacks = cartItemsPacks
      .reduce((acc, item) => acc + item.qty * item.precio, 0)
      .toFixed(2)
    let subtotal = parseFloat(sumaproduc) + parseFloat(sumapacks)

    return subtotal
  }
  const gotoHome = () => {
    navigate("/")
  }

  return (
    <>
      <Box className="container-menu">
        <AppBar position="static">
          <div className="container-items-header">
            <div>
              {window.screen.width <= 600 ? null : (
                <div className="container-div">
                  <Button
                    className="btn-header-lenguage"
                    onClick={() => i18n.changeLanguage("Es")}
                  >
                    {i18n.language === "Es" ? (
                      <span
                        className="themeColorSpan"
                        style={{ color: "#866d51" }}
                      >
                        ES
                      </span>
                    ) : (
                      <span className="themeColorSpan">ES</span>
                    )}
                  </Button>
                  |
                  <Button
                    className="btn-header-lenguage"
                    onClick={function () {
                      i18n.changeLanguage("En")
                      window.scrollTo(0, 0)
                    }}
                  >
                    {i18n.language === "En" ? (
                      <span
                        className="themeColorSpan"
                        style={{ color: "#866d51" }}
                      >
                        EN
                      </span>
                    ) : (
                      <span className="themeColorSpan">EN</span>
                    )}
                  </Button>
                </div>
              )}
            </div>
            <div className="logo-header-mobile">
              <IconButton onClick={gotoHome}>
                <LazyLoadImage
                  src={logoheader}
                  className="logo-header"
                  width={200}
                  alt="LogoTaboo"
                />
              </IconButton>
            </div>
            <div className="container-btn-burger icons-header">
              {userInfo ? (
                <AccountMenu user={userInfo} props={props} />
              ) : (
                <IconButton
                  onClick={function () {
                    handleOpenlogin()
                  }}
                  className="icon-btn-login"
                >
                  <AccountCircleIcon
                    sx={{
                      fontSize: 35,
                      color: "white",
                      "@media (max-width: 600px)": {
                        fontSize: 25,
                        width: "25px",
                        height: "25px",
                      },
                      "@media (max-width: 320px)": {
                        fontSize: 20,
                        width: "20px",
                        height: "20px",
                        marginRight: "-12px",
                      },
                    }}
                  ></AccountCircleIcon>
                </IconButton>
              )}
              <IconButton
                onClick={function () {
                  navigate(PAY_CHECKOUT)
                }}
                className="icon-btn-login"
              >
                <Badge badgeContent={CountItem} color="error">
                  <ShoppingCartIcon
                    sx={{
                      fontSize: 35,
                      color: "white",
                      "@media (max-width: 600px)": {
                        fontSize: 25,
                        width: "25px",
                        height: "25px",
                      },
                      "@media (max-width: 320px)": {
                        fontSize: 20,
                        width: "20px",
                        height: "20px",
                      },
                    }}
                  ></ShoppingCartIcon>
                </Badge>
              </IconButton>

              {openMenu !== false ? (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleOpenMenu}
                >
                  <CloseIcon sx={{ fontSize: 50, color: "white" }} />
                </IconButton>
              ) : (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleOpenMenu}
                >
                  <MenuIcon
                    sx={{
                      fontSize: 50,
                      color: "white",
                      "@media (max-width: 600px)": {
                        fontSize: 25,
                        width: "35px",
                        height: "35px",
                      },
                      "@media (max-width: 320px)": {
                        fontSize: 15,
                        width: "25px",
                        height: "25px",
                      },
                    }}
                  />
                </IconButton>
              )}
            </div>

            {/*************************  SIDEBAR ****************************/}

            {openMenu !== false ? (
              <div className="container-sidebar animated fadeIn fast">
                {/* <div className="container-header-sidebar">
                  <div>
                    <Link to="/">
                      <LazyLoadImage
                        src={logoheader}
                        className="logo-header"
                        width={150}
                        alt="logoheader"
                      />
                    </Link>
                  </div>
                  <div>
                    {openMenu !== false ? (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleOpenMenu}
                      >
                        <CloseIcon sx={{ fontSize: 40, color: "white" }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleOpenMenu}
                      >
                        <MenuIcon sx={{ fontSize: 40, color: "white" }} />
                      </IconButton>
                    )}
                  </div>
                </div> */}
                <div className="container-div">
                  <Button
                    className="btn-header-lenguage"
                    onClick={() => i18n.changeLanguage("Es")}
                  >
                    {i18n.language === "Es" ? (
                      <span
                        className="themeColorSpan"
                        style={{ color: "#866d51" }}
                      >
                        ES
                      </span>
                    ) : (
                      <span className="themeColorSpan">ES</span>
                    )}
                  </Button>
                  |
                  <Button
                    className="btn-header-lenguage"
                    onClick={() => i18n.changeLanguage("En")}
                  >
                    {i18n.language === "En" ? (
                      <span
                        className="themeColorSpan"
                        style={{ color: "#866d51" }}
                      >
                        EN
                      </span>
                    ) : (
                      <span className="themeColorSpan">EN</span>
                    )}
                  </Button>
                </div>
                <div className="container-item-a">
                  {userInfo ? (
                    <a
                      className="a-item-sidebar flex-start "
                      onClick={function () {
                        //handleOpenlogin()
                      }}
                    >
                      <p className="a-item toUpperCase  text-white">
                        {t("Sidebar.Welcome")}: {userInfo.nombre}
                      </p>
                    </a>
                  ) : (
                    <a
                      className="a-item-sidebar flex-start "
                      onClick={function () {
                        handleOpenlogin()
                      }}
                    >
                      <p className="a-item  text-white">{t("Sidebar.Login")}</p>
                      <AccountCircleIcon sx={{ ml: 1 }}></AccountCircleIcon>
                    </a>
                  )}

                  {i18n.language === "En" ? (
                    <a
                      className="a-item-sidebar"
                      target="_blank"
                      href="https://rosanegrabeachclub.com/en"
                    >
                      <p className="a-item text-white upperCase">
                        {t("Sidebar.Concep")}
                      </p>
                    </a>
                  ) : (
                    <a
                      className="a-item-sidebar"
                      target="_blank"
                      href="https://rosanegrabeachclub.com/es"
                    >
                      <p className="a-item text-white upperCase">
                        {t("Sidebar.Concep")}
                      </p>
                    </a>
                  )}

                  {i18n.language === "En" ? (
                    <a
                      className="a-item-sidebar"
                      target="_blank"
                      href="https://rosanegrabeachclub.com/en/best-lunch-in-tulum"
                    >
                      <p className="a-item  text-white upperCase">
                        {t("Sidebar.Menu")}
                      </p>
                    </a>
                  ) : (
                    <a
                      className="a-item-sidebar"
                      target="_blank"
                      href="https://rosanegrabeachclub.com/es/restaurantes-en-tulum-frente-al-mar"
                    >
                      <p className="a-item  text-white upperCase">
                        {t("Sidebar.Menu")}
                      </p>
                    </a>
                  )}
                  {/* {i18n.language === "En" ? ():()} */}
                  <a className="a-item-sidebar" onClick={handleOpenSubMenu}>
                    <div className="flex-between">
                      <p className="a-item  text-white upperCase">
                        {t("Sidebar.Reservations")}
                      </p>
                      {openSubMenu === false ? (
                        <ChevronRightIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </div>
                  </a>

                  {openSubMenu === false ? null : (
                    <div className="flex-end">
                      <div className="container-item-a-w80 w80 ">
                        {i18n.language === "En" ? (
                          <a
                            className="a-item-sidebar"
                            target="_blank"
                            href="https://rosanegrabeachclub.com/en/beach-club-tulum"
                          >
                            <p className="a-item  text-white">TULUM</p>
                          </a>
                        ) : (
                          <a
                            className="a-item-sidebar"
                            target="_blank"
                            href="https://rosanegrabeachclub.com/es/beach-club-tulum"
                          >
                            <p className="a-item  text-white">TULUM</p>
                          </a>
                        )}

                        {/* <a
                          className="a-item-sidebar"
                          target="_blank"
                          href="https://taboobeachclub.com.mx/es/beach-cabos"
                        >
                          <p className="a-item  text-white">LOS CABOS</p>
                        </a>
                        <a
                          className="a-item-sidebar"
                          target="_blank"
                          href="https://taboobeachclub.com.mx/es/beach-club-isla-mujeres"
                        >
                          <p className="a-item  text-white">ISLA MUJERES</p>
                        </a> */}
                      </div>
                    </div>
                  )}

                  {/* <a
                    className="a-item-sidebar"
                    target="_blank"
                    href="https://taboorestaurant.com.mx/es"
                  >
                    <p className="a-item  text-white upperCase">
                      {t("Sidebar.Restaurant")}
                    </p>
                  </a> */}

                  {i18n.language === "En" ? (
                    <a
                      className="a-item-sidebar"
                      target="_blank"
                      href="https://rosanegrabeachclub.com/en/blog"
                    >
                      <p className="a-item  text-white upperCase">
                        {t("Sidebar.Blog")}
                      </p>
                    </a>
                  ) : (
                    <a
                      className="a-item-sidebar"
                      target="_blank"
                      href="https://rosanegrabeachclub.com/es/blog"
                    >
                      <p className="a-item  text-white upperCase">
                        {t("Sidebar.Blog")}
                      </p>
                    </a>
                  )}
                  {i18n.language === "En" ? (
                    <a
                      className="a-item-sidebar"
                      target="_blank"
                      href="https://rosanegrabeachclub.com/en/new"
                    >
                      <p className="a-item text-white upperCase">
                        {t("Sidebar.Events")}
                      </p>
                    </a>
                  ) : (
                    <a
                      className="a-item-sidebar"
                      target="_blank"
                      href="https://rosanegrabeachclub.com/es/prensa"
                    >
                      <p className="a-item text-white upperCase">
                        {t("Sidebar.Events")}
                      </p>
                    </a>
                  )}

                  {userInfo ? (
                    <a
                      className="a-item-sidebar flex-start"
                      onClick={logoutHandler}
                    >
                      <p className="a-item  text-white upperCase">
                        {t("Sidebar.Logout")}
                      </p>
                      <ExitToAppIcon sx={{ ml: 2 }}></ExitToAppIcon>
                    </a>
                  ) : null}
                </div>
              </div>
            ) : null}

            {/*************************  SIDEBAR CART****************************/}

            {openCart !== false ? (
              <div className="container-sidebar animated fadeIn fast">
                {/* <div className="container-header-sidebar">
                  <div>
                    <Link to="/">
                      <LazyLoadImage
                        src={logoheader}
                        className="logo-header"
                        width={150}
                        alt="logoheader"
                      />
                    </Link>
                  </div>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleOpenCart}
                  >
                    <CloseIcon sx={{ fontSize: 40, color: "white" }} />
                  </IconButton>
                </div> */}

                <div className="container-items-sidecar">
                  {cartItems.length === 0 && cartItemsPacks.length === 0 ? (
                    <p className="text-center text-white">
                      {t("Sidebar.NoItemsCar")}
                    </p>
                  ) : (
                    cartItems.map((product) => (
                      <div className="flex-column-start text-white border-bottom-1">
                        <div className="flex-start">
                          <div className="">
                            <IconButton
                              onClick={() =>
                                removeFromCartHandler(product.productId)
                              }
                            >
                              <CloseIcon className="icon-delete-item-sidebar" />
                            </IconButton>
                          </div>
                          <div className="line-height-0 text-white">
                            <p className="text-white">{product.Nombre} </p>
                            {/* <p>{product.descripcion} </p> */}

                            <div className="flex-between">
                              <p className="mr3 text-white">
                                QTY: {product.qty}
                              </p>

                              <p className="text-white">
                                Total: {FormatNumber(product.precio)} MXN{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}

                  {cartItemsPacks.length === 0
                    ? null
                    : cartItemsPacks.map((product) => (
                        <div className="flex-column-start text-white border-bottom-1 mt2">
                          <div className="flex-start">
                            <div className="">
                              <IconButton
                                className="btn-delete-item-sidebar"
                                onClick={() =>
                                  removeFromCartPacksHandler(product.productId)
                                }
                              >
                                <CloseIcon className="icon-delete-item-sidebar" />
                              </IconButton>
                            </div>
                            <div className="line-height-0">
                              <p className="text-white">{product.Nombre}</p>
                              {/* <p>{product.descripcion} </p> */}

                              <div className="flex">
                                <p className="mr3 text-white">
                                  QTY: {product.qty}
                                </p>

                                {/* <p className='mx1'>X</p>

																<p className='mr2'>
																	{FormatNumber(product.precio)} MXN
																</p> */}

                                <p className="text-white">
                                  Total:
                                  {FormatNumber(product.precio * product.qty)}
                                  MXN
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>

                {cartItems.length === 0 &&
                cartItemsPacks.length === 0 ? null : (
                  <div className="flex-column text-white mt2">
                    <h2 className="my2 text-white">
                      total: {FormatNumber(total())} MXN
                    </h2>
                    <Button
                      className="btn-primario"
                      onClick={function () {
                        navigate(PAY_CHECKOUT)
                        handleOpenChekout()
                        handleOpenCart()
                      }}
                    >
                      {t("header.Btn-Btn-Checkout")}
                    </Button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </AppBar>
      </Box>
      <Login open={openLogin} onClose={handleCloseLogin} props={props} />
    </>
  )
}
export default Header
