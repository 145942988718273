import React, { useEffect, useState } from 'react'
// import { successIcon, errorIcon, closeIcon } from '../icons'
// import Icon from '../Icon'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import CheckIcon from '@mui/icons-material/Check'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AlertReset } from '../../Slices/Alert/alertSlice'
import '../../Styles/Alerts.css'
import CloseIcon from '@mui/icons-material/Close'

const Alerts = (props) => {
	const [t, i18n] = useTranslation('global')
	const showAlert = useSelector((state) => state.alert)
	const { openAlert } = showAlert
	const dispatch = useDispatch()
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		dispatch(AlertReset(false))
	}

	return (
		<>
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={openAlert}
					autoHideDuration={1500}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						variant={props.variant}
						severity={props.severity}
						sx={{ width: '100%' }}
					>
						{i18n.language === 'En' ? (
							<>{props.mensaje.messageEN}</>
						) : (
							<>{props.mensaje.messageES}</>
						)}
					</Alert>
				</Snackbar>
			</Stack>
		</>
	)
}

const AlertCollapse = (props) => {
	const [t, i18n] = useTranslation('global')
	const showAlert = useSelector((state) => state.alert)
	const { openAlert } = showAlert
	const dispatch = useDispatch()

	if (openAlert === true) {
		setTimeout(() => {
			dispatch(AlertReset(false))
		}, 1500)
	}

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Collapse in={openAlert}>
					<Alert
						severity={props.severity}
						icon={
							props.severity === 'success' ? (
								<CheckIcon className='checkIcon' fontSize='inherit' />
							) : (
								<CloseIcon className='checkIcon' fontSize='inherit' />
							)
						}
						className={
							props.severity === 'success'
								? 'txtAlertCollapse'
								: 'txtAlertCollapseError'
						}
					>
						{i18n.language === 'En' ? (
							<>{props.mensaje.messageEN}</>
						) : (
							<>{props.mensaje.messageES}</>
						)}
					</Alert>
				</Collapse>
			</Box>
		</>
	)
}

export { Alerts, AlertCollapse }
