import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	cartPacksId: '',
}

export const orderPacksSlice = createSlice({
	name: 'orderPacks',
	initialState,
	reducers: {
		orderPacks_Success: (state, action) => {
			return {
				...state,
				cartPacksId: action.payload,
			}
		},
		orderPacks_Remove: (state, action) => {
			return {
				...state,
				cartPacksId: {},
			}
		},
	},
})

export const { orderPacks_Success, orderPacks_Remove } = orderPacksSlice.actions
export default orderPacksSlice.reducer
