import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import Button from '@mui/material/Button'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import '../../Styles/Modals/PurchaseDetail.css'
import TermsConditions from './TermsConditions'
import { useTranslation } from 'react-i18next'
import { PURCHASEORES_BY_ID } from '../../Constants/routesConstants'
import requests from '../AxiosCalls/AxiosCall'
import { NumericFormat } from 'react-number-format'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const PurchaseDetail = (props) => {
	const [t, i18n] = useTranslation('global')
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		props.onClose()
	}
	const handleOpen = (id) => {
		if (open === false) {
			setOpen(!open)
		} else {
			setOpen(!open)
		}
	}
	const list = [
		{
			title: 'Beach Sunbed',
		},
		{
			title: 'Nectar Experience',
		},
	]

	const [_OrderInfo, setOrderInfo] = useState({
		lngIdOrdenCompra: 0,
		txtFolio: '',
		numCantidad: 1,
		fecCompra: '',
		lngIdCarrito: 0,
		txtNombreEstatus: '',
		idTransaction: null,
		cliente: '',

		dblSubTotal: 0,
		dblDecuento: 0,
		dblIva: 0,
		dblImpuesto: 0,
		dblTotal: 0,
		dblTotalProdPack:0,
	})
	const [_OrderProduct, setOrderProduct] = useState([])
	const [_OrderPackage, setOrderPackage] = useState([])
	const [_Ocultar, setOcultar] = useState(true)

	const id = props.BillingId

	useEffect(() => {
		getRegistros()
	}, [id])

	const getRegistros = () => {
		if (id != null) {
			// props.setOpenLoadingScreen()
			requests
				.get(PURCHASEORES_BY_ID + id)
				.then((response) => {
					setOrderInfo(response.info)
					setOrderProduct(response.products)
					setOrderPackage(response.packages)

					if (response.packages.length > 0) {
						setOcultar(false)
					} else {
						setOcultar(true)
					}
				})
				.catch((error) => {
					// props.setCloseLoadingScreen()
				})
		}
	}
	return (
		<div>
			<Dialog
				fullScreen
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				TransitionComponent={Transition}
				PaperProps={{
					style: {
						background:
							window.screen.width <= 600
								? 'white'
								: 'linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)',
					},
				}}
				className={'container-modal, backdropfilter'}
			>
				<div className='container-modal-purchase'>
					<div className='header-modal-purchase flex-between '>
						<div className='flex gap1'>
							<p className='co-white text-folio-purchaseDetail font-weight6 '>
								<strong>Folio:</strong> {_OrderInfo.txtFolio}
							</p>
						</div>
						<div className='flex gap1'>
							<p className='co-white text-folio-purchaseDetail font-weight6 '>
								{t('PurchaseDetail.Date')}:&nbsp;
							</p>
							<p className='co-white text-folio-purchaseDetail font-weight5 '>
								{_OrderInfo.fecCompra}
							</p>
							<IconButton color='inherit' onClick={handleClose}>
								<CloseIcon sx={{ fontSize: 35 }} />
							</IconButton>
						</div>
					</div>
					{/* ******************** */}
					<div className='container-body-modal'>
						<div className='flex-between mb1'>
							<p className='font-weight6 text-usuario-purchaseDetail upperCase'>
								{_OrderInfo.cliente}
							</p>
							<div className='flex gap1 status'>
								<p className=' font-weight6 upperCase text-estado-purchaseDetail'>
									{t('PurchaseDetail.Status')}:&nbsp;
								</p>
								<p className=' upperCase text-estado-purchaseDetail'>
									{_OrderInfo.txtNombreEstatus}
								</p>
							</div>
						</div>

						{/* ********* P R O D U C T O S *********** */}
						<div className='scrollPurchase'>
							{_OrderProduct.map((product, index) => (
								<div
									key={product.lngIdCarritoProducto}
									className='flex-column mb2 '
								>
									<div className='flex w100 upperCase'>
										<div className='flex-start w30'>
											<p className='font-weight6 title-flex-purchaseDetail'>
												{_OrderInfo.fecCompra} @ RosaNegra Beach Club
											</p>
										</div>
										<div className='flex w20'>
											<p className='font-weight6 text-center title-flex-purchaseDetail'>
												{t('PurchaseDetail.QTY')}
											</p>
										</div>
										<div className='flex w20'>
											<p className='font-weight6 text-center title-flex-purchaseDetail'>
												{t('PurchaseDetail.TotalGuests')}
											</p>
										</div>
										<div className='flex w20'>
											<p className='font-weight6 text-center title-flex-purchaseDetail'>
												{t('PurchaseDetail.Subtotal')}
											</p>
										</div>
										<div className='flex w10'>
											<p className='font-weight6 text-center title-flex-purchaseDetail'>
												{t('PurchaseDetail.Terms')}
											</p>
										</div>
									</div>

									<div className='flex w100 heightDiv'>
										<div className='flex-start w30'>
											<span className='font-weight6 toUpperCase'>
												{product.txtNombreProducto}
											</span>
										</div>
										<div className='flex w20'>
											<p className='font-weight6 text-center subtitle-flex-purchaseDetail'>
												{product.numCantidad}
											</p>
										</div>
										<div className='flex w20'>
											<p className='font-weight6 text-center subtitle-flex-purchaseDetail'>
												{product.intPax}
											</p>
										</div>
										<div className='flex w20'>
											<p className='font-weight6 text-center subtitle-flex-purchaseDetail'>
												<NumericFormat
													value={product.dblSubTotal.toFixed(4)}
													displayType={'text'}
													fixedDecimalScale={true}
													decimalScale={2}
													thousandSeparator={true}
													prefix={' $'}
												/>
												{/* ${product.dblSubTotal.toString().match(/^\d+(?:\.\d{0,2})?/)} */}
												{/* {product.dblSubTotal.toFixed(2)} */}
											</p>
										</div>
										<div className='flex w10'>
											<IconButton
												onClick={function () {
													handleOpen(1)
												}}
											>
												<DescriptionOutlinedIcon className='iconInfo' />
											</IconButton>
										</div>
									</div>
								</div>
							))}

							{/* ********* P A Q U E T E S *********** */}
							{_OrderPackage.map((packagee, index) => (
								<div
									key={packagee.lngIdCarritoPaquete}
									className='flex-column mb2 '
								>
									<div className='flex w100 upperCase'>
										<div className='flex-start w50'>
											<p className='font-weight6 title-flex-purchaseDetail'>
												{_OrderInfo.fecCompra} @ RosaNegra Beach Club
											</p>
										</div>
										<div className='flex w20'>
											<p className='font-weight6 text-center title-flex-purchaseDetail'>
												{t('PurchaseDetail.QTY')}
											</p>
										</div>

										<div className='flex w20'>
											<p className='font-weight6 text-center title-flex-purchaseDetail'>
												{t('PurchaseDetail.Subtotal')}
											</p>
										</div>
										<div className='flex w10'>
											<p className='font-weight6 text-center title-flex-purchaseDetail'>
												{t('PurchaseDetail.Terms')}
											</p>
										</div>
									</div>
									<div className='flex w100 heightDiv'>
										<div className='flex-start w50'>
											<span className='font-weight6 title-flex-purchaseDetail-product '>
												{packagee.txtNombrePaquete}
											</span>
										</div>
										<div className='flex w20'>
											<p className='font-weight6 text-center subtitle-flex-purchaseDetail'>
												{packagee.numCantidad}
											</p>
										</div>

										<div className='flex w20'>
											<p className='font-weight6 text-center subtitle-flex-purchaseDetail'>
												<NumericFormat
													value={packagee.dblSubTotal.toFixed(4)}
													displayType={'text'}
													fixedDecimalScale={true}
													decimalScale={2}
													thousandSeparator={true}
													prefix={' $'}
												/>
												{/* ${packagee.dblSubTotal.toString().match(/^\d+(?:\.\d{0,2})?/)} */}

											</p>
										</div>
										<div className='flex w10'>
											<IconButton
												onClick={function () {
													handleOpen(1)
												}}
											>
												<DescriptionOutlinedIcon className='iconInfo' />
											</IconButton>
										</div>
									</div>
								</div>
							))}
						</div>

						<div className='container-total-purchase'>
							<div className='flex gap2'>
								<div className='text-end'>
									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										{t('PurchaseDetail.Total')}
									</p>
									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										{t('PurchaseDetail.Discount')}
									</p>

									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										{t('PurchaseDetail.Subtotal')}
									</p>
									
									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										{t('PurchaseDetail.IVA')}
									</p>
									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										{t('PurchaseDetail.AdminFee')}
									</p>

									<p className='font-weight8 text-subtotal-purchaseDetail upperCase'>
										{t('PurchaseDetail.Paynow')}
									</p>
								</div>

								<div className='text-start'>
									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										<NumericFormat
											value={_OrderInfo.dblTotalProdPack.toFixed(4)}
											displayType={'text'}
											fixedDecimalScale={true}
											decimalScale={2}
											thousandSeparator={true}
											prefix={' $'}
										/>
										{/* ${_OrderInfo.dblTotalProdPack.toString().match(/^\d+(?:\.\d{0,2})?/)} */}
										
									</p>

									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										<NumericFormat
											value={_OrderInfo.dblDecuento.toFixed(4)}
											displayType={'text'}
											fixedDecimalScale={true}
											decimalScale={2}
											thousandSeparator={true}
											prefix={' $'}
										/>
										{/* ${_OrderInfo.dblDecuento.toString().match(/^\d+(?:\.\d{0,2})?/)} */}

									</p>
									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										<NumericFormat
											value={_OrderInfo.dblSubTotal.toFixed(4)}
											displayType={'text'}
											fixedDecimalScale={true}
											decimalScale={2}
											thousandSeparator={true}
											prefix={' $'}
										/>
										{/* ${_OrderInfo.dblSubTotal.toString().match(/^\d+(?:\.\d{0,2})?/)} */}

									</p>
									
									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										<NumericFormat
											value={_OrderInfo.dblIva.toFixed(4)}
											displayType={'text'}
											fixedDecimalScale={true}
											decimalScale={2}
											thousandSeparator={true}
											prefix={' $'}
										/>
										{/* ${_OrderInfo.dblIva.toString().match(/^\d+(?:\.\d{0,2})?/)} */}

									</p>
									<p className='co-light-gray font-weight6 text-subtotal-purchaseDetail'>
										<NumericFormat
											value={
												(_OrderInfo.dblImpuesto + _OrderInfo.dblCostoOperaciones).toFixed(4)
											}
											displayType={'text'}
											fixedDecimalScale={true}
											decimalScale={2}
											thousandSeparator={true}
											prefix={' $'}
										/>
										{/* ${(_OrderInfo.dblImpuesto + _OrderInfo.dblCostoOperaciones).toString().match(/^\d+(?:\.\d{0,2})?/)} */}

									</p>
									<p className='font-weight8 text-subtotal-purchaseDetail'>
										<NumericFormat
											value={_OrderInfo.dblTotal.toFixed(4)}
											displayType={'text'}
											fixedDecimalScale={true}
											decimalScale={2}
											thousandSeparator={true}
											prefix={' $'}
										/>

										{/* {_OrderInfo.dblTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
										{/* ${_OrderInfo.dblTotal.toString().match(/^\d+(?:\.\d{0,2})?/)} */}

									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Dialog>

			{_OrderInfo !== null ? (
				<TermsConditions
					setOrdercomp={_OrderInfo}
					open={open}
					onClose={handleOpen}
					CheckTermprops={handleOpen}
				/>
			) : null}

			{/* <TermsConditions open={open} onClose={handleOpen} /> */}
		</div>
	)
}

export default PurchaseDetail
