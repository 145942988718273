import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	ordercomp: [],
	cartProduct: [],
	cartOrderpacks: [],
	loading: false,
	error: '',
}

export const getOrderItemSlice = createSlice({
	name: 'getOrderItem',
	initialState,
	reducers: {
		getOrderItem_Details_Init: (state) => {
			return { ...state, loading: true }
		},
		getOrderItem_Details_Success: (state, action) => {
			const item = action.payload
			return {
				...state,
				ordercomp: item.ordenCompra,
				cartProduct: item.product,
				cartOrderpacks: item.packages,
			}
		},
		getOrderItem_Details_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		getOrderItem_Details_Remove: (state, action) => {
			return {
				...state,
				ordercomp: {},
				cartProduct: [],
				cartOrderpacks: [],
			}
		},
	},
})

export const {
	getOrderItem_Details_Init,
	getOrderItem_Details_Success,
	getOrderItem_Details_Fail,
	getOrderItem_Details_Remove,
} = getOrderItemSlice.actions
export default getOrderItemSlice.reducer
