import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	cartId: '',
	loading: false,
	error: '',
}

export const createOrderSlice = createSlice({
	name: 'createOrder',
	initialState,
	reducers: {
		createOrder_Init: (state) => {
			return { ...state, loading: true }
		},
		createOrder_Success: (state, action) => {
			return {
				...state,
				cartId: action.payload,
			}
		},
		createOrder_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		createOrder_Remove: (state) => {
			return {
				...state,
				cartId: null,
			}
		},
	},
})

export const {
	createOrder_Init,
	createOrder_Success,
	createOrder_Fail,
	createOrder_Remove,
} = createOrderSlice.actions
export default createOrderSlice.reducer
