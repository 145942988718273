import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	success: false,
	error: '',
}
export const userDeleteSlice = createSlice({
	name: 'userDelete',
	initialState,
	reducers: {
		user_Delete_Init: (state) => {
			return { ...state, loading: true }
		},
		user_Delete_Succes: (state, action) => {
			return { ...state, loading: false, success: true }
		},
		user_Delete_Failt: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
	},
})

export const { user_Delete_Init, user_Delete_Succes, user_Delete_Failt } =
	userDeleteSlice.actions
export default userDeleteSlice.reducer
