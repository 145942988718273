import React, { Suspense, lazy, useState } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'


// import St3Cart from './St3Cart'
// import St4Checkout from './St4Checkout'
// import St5Information from './St5Information'
// import St6Payment from './St6Payment'
// import St7Confirmation from './St7Confirmation'

import '../../Styles/PaymentFlow/PaymentStepper.css'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Check from '@mui/icons-material/Check'
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useEffect } from 'react'

const St3Cart =lazy(()=>import('./St3Cart'))
const St5Information =lazy(()=>import('./St5Information'))
const St4Checkout =lazy(()=>import('./St4Checkout'))
const St6Payment =lazy(()=>import('./St6Payment'))
const St7Confirmation =lazy(()=>import('./St7Confirmation'))

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 18,
		width: '100%',
		left: 'calc(-50% + 0px)',
    	right: 'calc(50% + 20px)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				'linear-gradient( 95deg,rgb(0,0,0) 0%,rgb(0,0,0) 50%,rgb(0,0,0) 100%)',
			boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.9)',
			height: 3,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				'linear-gradient( 95deg,rgb(0,0,0) 0%,rgb(0,0,0) 50%,rgb(0,0,0) 100%)',
			boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.9)',
			height: 3,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 2,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#868686',
		borderRadius: 1,
	},
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#333333',
	zIndex: 1,
	color: '#fff',
	width: 35,
	height: 35,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundImage:
			'linear-gradient( 136deg, rgb(0,0,0) 0%, rgb(0,0,0) 50%, rgb(0,0,0) 100%)',
		boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.9)',
		width: 35,
		height: 35,
	}),
	...(ownerState.completed && {
		backgroundImage:
			'linear-gradient( 136deg, rgb(51 51 51) 0%, rgb(51 51 51) 50%, rgb(51 51 51) 100%)',
		width: 35,
		height: 35,
	}),
}))

function ColorlibStepIcon(props) {
	const { active, completed, className } = props

	return (
		<ColorlibStepIconRoot
			ownerState={{ completed, active }}
			className={className}
		>
			{completed ? (
				<Check className='QontoStepIcon-completedIcon' />
			) : (
				<div className='QontoStepIcon-circle' />
			)}
			{active ? <FiberManualRecordIcon /> : <></>}
		</ColorlibStepIconRoot>
	)
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
}

const PaymentStepper = (props) => {
	const [t, i18n] = useTranslation('global')
	const [activeStep, setActiveStep] = useState(0)
	// t('Login.Title'),
	const steps = [
		t('PaymentStepper.Cart'),
		t('PaymentStepper.Information'),
		t('PaymentStepper.Chechout'),
		t('PaymentStepper.Paymant'),
		t('PaymentStepper.Confirmation'),
	]
	const nextStep = () => {
		if (activeStep < 4) {
			setActiveStep((currentStep) => currentStep + 1)
		}
	}
	const previousStep = () => {
		setActiveStep((currentStep) => currentStep - 1)
	}

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<Suspense fallback={<div></div>}>
						<St3Cart
							nextStep={nextStep}
							previousStep={previousStep}
							props={props}
						/>

					</Suspense>

				)
			case 1:
				return (
					<Suspense fallback={<div></div>}>

						<St5Information
							nextStep={nextStep}
							previousStep={previousStep}
							props={props}
						/>
				</Suspense>

				)
			case 2:
				return (
					<Suspense fallback={<div></div>}>

						<St4Checkout
							nextStep={nextStep}
							previousStep={previousStep}
							props={props}
						/>
				</Suspense>

				)
			case 3:
				return (
					<Suspense fallback={<div></div>}>

						<St6Payment
							nextStep={nextStep}
							previousStep={previousStep}
							props={props}
						/>
					</Suspense>

				)

			case 4:
				return (
					<Suspense fallback={<div></div>}>
						<St7Confirmation nextStep={nextStep} previousStep={previousStep} />
					</Suspense>

				)
			default:
				return 'Unknown step'
		}
	}
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<div className='container-Pay'>
				<Box sx={{ width: '100%' }}>
					<Stepper
						activeStep={activeStep}
						alternativeLabel
						connector={<ColorlibConnector />}
					>
						{steps.map((label, index) => (
							<Step
								key={label}
								sx={{
									'& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
										{
											fontSize: window.screen.width <= 600 ? 0 : 18,
											fontWeight: window.screen.width <= 600 ? null : '600',
											textTransform:
												window.screen.width <= 600 ? null : 'uppercase',
											color:
												window.screen.width <= 600 // Just text label (COMPLETED)
													? 'transparent'
													: '#00000099',
										},

									'& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
										{
											fontSize: window.screen.width <= 600 ? 12 : 18,
											
											fontWeight: '600',
											color: '#000000', // Just text label (ACTIVE)
											textTransform: 'uppercase',
											marginBottom: 0,
										},
									'& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
										{
											fontSize: window.screen.width <= 600 ? 0 : 18,
											fontWeight: window.screen.width <= 600 ? null : '600',
											textTransform:
												window.screen.width <= 600 ? null : 'uppercase',
											color:
												window.screen.width <= 600 // Just text label (DISABLED)
													? 'transparent'
													: '#00000099',
										},
								}}
							>
								<StepLabel StepIconComponent={ColorlibStepIcon}>
									{label}
								</StepLabel>
							</Step>
						))}
					</Stepper>
					<div className='componentsContainer'>
						<div>{getStepContent(activeStep)}</div>
					</div>
				</Box>
			</div>
		</div>
	)
}

export default PaymentStepper
