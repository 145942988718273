import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	cartId: '',
	loading: false,
	error: '',
}
export const createOrderItemSlice = createSlice({
	name: 'createOrderItem',
	initialState,
	reducers: {
		createOrderItem_Init: (state) => {
			return { ...state, loading: true }
		},
		createOrderItem_Success: (state, action) => {
			return {
				...state,
				cartId: action.payload,
			}
		},
		createOrderItem_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		createOrderItem_Remove: (state) => {
			return {
				...state,
				cartId: null,
			}
		},
	},
})

export const {
	createOrderItem_Init,
	createOrderItem_Success,
	createOrderItem_Fail,
	createOrderItem_Remove,
} = createOrderItemSlice.actions
export default createOrderItemSlice.reducer
