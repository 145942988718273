import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	cartItems: [],
	reserDate: '',
	reserHoue: '',
}

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		cart_Add_Item: (state, action) => {
			
			const item = action.payload		
			const existItem = state.cartItems.find(
				(x) => x.productId === item.productId && x.uuid === item.uuid
			)
			
			if (existItem) {				
				// additional condition
					// if(item.qtyPeople === 0){
					// 	return {
					// 		...state,
					// 		cartItems: state.cartItems.filter((x, index) => x.uuid !== item.uuid	)
					// 	}
					// }
					return {
						...state,
						cartItems: state.cartItems.map((x) =>
							x.uuid === existItem.uuid ? item : x
						),
					}
				
			} else {
				
				return { ...state, cartItems: [...state.cartItems, item] }
			}
		},
		cart_Remove_Item: (state, action) => {
			let data = action.payload			
			if (state.cartItems.length === 1) {
				return {
					...state,
					cartItems: [],
				}
			} else {
				return {
					...state,
					cartItems: state.cartItems.filter(
						(x, index) => x.uuid !== data
					),
				}
			}
		},
		cart_Remove_Item_Product:(state, action) => {
			let data = action.payload	


			if (state.cartItems.length === 1) {
				return {
					...state,
					cartItems: [],
				}
			} 
			else
			{
				return {
					...state,
					cartItems: state.cartItems.filter(
						(x, index) => x.uuid !== data
					),
				}
			}
		},
		cart_Clear_Items: (state, action) => {
			return {
				...state,
				cartItems: [],
			}
		},
		cart_Save_Date: (state, action) => {
			return {
				...state,
				reserDate: action.payload,
			}
		},
		cart_Save_Hour: (state, action) => {
			return {
				...state,
				reserHour: action.payload,
			}
		},
	},
})

export const {
	cart_Add_Item,
	cart_Remove_Item,
	cart_Clear_Items,
	cart_Save_Date,
	cart_Save_Hour,
	cart_Remove_Item_Product,
} = cartSlice.actions
export default cartSlice.reducer
