import React from 'react'
import '../Styles/StyleHeader.css'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../Actions/UserActions'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { HOME } from '../Constants/routesConstants'

const AccountMenu = (props) => {
	const [t] = useTranslation('global')
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const logoutHandler = () => {
		dispatch(logout(props))
		navigate(HOME)
	}

	return (
		<PopupState
			variant='popover'
			popupId='demo-popup-menu'
			className='container-Account-Menu'
		>
			{(popupState) => (
				<React.Fragment>
					<IconButton className='icon-btn-login' {...bindTrigger(popupState)}>
						<Avatar sx={{ fontSize: 20, color: 'white' }}>
							{props.user.nombre.substring(0, 1).toUpperCase()}
						</Avatar>
					</IconButton>
					<Menu {...bindMenu(popupState)} sx={{zIndex:3000}}>
						<div className='container-Account-Menu'>
							<MenuItem onClick={logoutHandler}>
								<ListItemIcon>
									<LogoutIcon sx={{ color: '#544330' }} fontSize='small' />
								</ListItemIcon>
								<ListItemText sx={{ color: '#544330' }}>
									{' '}
									{t('Sidebar.Logout')}
								</ListItemText>
							</MenuItem>
							<Link to='/Profile' style={{ textDecoration: 'none' }}>
								<MenuItem onClick={popupState.close} className='border-top'>
									<ListItemIcon>
										<AccountCircleIcon
											sx={{ color: '#544330' }}
											fontSize='small'
										/>
									</ListItemIcon>
									<ListItemText sx={{ color: '#544330' }}>
										{t('Sidebar.Profile')}
									</ListItemText>
								</MenuItem>
							</Link>
						</div>
					</Menu>
				</React.Fragment>
			)}
		</PopupState>
	)
}

export default AccountMenu
