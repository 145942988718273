import React from 'react'

import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import store from './Context/Store'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import En_global from './Utils/Translations/En/global.json'
import Es_global from './Utils/Translations/Es/global.json'
// import '../src/Styles/GlobalStyle.css'

var ln = window.navigator.language || navigator.browserLanguage
i18next.init({
	interpolation: { escapeValue: false },
	lng: 'En',
	resources: {
		Es: {
			global: Es_global,
		},
		En: {
			global: En_global,
		},
	},
})

ReactDOM.render(
	<Provider store={store}>
		<I18nextProvider i18n={i18next}>
			<App />
		</I18nextProvider>
	</Provider>,
	document.getElementById('root')
)

// ReactDOM.render(
// 	<React.StrictMode>
// 	<Provider store={store}>

// 		<I18nextProvider i18n={i18next}>
// 			<App />
// 		</I18nextProvider>
// 	</Provider>,

// 	</React.StrictMode>,
// 	document.getElementById('root')
// )
reportWebVitals()
