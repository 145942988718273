import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	product: '',
	loading: false,
	success: false,
	error: '',
}

export const productToRatedSlice = createSlice({
	name: 'ProductToRated',
	initialState,
	reducers: {
		//product to rated
		product_ToRated_Init: (state) => {
			return {
				...state,
				loading: true,
				product: [],
			}
		},
		product_ToRated_Success: (state, action) => {
			return {
				...state,
				loading: false,
				product: action.payload,
			}
		},
		product_ToRated_Fail: (state, action) => {
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		},
	},
})

export const {
	//product to rated
	product_ToRated_Init,
	product_ToRated_Success,
	product_ToRated_Fail,
} = productToRatedSlice.actions
export default productToRatedSlice.reducer
