import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	product: '',
	loading: false,
	success: false,
	error: '',
}

export const productUpdateSlice = createSlice({
	name: 'ProductUpdate',
	initialState,
	reducers: {
		//product Update
		product_Update_Init: (state) => {
			return {
				...state,
				loading: true,
			}
		},
		product_Update_Success: (state, action) => {
			return {
				...state,
				loading: false,
				success: true,
				product: action.payload,
			}
		},
		product_Update_Fail: (state, action) => {
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		},
	},
})

export const {
	//product Update
	product_Update_Init,
	product_Update_Success,
	product_Update_Fail,
} = productUpdateSlice.actions
export default productUpdateSlice.reducer
