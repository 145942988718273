import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import '../../Styles/Profile/TableBilling.css'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		color: '#545454',
		textTransform: 'uppercase',
		fontWeight: '600',
		fontSize: window.screen.width <= 600 ? 9 : null,
		border: 0,
	},
	[`&.${tableCellClasses.body}`]: {
		color: '#545454',
		padding: window.screen.width <= 600 ? 13 : null,
		fontSize: window.screen.width <= 600 ? 8 : null,
		border: 0,
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}))

const SkeletonTableQr = () => {
	const columns = [
		{
			id: 'date',
			label: (
				<Skeleton
					width={90}
					variant='text'
					sx={{ fontSize: '16px' }}
					animation='wave'
				/>
			),
			minWidth: window.screen.width <= 600 ? 180 : 300,
		},
		{
			id: 'qty',
			label: (
				<Skeleton
					width={90}
					variant='text'
					sx={{ fontSize: '16px' }}
					animation='wave'
				/>
			),
			minWidth: window.screen.width <= 600 ? 40 : 70,
		},
		{
			id: 'guests',
			label: (
				<Skeleton
					width={90}
					variant='text'
					sx={{ fontSize: '16px' }}
					animation='wave'
				/>
			),
			minWidth: window.screen.width <= 600 ? 80 : 150,
		},
		{ id: 'iconinvoice', label: '', minWidth: 100 },
	]
	// function createData(date, qty, guests, iconinvoice) {
	// 	return { date, qty, guests, iconinvoice }
	// }
	const rows = [
		{ dato: '1' },
		{ dato: '1' },
		{ dato: '1' },
		{ dato: '1' },
		{ dato: '1' },
		{ dato: '1' },
	]
	return (
		<TableContainer sx={{ maxHeight: 440 }}>
			<Table stickyHeader aria-label='sticky table'>
				<TableHead>
					<TableRow>
						{columns.map((column, index) => (
							<StyledTableCell
								key={index}
								align={column.align}
								style={{ minWidth: column.minWidth }}
							>
								{column.label}
							</StyledTableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((rows, index) => {
						return (
							<StyledTableRow
								key={index}
								hover
								role='checkbox'
								tabIndex={-1}
								className='font-weight6'
							>
								<StyledTableCell>
									<Skeleton
										width={90}
										variant='text'
										sx={{ fontSize: '16px' }}
										animation='wave'
									/>
								</StyledTableCell>
								<StyledTableCell>
									{' '}
									<Skeleton
										width={90}
										variant='text'
										sx={{ fontSize: '16px' }}
										animation='wave'
									/>
								</StyledTableCell>
								<StyledTableCell sx={{ fontWeight: '600' }}>
									<Skeleton
										width={90}
										variant='text'
										sx={{ fontSize: '16px' }}
										animation='wave'
									/>
								</StyledTableCell>
								<StyledTableCell>
									<Skeleton
										width={90}
										variant='text'
										sx={{ fontSize: '16px' }}
										animation='wave'
									/>
								</StyledTableCell>
								<StyledTableCell>
									<Skeleton
										width={90}
										variant='text'
										sx={{ fontSize: '16px' }}
										animation='wave'
									/>
								</StyledTableCell>
								<StyledTableCell>
									<Skeleton
										width={90}
										variant='text'
										sx={{ fontSize: '16px' }}
										animation='wave'
									/>
								</StyledTableCell>
							</StyledTableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default SkeletonTableQr
