import React, { useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Radio from '@mui/material/Radio'
import Slide from '@mui/material/Slide'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import {
	BILLS_CREATE,
	BILLS_BY_ID_ORDER,
	BILLS_UPDTE,
} from '../../Constants/routesConstants'
import requests from '../AxiosCalls/AxiosCall'

import '../../Styles/Modals/ModalInvoiceBilling.css'
import '../../Styles/Modals/PurchaseDetail.css'
import { styled } from '@mui/material/styles'

import { useForm } from 'react-hook-form'

const RedditTextField = styled((props) => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'label + &': {
		marginTop: theme.spacing(3),
	},
	'& label.Mui-focused': {
		color: '#545454',
		fontWeight: '600',
	},
	'& label': {
		color: '#545454',
		fontWeight: '600',
	},
	'& label .MuiInputLabel-asterisk': {
		color: 'red',
		// order: -1,
	},
	'& .MuiFilledInput-root': {
		border: 0,
		overflow: 'hidden',
		borderRadius: 4,
		backgroundColor: '#0000000f',
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),

		'&:hover': {
			// backgroundColor: 'transparent',
		},
		'&.Mui-focused': {
			backgroundColor: '#0000000f',
			boxShadow: 0,
			borderColor: '#0000000f',
		},
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const ModaRefusedInboice = (props) => {
	const [t, i18n] = useTranslation('global')
	const [radio, setRadio] = useState(true)
	const [typeInvoice, setTypeInvoice] = useState()

	const handleClose = () => {
		props.onClose()
	}

	const TypeInvoice = (event) => {
		setRadio(false)
		setTypeInvoice(event.target.value)
	}

	/*----------------------------------------------------------- */
	const [openInfo, setOpenInfo] = useState(true)
	const handleOpenInfo = () => {
		setOpenInfo(!openInfo)
	}
	const methods = useForm()
	const { handleSubmit } = methods

	/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::	LOGICA DE PETICION	:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
	const [createInboice, setCreateInboice] = useState({
		lngIdFactura: 0,
		fecSolicitud: '',
		intIdStatusFactura: '',
		bolEmpresa: '',
		bolPersonaFisica: '',
		lngIdOrdenCompra: '',
		txtCorreo: '',
		txtRfc: '',
		txtNombre: '',
		txtApellidos: '',
		txtEstado: '',
		txtMunicipio: '',
		txtColonia: '',
		txtCalle: '',
		txtNumInterior: '',
		txtNumExterior: '',
		txtCp: '',
		txtComentariosEs: '',
		txtComentariosEn: '',
		txtUrlPdf: '',
		txtUrlXml: '',
	})

	const handleSchedule = (event) => {
		setCreateInboice({
			...createInboice,
			[event.target.name]: event.target.value,
		})
	}

	const [_PersonaFisica, setPersonaFisica] = useState()
	const [_PersonaMoral, setPersonaMoral] = useState()

	const handleTextUserDataUpdateBool = (event) => {
		setCreateInboice({
			...createInboice,
			[event.target.name]: event.target.checked,
		})
	}
	const handlePersonaFisica = (event) => {
		setPersonaMoral(event.target.checked === false)
		setPersonaFisica(event.target.checked === true)
		if (event.target.checked === true) {
			setCreateInboice({
				...createInboice,
				bolPersonaFisica: true,
				bolEmpresa: false,
			})
		} else {
			setCreateInboice({
				...createInboice,
				bolPersonaFisica: false,
				bolEmpresa: true,
			})
		}
	}

	const handlePersonaMoral = (event) => {
		setPersonaMoral(event.target.checked === true)
		setPersonaFisica(event.target.checked === false)
		if (event.target.checked === true) {
			setCreateInboice({
				...createInboice,
				bolPersonaFisica: false,
				bolEmpresa: true,
			})
		} else {
			setCreateInboice({
				...createInboice,
				bolPersonaFisica: true,
				bolEmpresa: false,
			})
		}
	}

	const LimpiarValores = () => {
		setCreateInboice({
			...createInboice,
			fecSolicitud: '',
			intIdStatusFactura: '',
			bolEmpresa: '',
			bolPersonaFisica: '',
			lngIdOrdenCompra: '',
			txtCorreo: '',
			txtRfc: '',
			txtNombre: '',
			txtApellidos: '',
			txtEstado: '',
			txtMunicipio: '',
			txtColonia: '',
			txtCalle: '',
			txtNumInterior: '',
			txtNumExterior: '',
			txtCp: '',
			txtComentariosEs: '',
			txtComentariosEn: '',
			txtUrlPdf: '',
			txtUrlXml: '',
		})
		props.getRegistros()
	}

	const id = props.BillingId

	const guardar = () => {
		props.props.props.setOpenLoadingScreen()
		createInboice.lngIdOrdenCompra = props.BillingId
		createInboice.intIdStatusFactura = 2

		const formData = new FormData()
		formData.append('LngIdFactura',createInboice.lngIdFactura === null ? '' : createInboice.lngIdFactura)
		formData.append('IntIdStatusFactura',createInboice.intIdStatusFactura === null? '': (createInboice.intIdStatusFactura = 2))
		formData.append('bolEmpresa',createInboice.bolEmpresa === null ? '' : createInboice.bolEmpresa)
		formData.append('bolPersonaFisica',createInboice.bolPersonaFisica === null? '': createInboice.bolPersonaFisica)
		formData.append('lngIdOrdenCompra',createInboice.lngIdOrdenCompra === null? '': (createInboice.lngIdOrdenCompra = props.BillingId))
		formData.append('txtCorreo',createInboice.txtCorreo === null ? '' : createInboice.txtCorreo)
		formData.append('txtRfc',createInboice.txtRfc === null ? '' : createInboice.txtRfc)
		formData.append('txtNombre',createInboice.txtNombre === null ? '' : createInboice.txtNombre)
		formData.append('txtApellidos',createInboice.txtApellidos === null ? '' : createInboice.txtApellidos)
		formData.append('txtEstado',createInboice.txtEstado === null ? '' : createInboice.txtEstado)
		formData.append('txtMunicipio',createInboice.txtMunicipio === null ? '' : createInboice.txtMunicipio)
		formData.append('txtColonia',createInboice.txtColonia === null ? '' : createInboice.txtColonia)
		formData.append('txtCalle',createInboice.txtCalle === null ? '' : createInboice.txtCalle)
		formData.append('txtNumInterior',createInboice.txtNumInterior === null ? '' : createInboice.txtNumInterior)
		formData.append('txtNumExterior',createInboice.txtNumExterior === null ? '' : createInboice.txtNumExterior)
		formData.append('txtCp',createInboice.txtCp === null ? '' : createInboice.txtCp)
		formData.append('txtComentariosEs',createInboice.txtComentariosEs === null? '': createInboice.txtComentariosEs)
		formData.append('txtComentariosEn',createInboice.txtComentariosEn === null? '': createInboice.txtComentariosEn)
		formData.append('fecSolicitud',createInboice.fecSolicitud === null ? '' : createInboice.fecSolicitud)
		formData.append('txtUrlPdf', createInboice.txtUrlPdf === null ? '' : null)
		formData.append('txtUrlXml', createInboice.txtUrlXml === null ? '' : null)

		requests
			.put(BILLS_UPDTE, formData)
			.then((response) => {
				props.props.props.setTypeSnackBar('success')
				props.props.props.setMessageSnackBar(response.message)
				props.props.props.setOpenSnackBar(true)
				handleClose()
				props.props.props.setCloseLoadingScreen()
				LimpiarValores()
			})
			.catch((error) => {
				props.props.props.setTypeSnackBar('warning')
				props.props.props.setMessageSnackBar(error.data.messageES)
				props.props.props.setOpenSnackBar(true)
				props.props.props.setCloseLoadingScreen()
				//
			})
	}

	// LAs facturas solicitados los ultimos 3 dias, deshabilitar la regla de los primeros 2 dias.
	// 1	CREADO
	// 2	SOLICITADO  -- se va en estatus 2
	// 3	APROBADO
	// 4	RECHAZADO -- llega rechazaso
	// 5	PENDIENTE
	// 6	COMPLETADO
	// 7	EXPIRADO

	//const [createInboice, setRegistros] = React.useState([])
	const getinfoRefused = () => {
		requests
			.get(BILLS_BY_ID_ORDER, props.BillingId)
			.then((response) => {
				setCreateInboice(response)
				//////handleUserDataUpdate(response)
				setPersonaFisica(response.bolPersonaFisica)
				setPersonaMoral(response.bolEmpresa)
			})
			.catch((error) => {
			})
	}
	
	useEffect(() => {
		getinfoRefused()
	}, [])
	return (
		<Dialog
			fullScreen
			open={props.open}
			onClose={props.onClose ? props.onClose : null}
			TransitionComponent={Transition}
			PaperProps={{
				style: {
					background:
						window.screen.width <= 600
							? 'white'
							: 'linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)',
				},
			}}
			className={'container-modal, backdropfilter'}
		>
			<div className='container-modal-Invoice'>
				<div className='Header-Modal-Invoice flex'>
					<p className='co-white fs18 font-weight6 upperCase'>
						Factura Rechazada
					</p>
					<IconButton aria-label='delete' onClick={handleOpenInfo}>
						<InfoOutlinedIcon sx={{ color: 'white' }} />
					</IconButton>
				</div>
				{/* ******************** */}
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='Body-Modal-Invoice flex-column text-center'>
						<Collapse in={openInfo}>
							<Alert
								className='mt2'
								severity='warning'
								action={
									<IconButton
										aria-label='close'
										color='inherit'
										size='small'
										onClick={() => {
											setOpenInfo(false)
										}}
									>
										<CloseIcon fontSize='inherit' />
									</IconButton>
								}
							>
								{/* Hola NombreCliente notamos que los siguientes datos estan erroneos, Favor de validar, recuerda que solo puedes solicitar
								tu factura el mes en que se realizo la compra, llena tus datos correctamente a continuacion. */}
								{createInboice.txtComentariosEs}
							</Alert>
						</Collapse>
						<div className='w100 mt1 mb1 flex gap1'>
							<RadioGroup
								className='w100'
								row
								onChange={TypeInvoice}
							></RadioGroup>
						</div>

						<RadioGroup row className='w100 mb2' onChange={TypeInvoice}>
							<Grid container spacing={2}>
								<Grid item xs={6} lg={6}>
									<div className='radioBtn w100'>
										<FormControlLabel
											className='w95'
											// value='1'
											control={<Radio />}
											label='Empresa'
											labelPlacement='start'
											checked={_PersonaMoral}
											name='bolEmpresa'
											id='bolEmpresa'
											// onChange={handleTextUserDataUpdateBool}
											onChange={handlePersonaMoral}
										/>
									</div>
								</Grid>

								<Grid item xs={6} lg={6}>
									<div className='radioBtn w100'>
										<FormControlLabel
											className='w95'
											// value='2'
											control={<Radio />}
											label='Persona fisica'
											labelPlacement='start'
											checked={_PersonaFisica}
											name='bolPersonaFisica'
											// onChange={handleTextUserDataUpdateBool}
											onChange={handlePersonaFisica}
											id='bolPersonaFisica'
										/>
									</div>
								</Grid>
							</Grid>
						</RadioGroup>

						<Box noValidate>
							<Grid container spacing={2}>
								<Grid item xs={6} lg={6}>
									{typeInvoice == 1 ? (
										<RedditTextField
											disabled={radio}
											variant='filled'
											value={createInboice.txtRfc}
											required
											fullWidth
											focused
											inputProps={{
												maxLength: 13,
											}}
											id='txtRfc'
											label='RFC'
											name='txtRfc'
											onChange={handleSchedule}
										/>
									) : (
										<RedditTextField
											disabled={radio}
											value={createInboice.txtRfc}
											variant='filled'
											required
											fullWidth
											focused
											inputProps={{
												maxLength: 14,
											}}
											id='txtRfc'
											label='RFC'
											name='txtRfc'
											onChange={handleSchedule}
										/>
									)}
								</Grid>

								<Grid item xs={6} lg={6}>
									<RedditTextField
										variant='filled'
										required
										fullWidth
										focused
										id='txtCorreo'
										label='E-mail'
										name='txtCorreo'
										onChange={handleSchedule}
										value={createInboice.txtCorreo}
									/>
								</Grid>

								<Grid item xs={6} lg={6}>
									{_PersonaMoral === true ? (
										<RedditTextField
											variant='filled'
											required
											fullWidth
											focused
											id='txtNombre'
											label='Razón social'
											name='txtNombre'
											onChange={handleSchedule}
										/>
									) : (
										<RedditTextField
											variant='filled'
											required
											fullWidth
											focused
											id='txtNombre'
											label='Nombre Completo'
											name='txtNombre'
											onChange={handleSchedule}
											value={createInboice.txtNombre}
										/>
									)}
								</Grid>

								<Grid item xs={6} lg={6}>
									<RedditTextField
										variant='filled'
										required
										fullWidth
										focused
										id='txtEstado'
										label='Estado'
										name='txtEstado'
										onChange={handleSchedule}
										value={createInboice.txtEstado}
									/>
								</Grid>

								<Grid item xs={6} lg={6}>
									<RedditTextField
										variant='filled'
										fullWidth
										focused
										required
										id='txtMunicipio'
										label='Municipio'
										name='txtMunicipio'
										onChange={handleSchedule}
										value={createInboice.txtMunicipio}
									/>
								</Grid>

								<Grid item xs={6} lg={6}>
									<RedditTextField
										variant='filled'
										fullWidth
										focused
										required
										id='txtColonia'
										label='colonia'
										name='txtColonia'
										onChange={handleSchedule}
										value={createInboice.txtColonia}
									/>
								</Grid>

								<Grid item xs={12} lg={12}>
									<RedditTextField
										variant='filled'
										fullWidth
										focused
										required
										id='txtCalle'
										label='calle'
										name='txtCalle'
										onChange={handleSchedule}
										value={createInboice.txtCalle}
									/>
								</Grid>

								<Grid item xs={4} lg={4}>
									<RedditTextField
										variant='filled'
										fullWidth
										focused
										required
										id='txtNumInterior'
										label='No. Interior'
										name='txtNumInterior'
										onChange={handleSchedule}
										value={createInboice.txtNumInterior}
									/>
								</Grid>

								<Grid item xs={4} lg={4}>
									<RedditTextField
										variant='filled'
										fullWidth
										focused
										required
										id='txtNumExterior'
										label='No. Exterior'
										name='txtNumExterior'
										onChange={handleSchedule}
										value={createInboice.txtNumExterior}
									/>
								</Grid>

								<Grid item xs={4} lg={4}>
									<RedditTextField
										variant='filled'
										fullWidth
										focused
										required
										id='txtCp'
										label='código postal'
										name='txtCp'
										onChange={handleSchedule}
										value={createInboice.txtCp}
									/>
								</Grid>
							</Grid>
							<div className='flex-between mt4 mb2 gap1'>
								<Button
									onClick={handleClose}
									className='btn-login-cancel'
									variant='outlined'
								>
									Cancelar
								</Button>
								<Button
									className='btn-login-acept'
									variant='contained'
									type='submit'
								>
									Facturar
								</Button>
							</div>
						</Box>
					</div>
				</form>
			</div>
		</Dialog>
	)
}

export default ModaRefusedInboice
