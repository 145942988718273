import axios from "axios"

// import {
// 	ORDER_REQUEST,
// 	ORDER_SUCCESS,
// 	ORDER_FAIL,
// 	ORDER_FREMOVE,
// 	ORDER_DETAIL_REQUEST,
// 	ORDER_DETAI_SUCCESS,
// 	ORDER_DETAI_FAIL,
// 	ORDER_DETAI_REMOVE,
// 	GET_ORDER_DETAIL_REQUEST,
// 	GET_ORDER_DETAI_SUCCESS,
// 	GET_ORDER_DETAI_FAIL,
// 	MATRIX_RESULT_REQUEST,
// 	MATRIX_RESULT_SUCCESS,
// 	MATRIX_RESULT_FAIL,
// } from '../Constants/CartOrderConstants'
import {
  ORDER_CREATE,
  ORDER_CREATE_DETAIL,
  ORDER_DETAIL_BY_ID,
  ORDER_COUPON,
  GET_MATRIX_RESULT,
  // ORDER_ITEM_PRODUCT,
  // ORDER_BY_CART_PRODUCT,
  // ORDER_CREATE_PACKS,
  // ORDER_ITEM_PACKS,
  // ORDER_BY_CART_PACKS,
} from "../Constants/routesConstants"
// import { CART_PACK_CLEAR_ITEMS } from '../Constants/CartPackConstants'
// import { CART_CLEAR_ITEMS } from '../Constants/cartConstants'

import Dictionary from "../Components/AxiosCalls/Dictionary"
import requests from "../Components/AxiosCalls/AxiosAuth"
// import { coupon } from './CartOrderActions'
import { Alerts, AlertCollapse } from "../Components/Alerts/Alerts"
import { AlertSucces } from "../Slices/Alert/alertSlice"
import {
  cartOrder_Init,
  cartOrder_Success,
  cartOrder_Fail,
  cartOrder_Remove,
} from "../Slices/CartOrderSlice/cartOrderSlice"

import {
  getMatrixDiscount_Success,
  getMatrixDiscount_Init,
  getMatrixDiscount_Fail,
} from "../Slices/CartOrderSlice/getMatrixDiscountSlice"

import {
  getOrderItem_Details_Init,
  getOrderItem_Details_Success,
  getOrderItem_Details_Fail,
  getOrderItem_Details_Remove,
} from "../Slices/CartOrderSlice/getOrderItemSlice"

import {
  createOrder_Init,
  createOrder_Success,
  createOrder_Fail,
  // createOrder_Remove,
} from "../Slices/CartOrderSlice/createOrderSlice"

import {
  createOrderItem_Init,
  createOrderItem_Success,
  createOrderItem_Fail,
  // createOrderItem_Remove,
} from "../Slices/CartOrderSlice/createOrderItemSlice"

import { applyCoupon_MssSucces } from "../Slices/CartOrderSlice/applyCouponSlice"
import { logout_Auth } from "../Slices/UserSlice/userSlice"
// import { user_Detail_Reset } from '../Slices/UserSlice/userDetailsSlice'
import { guest_Clear_Item } from "../Slices/GuestSlice/guestSlice"
import { cart_Clear_Items } from "../Slices/CartSlice/cartSlice"
import { cartPacks_Clear_Items } from "../Slices/CartPacksSlice/cartPacksSlice"
// import localStorage from 'redux-persist/es/storage'
const getBaseUrl = Dictionary.baseUrls.getBaseUrl

export const ToCart = () => async (dispatch, getState) => {
  try {
    dispatch(cartOrder_Init())

    const {
      user: { userInfo },
    } = getState()

    requests
      .post(ORDER_CREATE, { lngIdCliente: userInfo.id })
      .then((response) => {
        dispatch(cartOrder_Success(response.data.lngIdCarritoComp))
        localStorage.setItem(
          "carritoId",
          JSON.stringify(response.data.lngIdCarritoComp)
        )
      })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch(cartOrder_Fail(message))
  }
}

export const createToOrder =
  (seddatos, sendDatapack, fechaReser, reHr, props) =>
  async (dispatch, getState) => {
    try {
      props.props.setOpenLoadingScreen()
      dispatch(createOrder_Init())
      const {
        user: { userInfo },
      } = getState()
      requests
        .post(ORDER_CREATE, { lngIdCliente: userInfo.id })
        .then((response) => {
          dispatch(createOrder_Success(response.data.lngIdCarrito))

          localStorage.setItem(
            "carritoId",
            JSON.stringify(response.data.lngIdCarrito)
          )
          const { createOrder } = getState()
          dispatch(
            createToOrderitems(
              createOrder.cartId,
              seddatos,
              sendDatapack,
              fechaReser,
              reHr
            )
          )

          props.props.setCloseLoadingScreen()
        })
    } catch (error) {
      if ((error.status = 401)) {
        dispatch(logout_Auth())
      }
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      dispatch(createOrder_Fail(message))
    }
  }

export const createToOrderitems =
  (cartId, seddatos, sendDatapack, fechaReser, reHr) =>
  async (dispatch, getState) => {
    try {
      dispatch(createOrderItem_Init())

      const {
        user: { userInfo },
        cart: { reserDate },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access_Token}`,
        },
      }

      const { data } = await axios.post(
        getBaseUrl + ORDER_CREATE_DETAIL,
        {
          lngIdCarrito: cartId,
          fechaCompra: fechaReser,
          hrReserva: reHr,
          productItems: seddatos,
          packageItems: sendDatapack,
        },
        config
      )
      dispatch(createOrderItem_Success(data.data))

      dispatch(getToOrderitems(cartId))
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      dispatch(createOrderItem_Fail(message))
    }
  }

export const getMatrixDiscount =
  (cartId, seddatos, sendDatapack, fechaReser) =>
  async (dispatch, getState) => {
    dispatch(getMatrixDiscount_Init())
    return requests
      .post(GET_MATRIX_RESULT, {
        lngIdCarrito: cartId,
        fechaCompra: fechaReser,
        productItems: seddatos,
        packageItems: sendDatapack,
      })
      .then((response) => {
        dispatch(getMatrixDiscount_Success(response))
        const estado = getState()
        return estado.getMatrixDiscount
      })
      .catch((error) => {
        dispatch(getMatrixDiscount_Fail(error.response))
      })
  }

export const getToOrderitems = (cartId) => async (dispatch, getState) => {
  try {
    dispatch(getOrderItem_Details_Init())

    const {
      user: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access_Token}`,
      },
    }

    const { data } = await axios.post(
      getBaseUrl + ORDER_DETAIL_BY_ID + cartId,
      config
    )
    dispatch(getOrderItem_Details_Success(data.data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch(getOrderItem_Details_Fail(message))
  }
}

export const applyCoupon =
  (coupon, IdOrdenCompra, props) => async (dispatch, getState) => {
    try {
      props.props.setOpenLoadingScreen()
      const {
        user: { userInfo },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access_Token}`,
        },
      }

      const { data } = await axios.post(
        getBaseUrl + ORDER_COUPON,
        { lngIdOrdenCompra: IdOrdenCompra, numCode: coupon },
        config
      )

      if (data.success === false) {
        const message = (
          <Alerts severity="error" variant="filled" mensaje={data} />
        )
        dispatch(applyCoupon_MssSucces(message))
      } else {
        const message = (
          <Alerts severity="success" variant="filled" mensaje={data} />
        )
        dispatch(applyCoupon_MssSucces(message))
      }
      dispatch(getToOrderitems(data.lngIdCarrito))
      dispatch(AlertSucces(true))
      props.props.setCloseLoadingScreen()
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      props.props.setCloseLoadingScreen()
    }
  }

export const removeOrder = () => (dispatch) => {
  // sessionStorage.removeItem('userInfo')// datos del usuario
  sessionStorage.removeItem("cartItems") // mi carrito producto
  sessionStorage.removeItem("cartItemsPack") // mi carrito paquete
  sessionStorage.removeItem("cartItems")
  localStorage.removeItem("carritoId")
  sessionStorage.removeItem("cartPacksItems")

  dispatch(getOrderItem_Details_Remove())
  dispatch(guest_Clear_Item())
  dispatch(cart_Clear_Items())
  dispatch(cartPacks_Clear_Items())
  dispatch(cartOrder_Remove())
}
