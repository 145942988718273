import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	products: '',
	loading: false,
}

export const productDetailPackageSlice = createSlice({
	name: 'ProductDetailPackage',
	initialState,
	reducers: {
		//product List
		product_Package_Detail_Init: (state) => {
			return { ...state, loading: true }
		},
		product_Package_Detail_Success: (state, action) => {
			return { ...state, loading: false, products: action.payload }
		},
		product_Package_Detail_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
	},
})

export const {
	//product List
	product_Package_Detail_Init,
	product_Package_Detail_Success,
	product_Package_Detail_Fail,
} = productDetailPackageSlice.actions
export default productDetailPackageSlice.reducer
