import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import Button from '@mui/material/Button'
import '../../Styles/Modals/TermsConditions.css'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import requests from '../AxiosCalls/AxiosCall'
import { TERMINOS_GET_BY_ID } from '../../Constants/routesConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const TermsConditions = (props) => {
	const [t, i18n] = useTranslation('global')
	const handleClose = () => {
		props.CheckTermprops()
		props.onClose()
	}
	useEffect(() => {
		getRegistrosTerminos()
	}, [])
	const [registrosTerrminos, setRegistrosTerminos] = useState({
		txtTituloTerminos: "",
		txtContenidoTerminos: "",
		txtTituloTerminosEn: "",
		txtContenidoTerminosEn: "",
	  })

	const getRegistrosTerminos = () => {
		// props.setOpenLoadingScreen()
		requests
		  .get(TERMINOS_GET_BY_ID, 1)
		  .then((response) => {
			// setSkeleton(false)
			setRegistrosTerminos(response)
			// setId(null)
			// props.setCloseLoadingScreen()
		  })
		  .catch((error) => {
			// props.setCloseLoadingScreen()
		  })
	  }

	const listVipAREAS = [
		{title: t('TermsConditionsVIPAreas.Condition6'),},
		{title: t('TermsConditionsVIPAreas.Condition7'),},
		{title: t('TermsConditionsVIPAreas.Condition8'),},
		{title: t('TermsConditionsVIPAreas.Condition1'),},
		{title: t('TermsConditionsVIPAreas.Condition2'),},
		{title: t('TermsConditionsVIPAreas.Condition3'),},
		{title: t('TermsConditionsVIPAreas.Condition4'),},
		{title: t('TermsConditionsVIPAreas.Condition5'),},
	]
	const listReservation = [
		{title: t('TermsConditionsReservation.Condition1'),},
		{title: t('TermsConditionsReservation.Condition2'),},
		{title: t('TermsConditionsReservation.Condition3'),},
		{title: t('TermsConditionsReservation.Condition4'),},
		{title: t('TermsConditionsReservation.Condition5'),},
		{title: t('TermsConditionsReservation.Condition6'),},
		{title: t('TermsConditionsReservation.Condition7'),},
		{title: t('TermsConditionsReservation.Condition8'),},
		{title: t('TermsConditionsReservation.Condition9'),},
	]
	const listGroupReservation = [
		{title: t('TermsConditionsGroupReservation.Condition1'),},
		{title: t('TermsConditionsGroupReservation.Condition2'),},
	]
	const listRestaurantReservation = [
		{title: t('TermsConditionsRestaurantReservation.Condition1'),},
	]
	const listPackageExperience = [
		{title: t('TermsConditionsPackagesExperience.Condition1'),},
	]
	const PrivacyNotice = [
		{
			subtitle: t('PrivacyNotice.I.Subtitle'),
			body: t('PrivacyNotice.I.Body'),
		},
		{
			subtitle: t('PrivacyNotice.II.Subtitle'),
			body: t('PrivacyNotice.II.Body'),
		},
	]
	return (
		<>
			<div>
				<Dialog
					fullScreen
					open={props.open}
					onClose={props.onClose ? props.onClose : null}
					TransitionComponent={Transition}
					PaperProps={{
						style: {
							height:window.screen.width <= 600 ?"auto":"100%",
							background:
								window.screen.width <= 600
									? 'linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)'
									: 'linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)',
						},
					}}
					className={'container-modal, backdropfilter'}
				>
					<div className='container-modal-termsConditions'>
						<div className='header-modal-termsConditions flex-between'>
							{i18n.language === "Es" ?(

							<p className='co-white fs18 font-weight6 upperCase'>
								{registrosTerrminos.txtTituloTerminos}
								{/* {t('TermsConditions.Title')} */}
							</p>
							):(
								<p className='co-white fs18 font-weight6 upperCase'>
								{registrosTerrminos.txtTituloTerminosEn}
								{/* {t('TermsConditions.Title')} */}
							</p>
							)}
							<IconButton color='inherit' onClick={handleClose}>
								<CloseIcon sx={{ fontSize: 35 }} />
							</IconButton>
						</div>

						<div className='flex-between container-body-modal'>
							<div>
								
							</div>
						</div>

						
						<div className="container-body-modal">

							{i18n.language === "Es" ? (
								<p
									className="co-light-gray"
									dangerouslySetInnerHTML={{
									__html: registrosTerrminos.txtContenidoTerminos,
									}}
								></p>
							) : (
								<p
								className="co-light-gray"
								dangerouslySetInnerHTML={{
								__html: registrosTerrminos.txtContenidoTerminosEn,
								}}
								></p>                    
							)}
							<Button
								className='accept-button'
								variant='outlined'
								// onClick={props.nextStep}
								onClick={handleClose}
							>
								{t('TermsConditions.Btn-Acept')}
							</Button>
            			</div>

					</div>
				</Dialog>
			</div>
		</>
	)
}

export default TermsConditions
