import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	ordercomp: '',
	cartProduct: '',
	cartOrderpacks: '',
	cartPacksId: '',
}

export const orderByCartProductIdSlice = createSlice({
	name: 'orderByCartProductId',
	initialState,
	reducers: {
		orderByCartProductId_Success: (state, action) => {
			const item = action.payload
			return {
				...state,
				ordercomp: item.ordenCompra,
				cartProduct: item.product,
				cartOrderpacks: item.packages,
			}
		},
		orderByCartProductId_Packs_Remove: (state) => {
			return {
				...state,
				cartPacksId: {},
			}
		},
	},
})

export const {
	orderByCartProductId_Success,
	orderByCartProductId_Packs_Remove,
} = orderByCartProductIdSlice.actions
export default orderByCartProductIdSlice.reducer
