import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	success: false,
	error: '',
}

export const productDeleteSlice = createSlice({
	name: 'ProductDelete',
	initialState,
	reducers: {
		//product Delete
		product_Delete_Init: (state) => {
			return { ...state, loading: true }
		},
		product_Delete_Success: (state) => {
			return { ...state, loading: false, success: true }
		},
		product_Delete_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
	},
})

export const {
	//product Delete
	product_Delete_Init,
	product_Delete_Success,
	product_Delete_Fail,
} = productDeleteSlice.actions
export default productDeleteSlice.reducer
