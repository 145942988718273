import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	success: false,
	error: '',
}
export const userRecoveryPassSlice = createSlice({
	name: 'userRecoveryPass',
	initialState,
	reducers: {
		user_RecoveryPass_Init: (state) => {
			return { ...state, loading: true }
		},
		user_RecoveryPass_Succes: (state, action) => {
			return { ...state, loading: false, success: true }
		},
		user_RecoveryPass_Failt: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
	},
})

export const { user_RecoveryPass_Init, user_RecoveryPass_Succes, user_RecoveryPass_Failt } =
	userRecoveryPassSlice.actions
export default userRecoveryPassSlice.reducer