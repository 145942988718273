import React, { useEffect, useState } from "react"
import "../../Styles/Profile/TableQR.css"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Button from "@mui/material/Button"
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner"
import { ViewQR } from "../Modals/ViewQR"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import requests from "../AxiosCalls/AxiosCall"
import { QR_LIST_BY_ID_CLIENT } from "../../Constants/routesConstants"
import SkeletonTableQr from "../Skeleton/SkeletonTableQr"
import { styled } from "@mui/material/styles"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.white,
    backgroundColor: "#544331",
    color: "#FFFFFF",
    // color: '#545454',
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: window.screen.width <= 600 ? 9 : null,
    border: 0,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#545454",
    padding: window.screen.width <= 600 ? 13 : null,
    fontSize: window.screen.width <= 600 ? 8 : null,
    border: 0,
    textAlign: "center",
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))

const TableQR = () => {
  const [t] = useTranslation("global")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [_Registros, setRegistros] = useState([])
  const [response, setResponse] = useState(false)
  // const [_IdRegistro, setIdRegistro] = useState(null)
  const userLogin = useSelector((state) => state.user)
  const { userInfo } = userLogin
  const [openViewQRpurchase, setOpenViewQRpurchase] = useState(false)
  const [QRpurchaseId, setQRpurchaseId] = useState()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getRegistros = () => {
    setResponse(false)
    requests
      .get(QR_LIST_BY_ID_CLIENT + userInfo.id)
      .then((response) => {
        setRegistros(response)
        setResponse(true)
        // setIdRegistro(null)
      })
      .catch((error) => {
        setResponse(true)
      })
  }

  useEffect(() => {
    getRegistros()
  }, [])
  const handleOpenViewQRpurchase = (id) => {
    if (openViewQRpurchase === false) {
      setOpenViewQRpurchase(!openViewQRpurchase)
      setQRpurchaseId(id)
    } else {
      setOpenViewQRpurchase(!openViewQRpurchase)
      setQRpurchaseId(null)
    }
  }
  const columns = [
    // {
    // 	id: 'qty',
    // 	label: t('Profile-QR.QTY'),
    // 	minWidth: window.screen.width <= 600 ? 40 : 50,
    // },
    {
      id: "order",
      label: t("Profile-Billing.Order"),
      minWidth: window.screen.width <= 600 ? 45 : 80,
    },
    {
      id: "date",
      label: "Rosa Negra B.C",
      minWidth: window.screen.width <= 600 ? 180 : 150,
    },
    {
      id: "guests",
      label: t("Profile-QR.TotalGuests"),
      minWidth: window.screen.width <= 600 ? 80 : 120,
    },
    {
      id: "dateReservation",
      label: t("Profile-QR.DateReservation"),
      minWidth: window.screen.width <= 600 ? 80 : 120,
    },

    {
      id: "hourReservation",
      label: t("Profile-QR.HourReservation"),
      minWidth: window.screen.width <= 600 ? 80 : 120,
    },

    { id: "iconinvoice", label: "", minWidth: 100 },
  ]
  function createData(date, qty, guests, iconinvoice) {
    return { date, qty, guests, iconinvoice }
  }
  const BtnInvoice = (
    <Button
      onClick={function () {
        handleOpenViewQRpurchase(1)
      }}
      size="small"
      className="btn-table-QR"
      startIcon={<QrCodeScannerIcon />}
    >
      {t("Profile-QR.Btn-QR")}
    </Button>
  )

  const rows = [
    createData(
      <span className="font-weight6">Beach Sunbed</span>,
      "1",
      "5",
      BtnInvoice
    ),
  ]
  return (
    <div className="ContainerTableQR animated fadeIn fast">
      <p className="text-center font-weight6 my1">{t("Profile-QR.Title")}</p>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        {_Registros.length === 0 ? (
          <>
            {response === false ? (
              <SkeletonTableQr />
            ) : (
              <p className="text-center font-weight6 my1">
                {t("Profile-QR.NoticketList")}
              </p>
            )}
          </>
        ) : (
          <>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        sx={{
                          color: "#545454",
                          textTransform: "uppercase",
                          fontWeight: "600",
                        }}
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_Registros
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          className="font-weight6"
                        >
                          <StyledTableCell>{row.txtFolio}</StyledTableCell>
                          <StyledTableCell>{row.espacio}</StyledTableCell>
                          <StyledTableCell>{row.intPax}</StyledTableCell>
                          <StyledTableCell>{row.fecReserva}</StyledTableCell>

                          <StyledTableCell>{row.hrHorarioBc}</StyledTableCell>
                          <StyledTableCell>
                            <Button
                              onClick={function () {
                                handleOpenViewQRpurchase(
                                  // row.lngIdCarrito
                                  row.lngIdCarritoProducto
                                )
                              }}
                              size="small"
                              className="btn-table-QR"
                              startIcon={<QrCodeScannerIcon />}
                            >
                              {t("Profile-QR.Btn-QR")}
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={_Registros.length}
              // count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
      <ViewQR
        QRpurchaseId={QRpurchaseId}
        open={openViewQRpurchase}
        onClose={handleOpenViewQRpurchase}
      />
    </div>
  )
}

export default TableQR
