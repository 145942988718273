import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	loading: false,
	guestPrice: '',
	error: '',
}

export const cartGuestPriceSlice = createSlice({
	name: 'guestPrice',
	initialState,
	reducers: {
		cartGuestPrice_Init: (state) => {
			return { ...state, loading: true }
		},
		cartGuestPrice_Success: (state, action) => {			
			return { ...state, loading: false, guestPrice: action.payload }
		},
		cartGuestPrice_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
	},
})

export const {
	cartGuestPrice_Init,
	cartGuestPrice_Success,
	cartGuestPrice_Fail,
} = cartGuestPriceSlice.actions
export default cartGuestPriceSlice.reducer
