import React, { useEffect, useState } from 'react'
import '../../Styles/Login/Signin.css'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Button from '@mui/material/Button'
// import logo from '../../img/logo-login.webp'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import MenuItem from '@mui/material/MenuItem'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import { registerUser } from '../../Actions/UserActions'
import { styled } from '@mui/material/styles'
// import FormControl from '@mui/material/FormControl'
// import InputLabel from '@mui/material/InputLabel'
// import Select from '@mui/material/Select'
import requests from '../AxiosCalls/AxiosCall'
import { GETLISTLADA, GET_LIST_PAIS } from '../../Constants/routesConstants'
import { AlertReset } from '../../Slices/Alert/alertSlice'
import Select from 'react-select'
import { parse, isBefore, differenceInYears } from 'date-fns'
const RedditTextField = styled(props => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& label.Mui-focused': {
    color: '#545454',
    fontWeight: '600',
    zIndex: 0,
  },
  '& label': {
    color: '#545454',
    fontWeight: '600',
    zIndex: 0,
  },
  '& label .MuiInputLabel-asterisk': {
    color: 'red',
    // order: -1,
  },
  '& .MuiFilledInput-root': {
    border: 0,
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#0000000f',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),

    '&:hover': {
      // backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: '#0000000f',
      boxShadow: 0,
      borderColor: '#0000000f',
    },
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SignIn = props => {
  // const navigate = useNavigate()
  const [t, i18n] = useTranslation('global')
  const [values, setValues] = useState({ showPassword: false })
  const [valuesPassword, setValuesPassword] = useState({
    showPasswordConfirm: false,
  })

  // const [txtlistlada, setTxtListLada] = useState(1)
  // const [age, setAge] = useState('18')
  // const [year, setYear] = useState('2004')
  // const [month, setMonth] = useState(11)
  // const [day, setDay] = useState(1)

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }
  const handleClickShowPasswordConfirm = () => {
    setValuesPassword({
      ...valuesPassword,
      showPasswordConfirm: !valuesPassword.showPasswordConfirm,
    })
  }
  const onClose = () => {
    props.onCloseR()
    dispatch(AlertReset(false))
  }

  const Age = []

  for (let i = 18; i <= 100; i++) {
    Age.push({
      value: i,
      label: i.toString(),
    })
  }

  const Year = []

  for (let i = 1920; i <= 2004; i++) {
    Year.push({
      value: i,
      label: i.toString(),
    })
  }

  const MonthEn = [
    {
      value: 1,
      label: 'January',
    },
    {
      value: 2,
      label: 'February',
    },
    {
      value: 3,
      label: 'March',
    },
    {
      value: 4,
      label: 'April',
    },
    {
      value: 5,
      label: 'May',
    },
    {
      value: 6,
      label: 'June',
    },
    {
      value: 7,
      label: 'July',
    },
    {
      value: 8,
      label: 'August',
    },
    {
      value: 9,
      label: 'September',
    },
    {
      value: 10,
      label: 'October',
    },
    {
      value: 11,
      label: 'November',
    },
    {
      value: 11,
      label: 'December',
    },
  ]
  const MonthEs = [
    {
      value: 1,
      label: 'Enero',
    },
    {
      value: 2,
      label: 'Febrero',
    },
    {
      value: 3,
      label: 'Marzo',
    },
    {
      value: 4,
      label: 'Abril',
    },
    {
      value: 5,
      label: 'Mayo',
    },
    {
      value: 6,
      label: 'Junio',
    },
    {
      value: 7,
      label: 'Julio',
    },
    {
      value: 8,
      label: 'Agosto',
    },
    {
      value: 9,
      label: 'Septiembre',
    },
    {
      value: 10,
      label: 'Octubre',
    },
    {
      value: 11,
      label: 'Noviembre',
    },
    {
      value: 11,
      label: 'Diciembre',
    },
  ]
  const Day = [
    {
      value: 1,
      label: '01',
    },
    {
      value: 2,
      label: '02',
    },
    {
      value: 3,
      label: '03',
    },
    {
      value: 4,
      label: '04',
    },
    {
      value: 5,
      label: '05',
    },
    {
      value: 6,
      label: '06',
    },
    {
      value: 7,
      label: '07',
    },
    {
      value: 8,
      label: '08',
    },
    {
      value: 9,
      label: '09',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 11,
      label: '11',
    },
    {
      value: 12,
      label: '12',
    },
    {
      value: 13,
      label: '13',
    },
    {
      value: 14,
      label: '14',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 16,
      label: '16',
    },
    {
      value: 17,
      label: '17',
    },
    {
      value: 18,
      label: '18',
    },
    {
      value: 19,
      label: '19',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 21,
      label: '21',
    },
    {
      value: 22,
      label: '22',
    },
    {
      value: 23,
      label: '23',
    },
    {
      value: 24,
      label: '24',
    },
    {
      value: 25,
      label: '25',
    },
    {
      value: 26,
      label: '26',
    },
    {
      value: 27,
      label: '27',
    },
    {
      value: 28,
      label: '28',
    },
    {
      value: 29,
      label: '29',
    },
    {
      value: 30,
      label: '30',
    },
    {
      value: 31,
      label: '31',
    },
  ]
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const { userInfo } = user

  const userRegister = useSelector(state => state.userRegister)
  const { loading, error, msssuccess } = userRegister

  const formik = useFormik({
    initialValues: {
      //lngIdCliente: 0,
      txtNombre: '',
      txtApellidos: '',
      txtTelefono: '',
      txtEmail: '',
      txtContrasena: '',
      txtEdad: '',
      intIdCtLada: '',
      intIdPais: '',
      txtCiudad: '',
      txtAñoNacimiento: '',
      txtMesNacimiento: '',
      txtDiaNacimiento: '',
      bolMailing: '',
      txtPassword: '',
      txtComentarios: '',
    },
    validationSchema: yup.object({
      txtEmail: yup
        .string()
        .email(t('SignUp.fomatEmail'))
        .required(t('SignUp.requiredAlert')),
      txtNombre: yup.string().required(t('SignUp.requiredAlert')),
      txtApellidos: yup.string().required(t('SignUp.requiredAlert')),
      txtTelefono: yup.string().required(t('SignUp.requiredAlert')),
      txtContrasena: yup.string().required(t('SignUp.requiredAlert')),
      intIdCtLada: yup.string().required(t('SignUp.requiredAlert')),
      txtEdad: yup.string().required(t('SignUp.requiredAlert')),
      txtComentarios: yup.string().notRequired(t('SignUp.requiredAlert')),
      txtAñoNacimiento: yup.string().required(t('SignUp.requiredAlert')),
      txtMesNacimiento: yup.string().required(t('SignUp.requiredAlert')),
      // txtDiaNacimiento: yup.string(),
      txtDiaNacimiento: yup.string().required(t('SignUp.requiredAlert')),
      intIdPais: yup.string().required('Campos requerido'),
      txtCiudad: yup.string().required(t('SignUp.requiredAlert')),
      txtPassword: yup.string().required(t('SignUp.requiredAlert')),
    }),

    onSubmit: values => {
      dispatch(registerUser(values, props))
    },
  })

  /*:::::::::::::::::::::::::::::::::::::::::::::: Obtener Lada */
  const [listLada, setListLada] = useState([])

  const getListLada = () => {
    requests
      .get(GETLISTLADA)
      .then(response => {
        setListLada(response)
      })
      .catch(err => {})
  }

  /*:::::::::::::::::::::::::::::::::::::::::::::: Obtener Pais */
  const [listaPais, setListaPais] = useState([])
  const getListaPais = () => {
    requests
      .get(GET_LIST_PAIS)
      .then(response => {
        setListaPais(response)
      })
      .catch(error => {})
  }

  useEffect(() => {
    getListLada()
    getListaPais()

    if (userInfo) {
      setTimeout(() => {
        onClose()
      }, 1500)
    }
  }, [userInfo])

  const [age, setAge] = useState(null)
  const calculateAge = (year, month, day) => {
    if (year && month && day) {
      const selectedDate = parse(
        `${year}-${month}-${day}`,
        'yyyy-MM-dd',
        new Date()
      )
      const currentDate = new Date()
      if (isBefore(selectedDate, currentDate)) {
        const calculatedAge = differenceInYears(currentDate, selectedDate)
        setAge(calculatedAge.toString())
        formik.setFieldValue('txtEdad', calculatedAge.toString())
        formik.setFieldError('txtEdad', '') // Limpiar el error cuando se realiza una selección
      } else {
        setAge('')
        formik.setFieldValue('txtEdad', '')
        formik.setFieldError('txtEdad', 'Fecha de nacimiento no válida') // Puedes manejar este caso según tus necesidades
      }
    }
  }
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          background:
            window.screen.width <= 600
              ? 'white'
              : 'linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)',
        },
      }}
      className={'container-modal, backdropfilter'}>
      <div className="Container-Modal-Signin">
        <div className="Header-Modal-Login flex">
          <p className="co-white fs18 font-weight6 upperCase">
            {t('SignUp.Title')}
          </p>
        </div>
        <div className="Body-Modal-Login flex-column text-center">
          <p className="font-weight7 co-black  my1 titleSigIn">
            {t('SignUp.Body-title')}
          </p>
          <span className="fs18">{t('SignUp.Body-Inf')}</span>
          {msssuccess}
          <div className="mt2">
            <Box component="form" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6} lg={6}>
                  <RedditTextField
                    variant="filled"
                    required
                    fullWidth
                    focused
                    id="txtNombre"
                    label={t('SignUp.FName')}
                    name="txtNombre"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.txtNombre}
                    error={
                      formik.touched.txtNombre &&
                      Boolean(formik.errors.txtNombre)
                    }
                    helperText={
                      formik.touched.txtNombre && formik.errors.txtNombre
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <RedditTextField
                    variant="filled"
                    required
                    fullWidth
                    focused
                    id="txtApellidos"
                    label={t('SignUp.FApellido')}
                    name="txtApellidos"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.txtApellidos}
                    error={
                      formik.touched.txtApellidos &&
                      Boolean(formik.errors.txtApellidos)
                    }
                    helperText={
                      formik.touched.txtApellidos && formik.errors.txtApellidos
                    }
                  />
                </Grid>
                <Grid item xs={5} lg={4}>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: '#F0F0F0',
                        border: 'none',
                        padding: '14px 0px 8px 0px',
                        '@media (max-width: 600px)': {
                          fontSize: '11px',
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#544331',
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#545454',
                        fontWeight: 600,
                      }),
                    }}
                    placeholder={t('SignUp.FPhone')}
                    // placeholder={pais.txtPais}
                    required
                    options={listLada.map(lada => ({
                      label: lada.txtNomLada + lada.txtNumLada,
                      value: lada.intIdCtLada,
                    }))}
                    onChange={selectedOption => {
                      formik.setFieldValue('intIdCtLada', selectedOption.value)
                      formik.setFieldError('intIdCtLada', '') // Limpiar el error cuando se realiza una selección
                    }}
                    onBlur={formik.handleBlur('intIdCtLada')}
                    // options={listLada.map((option) => ({
                    //   label: option.txtNomLada + option.txtNumLada,
                    //   value: option.intIdCtLada,
                    // }))}
                    // onChange={(selectedOption) =>
                    //   handleTextUserDataUpdate({
                    //     target: {
                    //       name: "intIdCtLada",
                    //       value: selectedOption.value,
                    //     },
                    //   })
                    // }
                  />
                  {formik.touched.intIdCtLada && formik.errors.intIdCtLada && (
                    <span className="alertSingUp">
                      {formik.errors.intIdCtLada}
                    </span>
                  )}
                  {/* <RedditTextField
										variant='filled'
										fullWidth
										required
										focused
										id='intIdCtLada'
										name='intIdCtLada'
										select
										label={t('SignUp.FPhone')}
										value={formik.values.intIdCtLada}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={
											formik.touched.intIdCtLada &&
											Boolean(formik.errors.intIdCtLada)
										}
										helperText={
											formik.touched.intIdCtLada && formik.errors.intIdCtLada
										}
									>
										{listLada.map((option) => (
											<MenuItem
												key={option.intIdCtLada}
												value={option.intIdCtLada}
											>
												{option.txtNomLada}
												{option.txtNumLada}
											</MenuItem>
										))}
									</RedditTextField> */}
                </Grid>
                <Grid item xs={7} lg={8}>
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    focused
                    required
                    id="txtTelefono"
                    label={t('SignUp.FPhoneHere')}
                    name="txtTelefono"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.txtTelefono}
                    error={
                      formik.touched.txtTelefono &&
                      Boolean(formik.errors.txtTelefono)
                    }
                    helperText={
                      formik.touched.txtTelefono && formik.errors.txtTelefono
                    }
                  />
                </Grid>
                {/* ------------------------------------ Datos Paises ----------------------------------*/}

                <Grid item xs={6} lg={6}>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: '#F0F0F0',
                        border: 'none',
                        padding: '14px 0px 8px 0px',
                        '@media (max-width: 600px)': {
                          fontSize: '11px',
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#544331',
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#545454',
                        fontWeight: 600,
                      }),
                    }}
                    placeholder={t('6Paso-Pay.Country')}
                    required
                    options={listaPais.map(country => ({
                      label: country.txtPais,
                      value: country.intIdPais,
                    }))}
                    // onChange={(selectedOption) => formik.handleChange({ target: { name: 'intIdPais' } }, selectedOption.value)}
                    // onChange={(selectedOption) => formik.setFieldValue('intIdPais', selectedOption.value)}
                    onChange={selectedOption => {
                      formik.setFieldValue('intIdPais', selectedOption.value)
                      formik.setFieldError('intIdPais', '') // Limpiar el error cuando se realiza una selección
                    }}
                    // value={formik.values.intIdPais}
                    onBlur={formik.handleBlur('intIdPais')}
                  />
                  {formik.touched.intIdPais && formik.errors.intIdPais && (
                    <span className="alertSingUp">
                      {formik.errors.intIdPais}
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} lg={6}>
                  <RedditTextField
                    variant="filled"
                    required
                    fullWidth
                    focused
                    id="txtCiudad"
                    label={t('SignUp.City')}
                    name="txtCiudad"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.txtCiudad}
                    error={
                      formik.touched.txtCiudad &&
                      Boolean(formik.errors.txtCiudad)
                    }
                    helperText={
                      formik.touched.txtCiudad && formik.errors.txtCiudad
                    }
                  />
                </Grid>
                {/* ---------------------------------------------------fin */}
                {/* <Grid item xs={6} lg={3}>
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    focused
                    required
                    // disabled
                    id="txtEdad"
                    name="txtEdad"
                    label={t("SignUp.FAge")}
                    // value={age}
                    value={formik.values.txtEdad}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.txtEdad && Boolean(formik.errors.txtEdad)
                    }
                    helperText={formik.touched.txtEdad && formik.errors.txtEdad}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid> */}
                <Grid item xs={4} lg={4}>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: '#F0F0F0',
                        border: 'none',
                        padding: '14px 0px 8px 0px',
                        '@media (max-width: 600px)': {
                          fontSize: '11px',
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#544331',
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#545454',
                        fontWeight: 600,
                      }),
                    }}
                    placeholder={t('SignUp.Year')}
                    required
                    options={Year.map(option => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    // onChange={(selectedOption) => formik.setFieldValue('txtAñoNacimiento', selectedOption?.value || '')}
                    onChange={selectedOption => {
                      formik.setFieldValue(
                        'txtAñoNacimiento',
                        selectedOption.value
                      )
                      formik.setFieldError('txtAñoNacimiento', '') // Limpiar el error cuando se realiza una selección
                      calculateAge(
                        selectedOption.value,
                        formik.values.txtMesNacimiento,
                        formik.values.txtDiaNacimiento
                      )
                    }}
                    // value={formik.values.txtAñoNacimiento}
                    onBlur={formik.handleBlur('txtAñoNacimiento')}
                  />
                  {formik.touched.txtAñoNacimiento &&
                    formik.errors.txtAñoNacimiento && (
                      <span className="alertSingUp">
                        {formik.errors.txtAñoNacimiento}
                      </span>
                    )}
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: '#F0F0F0',
                        border: 'none',
                        padding: '14px 0px 8px 0px',
                        '@media (max-width: 600px)': {
                          fontSize: '11px',
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#544331',
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#545454',
                        fontWeight: 600,
                      }),
                    }}
                    placeholder={t('SignUp.Month')}
                    required
                    options={
                      i18n.language === 'En'
                        ? MonthEn.map(option => ({
                            label: option.label,
                            value: option.value,
                          }))
                        : MonthEs.map(option => ({
                            label: option.label,
                            value: option.value,
                          }))
                    }
                    onChange={selectedOption => {
                      formik.setFieldValue(
                        'txtMesNacimiento',
                        selectedOption.value
                      )
                      formik.setFieldError('txtMesNacimiento', '')
                      calculateAge(
                        formik.values.txtAñoNacimiento,
                        selectedOption.value,
                        formik.values.txtDiaNacimiento
                      )
                    }}
                    onBlur={formik.handleBlur('txtMesNacimiento')}
                  />
                  {formik.touched.txtMesNacimiento &&
                    formik.errors.txtMesNacimiento && (
                      <span className="alertSingUp">
                        {formik.errors.txtMesNacimiento}
                      </span>
                    )}
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: '#F0F0F0',
                        border: 'none',
                        padding: '14px 0px 8px 0px',
                        '@media (max-width: 600px)': {
                          fontSize: '11px',
                        },
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#544331',
                        fontWeight: 500,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#545454',
                        fontWeight: 600,
                      }),
                    }}
                    placeholder={t('SignUp.Day')}
                    required
                    options={Day.map(option => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    onChange={selectedOption => {
                      formik.setFieldValue(
                        'txtDiaNacimiento',
                        selectedOption.value
                      )
                      formik.setFieldError('txtDiaNacimiento', '')
                      calculateAge(
                        formik.values.txtAñoNacimiento,
                        formik.values.txtMesNacimiento,
                        selectedOption.value
                      )
                    }}
                    onBlur={formik.handleBlur('txtDiaNacimiento')}
                  />
                  {formik.touched.txtDiaNacimiento &&
                    formik.errors.txtDiaNacimiento && (
                      <span className="alertSingUp">
                        {formik.errors.txtDiaNacimiento}
                      </span>
                    )}
                </Grid>
                <Grid item xs={12} lg={12}>
                  {/* <RedditTextField
                    variant="filled"
                    fullWidth
                    focused
                    required
                    // disabled
                    id="txtEdad"
                    name="txtEdad"
                    label={t("SignUp.FAge")}
                    // value={age}
                    value={formik.values.txtEdad}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.txtEdad && Boolean(formik.errors.txtEdad)
                    }
                    helperText={formik.touched.txtEdad && formik.errors.txtEdad}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    focused
                    // required
                    multiline
                    // disabled
                    id="txtComentarios"
                    name="txtComentarios"
                    label={t('SignUp.comments')}
                    // value={age}
                    value={formik.values.txtComentarios}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.txtComentarios &&
                      Boolean(formik.errors.txtComentarios)
                    }
                    helperText={
                      formik.touched.txtComentarios &&
                      formik.errors.txtComentarios
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    required
                    id="txtEmail"
                    label={t('SignUp.FEmail')}
                    placeholder="example@email.com"
                    name="txtEmail"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.txtEmail}
                    error={
                      formik.touched.txtEmail && Boolean(formik.errors.txtEmail)
                    }
                    helperText={
                      formik.touched.txtEmail && formik.errors.txtEmail
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    required
                    id="txtContrasena"
                    label={t('SignUp.FPassword')}
                    type={values.showPassword ? 'text' : 'password'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.txtContrasena}
                    error={
                      formik.touched.txtContrasena &&
                      Boolean(formik.errors.txtContrasena)
                    }
                    helperText={
                      formik.touched.txtContrasena &&
                      formik.errors.txtContrasena
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            edge="end"
                            onClick={handleClickShowPassword}>
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    required
                    id="txtPassword"
                    label={t('SignUp.FPasswordConfirm')}
                    type={
                      valuesPassword.showPasswordConfirm ? 'text' : 'password'
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.txtPassword}
                    error={
                      formik.touched.txtPassword &&
                      Boolean(formik.errors.txtPassword)
                    }
                    helperText={
                      formik.touched.txtPassword && formik.errors.txtPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            edge="end"
                            onClick={handleClickShowPasswordConfirm}>
                            {valuesPassword.showPasswordConfirm ? (
                              <VisibilityOff />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className="Sign-Checkbox">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="bolMailing"
                            onChange={formik.handleChange}
                            value={formik.values.bolMailing}
                            onBlur={formik.handleBlur}
                            color="success"
                          />
                        }
                        label={t('SignUp.CheckMark')}
                      />
                    </FormGroup>
                  </div>
                </Grid>
              </Grid>
              <div className="flex-between mt2 mb2 gap1">
                <Button
                  className="btn-login-acept"
                  variant="contained"
                  type="submit">
                  {t('SignUp.Btn-SignUp')}
                </Button>
                <Button
                  onClick={onClose}
                  className="btn-login-cancel"
                  variant="outlined">
                  {t('SignUp.Btn-Cancel')}
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default SignIn
