import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	product: '',
	loading: false,
}

export const productDetailSlice = createSlice({
	name: 'productDetails',
	initialState,
	reducers: {
		//product Detail
		product_Detail_Init: (state) => {
			return { ...state, loading: true }
		},
		product_Detail_Success: (state, action) => {
			return { ...state, loading: false, product: action.payload }
		},
		product_Detail_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
	},
})

export const {
	//product Detail
	product_Detail_Init,
	product_Detail_Success,
	product_Detail_Fail,
} = productDetailSlice.actions
export default productDetailSlice.reducer
