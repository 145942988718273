import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	success: false,
	userInfo: '',
	error: '',
}
export const userUpdateProfileSlice = createSlice({
	name: 'userUpdateProfile',
	initialState,
	reducers: {
		user_Update_Profile_Init: (state) => {
			return { ...state, loading: true }
		},
		user_Update_Profile_Succes: (state, action) => {
			return {
				...state,
				loading: false,
				success: true,
				userInfo: action.payload,
			}
		},
		user_Update_Profile_Failt: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		user_Update_Profile_Reset: (state) => {
			return { ...state }
		},
	},
})

export const {
	user_Update_Profile_Init,
	user_Update_Profile_Succes,
	user_Update_Profile_Failt,
	user_Update_Profile_Reset,
} = userUpdateProfileSlice.actions
export default userUpdateProfileSlice.reducer
