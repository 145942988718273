import axios from 'axios'
import Dictionary from './Dictionary'

const getBaseUrl = Dictionary.baseUrls.getBaseUrl

axios.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		return Promise.reject(error)
	}
)
const responseBody = (response) => response?.data

const requests = {
	get: (url, id = '') =>
		axios
			.get(!id ? url : `${url}/${id}`, {
				baseURL: getBaseUrl,
			})
			.then(responseBody),
	getParams: (url, param) =>
		axios
			.get(url, param, {
				baseURL: getBaseUrl,
			})
			.then(responseBody),
	getBody: (url, body) =>
		axios
			.post(url, body, {
				baseURL: getBaseUrl,
			})
			.then(responseBody),
	post: (url, body, id = '') =>
		axios
			.post(!id ? url : `${url}/${id}`, body, {
				baseURL: getBaseUrl,
			})
			.then(responseBody),
	put: (url, body, id = '') =>
		axios
			.put(!id ? url : `${url}/${id}`, body, {
				baseURL: getBaseUrl,
			})
			.then(responseBody),
	delete: (url, id = '') =>
		axios
			.delete(!id ? url : `${url}/${id}`, {
				baseURL: getBaseUrl,
			})
			.then(responseBody),
	Url: getBaseUrl,
}

export default requests
