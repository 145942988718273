import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    loading: false,
    event: '',
    user: ''
}

export const eventsDetailsSlice = createSlice({
    name: 'eventsDetails',
    initialState,
    reducers: {        
        eventDetails_Init: (state) => {
            return { ...state, loading: true }
        },
        eventDetails_Success: (state,action) => {
            return { ...state, loading: false, event: action.payload }            

        },
        eventDetails_Fail: (state,action) => {
            return { ...state, loading: false, event: action.payload }            

        },
        eventDetails_Reset: (state,action) => {
			return { ...state, loading: false, user: action.payload }
        }
    }
})

export const { 
    eventDetails_Init,
    eventDetails_Success,
    eventDetails_Fail,
    eventDetails_Reset 
} = eventsDetailsSlice.actions;
export default eventsDetailsSlice.reducer;