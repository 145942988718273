import { createSlice } from '@reduxjs/toolkit'

export const alertSlice = createSlice({
	name: 'alert',
	initialState: {
		openAlert: false,
	},

	reducers: {
		AlertSucces: (state, action) => {
			return { ...state, openAlert: action.payload }
		},
		AlertReset: (state, action) => {
			return { ...state, openAlert: action.payload }
		},
	},
})

export const { AlertSucces, AlertReset } = alertSlice.actions

export default alertSlice.reducer
