import React, { useState } from "react"
import "../../Styles/Login/Login.css"
import Dialog from "@mui/material/Dialog"
import Slide from "@mui/material/Slide"
import Button from "@mui/material/Button"
// import logo from '../../img/logo-login.webp'
import logo from "../../img/Rosanegrabeach_negro.webp"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import requests from "../../Components/AxiosCalls/AxiosAuth"
import { RECOVERY_PASSWORD } from "../../Constants/routesConstants"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { HOME } from "../../Constants/routesConstants"
import { LazyLoadImage } from "react-lazy-load-image-component"

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& label.Mui-focused": {
    color: "#545454",
    fontWeight: "600",
  },
  "& label": {
    color: "#545454",
    fontWeight: "600",
  },
  "& label .MuiInputLabel-asterisk": {
    color: "red",
    // fontSize: window.screen.width <= 600 ? 18 : 20,
    // order: -1,
  },
  "& .MuiFilledInput-root": {
    border: 0,
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#0000000f",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:hover": {
      // backgroundColor: 'transparent',
    },
    "&.Mui-focused": {
      backgroundColor: "#0000000f",
      boxShadow: 0,
      borderColor: "#0000000f",
    },
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const EmailResetPassword = (props) => {
  let navigate = useNavigate()

  const [t, i18n] = useTranslation("global")

  const methods = useForm()
  const { handleSubmit } = methods

  const onClose = () => {
    props.onClose()
  }

  const [validateEmail, setValidateEmail] = useState({
    email: "",
  })

  const handleValidateEmail = (event) => {
    setValidateEmail({
      ...validateEmail,
      [event.target.name]: event.target.value,
    })
  }

  /*navegacion */
  const navigateHome = () => {
    navigate(HOME)
  }

  const valueEmail = () => {
    props.props.setOpenLoadingScreen()
    requests
      .post(RECOVERY_PASSWORD, validateEmail)
      .then((response) => {
        props.props.setTypeSnackBar("success")
        props.props.setMessageSnackBar(response.messageES)
        props.props.setOpenSnackBar(true)
        props.props.setCloseLoadingScreen()
        onClose()
        navigateHome()
      })
      .catch((error) => {
        props.props.setTypeSnackBar("warning")
        props.props.setMessageSnackBar(error.data.messageES)
        props.props.setOpenSnackBar(true)
        props.props.setCloseLoadingScreen()
      })
  }

  return (
    <>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose ? props.onClose : null}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background:
              window.screen.width <= 600
                ? "white"
                : "linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)",
          },
        }}
        className={"container-modal, backdropfilter"}
      >
        <div className="Container-Modal-Login">
          <div className="Header-Modal-Login flex">
            <p className="co-white fs18 font-weight6 upperCase">
              {t("Login.TitleRecoveryPassword")}
            </p>
          </div>
          <div className="Body-Modal-Login flex-column text-center">
            <LazyLoadImage src={logo} className="logo-login" alt="LogoTaboo" />
            <p className="font-weight7 co-black my1 titleLogin">
              {t("Password.Title")}
            </p>
            <span className="fs18">{t("Password.Subtitle")}</span>
            <div className="mt4 w80">
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(valueEmail)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <RedditTextField
                      value={validateEmail.email}
                      variant="filled"
                      required
                      fullWidth
                      label={t("Password.EmailReset")}
                      id="email"
                      name="email"
                      onChange={handleValidateEmail}
                    />
                  </Grid>
                </Grid>
                <div className="flex-between mt4 gap1">
                  <Button
                    className="btn-login-acept"
                    variant="contained"
                    type="submit"
                  >
                    {t("Password.Send")}
                  </Button>
                  <Button
                    onClick={onClose}
                    className="btn-login-cancel"
                    variant="outlined"
                  >
                    {t("Password.Cancel")}
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default EmailResetPassword
