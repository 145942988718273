import React, { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import { useTranslation } from 'react-i18next'

const AlertInfo = (props) => {

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		props.setOpen()
	}

	return (
		<>
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={props.open}
					autoHideDuration={4000}
					onClose={handleClose}
					style={{zIndex:3000}}
				>
					<Alert
						onClose={handleClose}
						// variant={props.variant}
						severity={props.severity}
						sx={{ width: '100%' }}
					>
						{props.mensaje}
					</Alert>
				</Snackbar>
			</Stack>
		</>
	)
}

export default AlertInfo
