export const HOME = "/"
export const SHOP = "/shop"
export const FEATURED_PRODUCTS = "/featured"
export const RECOMMENDED_PRODUCTS = "/recommended"
export const ACCOUNT = "/account"
export const ACCOUNT_EDIT = "/account/edit"
export const ADMIN_DASHBOARD = "/admin/dashboard"
export const ADMIN_PRODUCTS = "/admin/products"
export const ADMIN_USERS = "/admin/users"
export const ADD_PRODUCT = "/admin/add"
export const EDIT_PRODUCT = "/admin/edit"
export const SEARCH = "/search/:searchKey"
export const SIGNOUT = "/signout"
export const FORGOT_PASSWORD = "/forgot_password"
export const CHECKOUT_STEP_1 = "/checkout/step1"
export const CHECKOUT_STEP_2 = "/checkout/step2"
export const CHECKOUT_STEP_3 = "/checkout/step3"
export const VIEW_PRODUCT = "/product/:id"

//validas
export const SIGNIN = "/Clients/Login"
export const SIGNUP = "/Clients/Create"
export const USERID = "/Clients/GetById/"
export const USERUPDATE = "/Clients/Update"

//prodcuto
export const PRODUCT_BY_ID = "/Shops/GetById/"
export const PRODUCT_BY_ID_AND_QTY = "/Shops/GetByIdAndQty"
export const PRODUCT_LIST = "/Shops/GetAll/"

//Carrito

//paquetes
export const PRODUCT_PACKALL_BY_ZONA = "/ProductPacks/GetAll"
export const PRODUCT_PACK_BY_IDZONA = "/ProductPacks/GetById"

//order
export const ORDER_CREATE = "/Carts/CartOrder/"
// export const ORDER_CREATE_DETAIL = '/Carts/CartOrderDetail/'
export const ORDER_CREATE_DETAIL = "/Carts/CartOrderDetail/"
// export const ORDER_DETAIL_BY_ID = '/Carts/PayOrderById/'
export const ORDER_DETAIL_BY_ID = "/Carts/PayOrderById/"
export const ORDER_COUPON = "/Carts/ApplyCode/"

export const ORDER_ITEM_PRODUCT = "/Carts/CartItemProduct/"
export const ORDER_BY_CART_PRODUCT = "Carts/OrderByCartProductID/"

// order packges
export const ORDER_CREATE_PACKS = "/Carts/CartPakages/"
export const ORDER_ITEM_PACKS = "/Carts/CartItemPackages/"
export const ORDER_BY_CART_PACKS = "Carts/OrderByCartPackageID/"

//Events
export const EVENT_BY_ID = "/EventCalendars/GetById/"
export const EVENT_LIST = "/Events/GetList"
export const GET_All_EVENTS = "EventCalendars/GetAll"
export const GET_All_EVENTS_INFO = "EventCalendars/GetAllEvent"
export const GET_All_RESERVATION = "EventCalendars/GetAllDayResevartion"
//Orders client
export const ORDER_LIST_BY_ID_CLIENT = "/Purchase/GetPurchaseOrderList/"
export const ORDER_LIST_BY_ID_CLIENT_STATUS =
  "/Purchase/GetPurchaseOrderListStatus/"
export const ORDER_LIST_BY_ID = "/Purchase/GetPurchaseOrderById/"

//Lista De Descuentos
export const LIST_DISCOUNT_BY_ID = "/DescuentoBeachClub/GetListByIdClient/"

//Qr client
export const QR_LIST_BY_ID_CLIENT = "/Purchase/GetQrOrderList/"
export const QR_BY_ID_ORDER = "/Purchase/GetQrOrderById/"
export const QR_BY_ID_ORDER_PROFILE = "/Purchase/GetQrOrderByIdProfile/"

//Countries
export const SEARCH_COUNTRY = "/Countries/GetListParam?Search="
export const GET_BY_ID_COUNTRY = "/Countries/GetById/"

//Matrizss
export const GET_LIST_DIAS_SEMANA = "/DiasSemana/GetList"
export const GET_LIST_ANTELACION = "/Antelacion/GetList"
export const GET_LIST_UBICACION = "Ubicacion/GetList"
export const GET_LIST_PAX = "NumPax/GetList"
export const GET_MATRIX_RESULT = "/MatrizResults/MatrizResults"

// Matriz de conparacion
export const GET_LIST_MATIZ = "Matriz/GetList"

//Orden de compras
export const PURCHASEORES_GET_LIST = "/AdminCarts/GetPurchaseOrderList"
export const PURCHASEORES_BY_ID = "/AdminCarts/GetPurchaseOrder/"

//MAIL SIMULATION
export const MAIL_SIMULATION = "/Mails/GetDataMailByOrden"

//Pay
export const PAY_CHECKOUT = "/Pay"

//LIST LADAS
export const GETLISTLADA = "/CtLada/GetList"
export const GET_LIST_LADA_BY_ID = "/CtLada/GetById/"

//Paises
export const GET_LIST_PAIS = "CtPais/GetList"
export const GET_LIST_PAIS_BY_ID = "CtPais/GetById/"
export const CREATE_PAIS = "CtPais/Create"
export const UPDATE_PAIS = "CtPais/Update"
export const DELETE_PAIS = "CtPais/Delete/"

// CHANGE PASSWORD
export const CHANGE_PASSWORD = "/Clients/ResetPassword"

//RECOVERY PASSWORD
export const RECOVERY_PASSWORD = "/Clients/RecuperarContrasena"
export const UPDATE_PASSWORD = "/Clients/UpdatePassword"

//LIST HORARIOS
export const GETLISTHORARIOS = "/CtHorariosBC/GetList"
export const GET_HORARIOS_BY_ID = "/CtHorariosBC/GetById"

//Bills FACTURACION
export const BILLS_CREATE = "/Bills/Create"
export const BILLS_UPDTE = "/Bills/Update/"
export const BILLS_GET_LIST = "/Bills/GetListView"
export const BILLS_BY_ID = "/Bills/GetById/"
export const BILLS_BY_ID_ORDER = "/Bills/GetByIdOrder"
export const BILLS_VIEW_BY_ID = "/Bills/GetByIdView/"
export const BILL_UPLOAD_FILES = "/Bills/UploadFiles"
export const STATUS_BILLS = "/StatusBills/GetList"
export const STATUS_BILLS_BY_ID = "/StatusBills/GetById/"

//CONFIRM MAIL
export const CONFIRMATION_MAIL = "/Clients/ConfirmEmail"

//FormaPago
export const FORMA_PAGO = "/FormaPago/GetList"

// RegimenFiscal
export const REGIMEN_FISCAL_FISICA = "/RegimenFiscal/GetListView"
export const REGIMEN_FISCAL_MORAL = "/RegimenFiscal/GetListViewMoral"

//CFDI

export const CFDI_FISICA = "/UsoCfdi/GetListView"
export const CFDI_MORAL = "/UsoCfdi/GetListViewMoral"

//TERMINOS Y CONDCIONES
export const TERMINOS_GET_BY_ID = "/Terminos/GetById"
