import { configureStore } from '@reduxjs/toolkit'

//CartOrderSlice
import cartOrderReducer from '../Slices/CartOrderSlice/cartOrderSlice'
import getMatrixDiscountReducer from '../Slices/CartOrderSlice/getMatrixDiscountSlice'
import getOrderItemsReducer from '../Slices/CartOrderSlice/getOrderItemSlice'
import orderByCartPacksIdReducer from '../Slices/CartOrderSlice/orderByCartPacksIdSlice'
import cartOrderByCartProductIdReducer from '../Slices/CartOrderSlice/orderByCartProductIdSlice'
import cartOrderPacksReducer from '../Slices/CartOrderSlice/orderPacksSlice'
import applyCouponReducer from '../Slices/CartOrderSlice/applyCouponSlice'

//Cart Packs Slice
import cartPacksReducer from '../Slices/CartPacksSlice/cartPacksSlice'
//import cartPacksRemoveReducer from '../Slices/CartPacksSlice/cartPacksRemoveSlice'

//Cart Slice
import cartGuestPriceReducer from '../Slices/CartSlice/cartGuestPriceSlice'
import cartReducer from '../Slices/CartSlice/cartSlice'

//Event Slice
import eventListReducer from '../Slices/EventSlice/eventListSlice'
import eventsDetailsReducer from '../Slices/EventSlice/eventsDetailsSlice'

//Guest Slice
import guestSliceReducer from '../Slices/GuestSlice/guestSlice'
import updateGuesstNameReducer from '../Slices/GuestSlice/updateGuesstNameSlice'
import updateGuesstEmailReducer from '../Slices/GuestSlice/updateGuesstEmailSlice'
import removeGuestReducer from '../Slices/GuestSlice/removeGuestSlice'

//Product Package Slice
import productDetailsPackReducer from '../Slices/ProductPackageSlice/productDetailsPackSlice'
import productListPackReducer from '../Slices/ProductPackageSlice/productListPackSlice'

//Product Slice
import productCreateReducer from '../Slices/ProductSlice/productCreateSlice'
import productDeleteReducer from '../Slices/ProductSlice/productDeleteSlice'
import productDetailReducer from '../Slices/ProductSlice/productDetailsSlice'
import productListReducer from '../Slices/ProductSlice/productListSlice'
import productReviewCreateReducer from '../Slices/ProductSlice/productReviewCreateSlice'
import productTopRatedReducer from '../Slices/ProductSlice/productTopRatedSlice'
import productUpdateReducer from '../Slices/ProductSlice/productUpdateSlice'

//User Slice
import userDeleteReducer from '../Slices/UserSlice/userDeleteSlice'
import userDetailReducer from '../Slices/UserSlice/userDetailsSlice'
import userListReducer from '../Slices/UserSlice/userListSlice'
import userRegisterReducer from '../Slices/UserSlice/userRegisterSlice'
import userReducer from '../Slices/UserSlice/userSlice.js'
import userUpdateProfileReducer from '../Slices/UserSlice/userUpdateProfileSlice'
import userUpdateReducer from '../Slices/UserSlice/userUpdateSlice'
import createOrderReducer from '../Slices/CartOrderSlice/createOrderSlice'
import createOrderItemReducer from '../Slices/CartOrderSlice/createOrderItemSlice'

//Alert Slice
import alertReducer from '../Slices/Alert/alertSlice'

const cartItemsFromStorage = sessionStorage.getItem('cartItems')
	? JSON.parse(sessionStorage.getItem('cartItems'))
	: []

const cartPackItemsFromStorage = sessionStorage.getItem('cartItemsPacks')
	? JSON.parse(sessionStorage.getItem('cartItemsPacks'))
	: []

const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null

//no se usa aun

const carritoIdFromStorage = localStorage.getItem('carritoId')
	? JSON.parse(localStorage.getItem('carritoId'))
	: {}

const DateReservaFromStorage = sessionStorage.getItem('ReserDate')
	? JSON.parse(sessionStorage.getItem('ReserDate'))
	: {}

const initialState = {
	cart: { cartItems: cartItemsFromStorage },
	cartOrder: carritoIdFromStorage,
	cartPacks: { cartItemsPacks: cartPackItemsFromStorage },
	user: { userInfo: userInfoFromStorage },
	reserDate: DateReservaFromStorage,
}

const reducer = {
	//CartOrderSlice
	cartOrder: cartOrderReducer,
	cart: cartReducer,
	cartPacks: cartPacksReducer,
	applyCoupon: applyCouponReducer,

	//cartPacksRemove: cartPacksRemoveReducer,

	createOrderItem: createOrderItemReducer,
	createOrder: createOrderReducer,
	getMatrixDiscount: getMatrixDiscountReducer,
	getOrderItems: getOrderItemsReducer,
	orderByCartPacksId: orderByCartPacksIdReducer,
	orderByCartProductId: cartOrderByCartProductIdReducer,
	cartOrderPacks: cartOrderPacksReducer,

	//Cart Packs Slice
	// ahí esta arriba

	//Cart Slice
	guestPrice: cartGuestPriceReducer,

	//Event
	eventList: eventListReducer,
	eventsDetails: eventsDetailsReducer,

	//Guest Slice
	guestSlice: guestSliceReducer,
	updateGuesstName: updateGuesstNameReducer,
	updateGuesstEmail: updateGuesstEmailReducer,
	removeGuest: removeGuestReducer,

	//Product Package Slice
	productDetailsPack: productDetailsPackReducer,
	productListPack: productListPackReducer,

	//Product Slice
	productCreate: productCreateReducer,
	productDelete: productDeleteReducer,
	productDetails: productDetailReducer,
	productList: productListReducer,
	productReviewCreate: productReviewCreateReducer,
	productTopRated: productTopRatedReducer,
	productUpdate: productUpdateReducer,

	//User Slice
	userDelete: userDeleteReducer,
	userDetail: userDetailReducer,
	userList: userListReducer,
	userRegister: userRegisterReducer,
	user: userReducer,
	userUpdateProfile: userUpdateProfileReducer,
	userUpdate: userUpdateReducer,

	//ALERT SLICE
	alert: alertReducer,
}

export const store = configureStore({
	reducer,
	preloadedState: initialState,

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			// {
			// 	// Ignore these action types
			// 	ignoredActions: ['your/action/type'],
			// 	// Ignore these field paths in all actions
			// 	ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
			// 	// Ignore these paths in the state
			// 	ignoredPaths: ['items.dates'],
			// },
		}),
})

export default store
