import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	success: false,
	error: '',
}
export const userUpdateSlice = createSlice({
	name: 'userUpdate',
	initialState,
	reducers: {
		user_Update_Init: (state) => {
			return { ...state, loading: true }
		},
		user_Update_Succes: (state, action) => {
			return { ...state, loading: false, success: action.payload }
		},
		user_Update_Failt: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		user_Update_Reset: (state) => {
			return { ...state }
		},
	},
})

export const {
	user_Update_Init,
	user_Update_Succes,
	user_Update_Failt,
	user_Update_Reset,
} = userUpdateSlice.actions
export default userUpdateSlice.reducer
