//import requests from './../Components/AxiosCalls/AxiosCall'

import { CART_CLEAR_ITEMS } from '../Constants/cartConstants'
import { GUESS_CLEAR_ITEMS } from '../Constants/GuessConstants'
import { ORDER_FREMOVE } from '../Constants/CartOrderConstants'
import { CART_PACK_CLEAR_ITEMS } from '../Constants/CartPackConstants'
import {
  SIGNIN,
  SIGNUP,
  USERID,
  USERUPDATE,
  RECOVERY_PASSWORD,
  UPDATE_PASSWORD,
  CONFIRMATION_MAIL,
} from './../Constants/routesConstants'
import Dictionary from '../Components/AxiosCalls/Dictionary'
import requests from '../Components/AxiosCalls/AxiosAuth'
import { Alerts, AlertCollapse } from '../Components/Alerts/Alerts'
import {
  login_Init,
  login_Succes,
  login_Failt,
  logout_Auth,
  login_MssSucces,
} from '../Slices/UserSlice/userSlice'

import {
  userRegister_Init,
  userRegister_Success,
  userRegister_MssSucces,
  userRegister_Fail,
} from '../Slices/UserSlice/userRegisterSlice'
import {
  user_Detail_Init,
  user_Detail_Succes,
  user_Detail_Failt,
  user_Detail_Reset,
} from '../Slices/UserSlice/userDetailsSlice'
import {
  user_Update_Profile_Init,
  user_Update_Profile_Succes,
  user_Update_Profile_Failt,
  user_Update_Profile_Reset,
} from '../Slices/UserSlice/userUpdateProfileSlice'
import {
  user_List_Init,
  user_List_Succes,
  user_List_Failt,
  user_List_Auth,
} from '../Slices/UserSlice/userListSlice'
import {
  user_Delete_Init,
  user_Delete_Succes,
  user_Delete_Failt,
} from '../Slices/UserSlice/userDeleteSlice'
import {
  user_RecoveryPass_Init,
  user_RecoveryPass_Succes,
  user_RecoveryPass_Failt,
} from '../Slices/UserSlice/userRecoveryPassSlice'
import {
  user_UpdatePass_Init,
  user_UpdatePass_Succes,
  user_UpdatePass_Failt,
} from '../Slices/UserSlice/userUpdatePassSlice'
import {
  user_Update_Init,
  user_Update_Succes,
  user_Update_Failt,
  user_Update_Reset,
} from '../Slices/UserSlice/userUpdateSlice'

import {
  userConfirmation_Init,
  userConfirmation_Success,
  userConfirmation_Fail,
  userConfirmation_MssSucces,
} from '../Slices/UserSlice/userConfirmEmailSlice'

import { AlertSucces } from '../Slices/Alert/alertSlice'

import { guest_Clear_Item } from '../Slices/GuestSlice/guestSlice'
import { cart_Clear_Items } from '../Slices/CartSlice/cartSlice'
import { cartOrder_Remove } from '../Slices/CartOrderSlice/cartOrderSlice'
import { cartPacks_Clear_Items } from '../Slices/CartPacksSlice/cartPacksSlice'

import React, { useState } from 'react'

const getBaseUrl = Dictionary.baseUrls.getBaseUrl

export const login = (sendData, props) => async dispatch => {
  //const [_Open, setOpen] = useState(false)
  dispatch(login_Init())
  props.props.setOpenLoadingScreen()
  requests
    .post(SIGNIN, sendData)
    .then(response => {
      localStorage.setItem('userInfo', JSON.stringify(response))
      dispatch(login_Succes(response))
      const data = {
        messageES: 'Inicio de sesión con éxito',
        messageEN: 'Successful login',
      }
      const message = (
        <Alerts severity="success" variant="filled" mensaje={data} />
      )
      dispatch(login_Failt(false))
      dispatch(AlertSucces(true))
      dispatch(login_MssSucces(message))
      props.props.setCloseLoadingScreen()
    })

    .catch(error => {
      const message = (
        <Alerts severity="error" variant="filled" mensaje={error.data} />
      )
      dispatch(login_MssSucces(false))
      dispatch(AlertSucces(true))
      dispatch(login_Failt(message))
      props.props.setCloseLoadingScreen()
    })
}

export const recoveryPassword = (sendData, props) => async dispatch => {
  //const [_Open, setOpen] = useState(false)
  dispatch(user_RecoveryPass_Init())
  props.props.setOpenLoadingScreen()

  requests
    .post(RECOVERY_PASSWORD, sendData)
    .then(response => {
      dispatch(user_RecoveryPass_Succes(response))
      const data = {
        messageES: response.messageES,
        messageEN: response.messageEN,
      }
      const message = (
        <Alerts severity="success" variant="filled" mensaje={data} />
      )
      //dispatch(login_Failt(false))
      dispatch(AlertSucces(true))
      //dispatch(login_MssSucces(message))
      props.props.setCloseLoadingScreen()
    })

    .catch(error => {
      const message = (
        <Alerts severity="error" variant="filled" mensaje={error.data} />
      )
      //dispatch(login_MssSucces(false))
      dispatch(AlertSucces(true))
      dispatch(user_RecoveryPass_Failt(message))
      props.props.setCloseLoadingScreen()
    })
}

export const confirmMail = (sendData, props) => async dispatch => {
  //const [_Open, setOpen] = useState(false).

  dispatch(userConfirmation_Init())
  props.setOpenLoadingScreen()
  requests
    .post(CONFIRMATION_MAIL, sendData)
    .then(response => {
      dispatch(userConfirmation_Success(response))
      const data = {
        messageES: response.messageES,
        messageEN: response.messageEN,
      }
      const message = (
        <Alerts severity="success" variant="filled" mensaje={data} />
      )
      //dispatch(userConfirmation_Failt(false))
      dispatch(AlertSucces(true))
      dispatch(userConfirmation_MssSucces(message))
      props.setCloseLoadingScreen()
    })

    .catch(error => {
      const message = (
        <Alerts severity="error" variant="filled" mensaje={error.data} />
      )
      //dispatch(login_MssSucces(false))
      dispatch(AlertSucces(true))
      dispatch(userConfirmation_Fail(message))
      props.setCloseLoadingScreen()
    })
}

export const updatePassword = (sendData, props) => async dispatch => {
  dispatch(user_UpdatePass_Init())
  props.setOpenLoadingScreen()
  requests
    .post(UPDATE_PASSWORD, sendData)
    .then(response => {
      dispatch(user_UpdatePass_Succes(response))
      const data = {
        messageES: response.messageES,
        messageEN: response.messageEN,
      }
      const message = (
        <Alerts severity="success" variant="filled" mensaje={data} />
      )
      //dispatch(login_Failt(false))
      dispatch(AlertSucces(true))
      //dispatch(login_MssSucces(message))
      props.setCloseLoadingScreen()
    })

    .catch(error => {
      const message = (
        <Alerts severity="error" variant="filled" mensaje={error.data} />
      )
      //dispatch(login_MssSucces(false))
      dispatch(AlertSucces(true))
      dispatch(user_UpdatePass_Failt(message))
      props.setCloseLoadingScreen()
    })
}
export const logout = props => dispatch => {
  props.props.setOpenLoadingScreen()
  localStorage.removeItem('userInfo') // datos del usuario
  sessionStorage.removeItem('cartItems') // mi carrito producto
  sessionStorage.removeItem('cartItemsPack') // mi carrito paquete
  sessionStorage.removeItem('guessItems')
  sessionStorage.removeItem('cartItems')
  localStorage.removeItem('carritoId')
  sessionStorage.removeItem('carritoPackId')
  sessionStorage.removeItem('cartPacksItems')

  dispatch(logout_Auth())
  dispatch(user_Detail_Reset())
  dispatch(guest_Clear_Item())
  dispatch(cart_Clear_Items())
  dispatch(cartOrder_Remove())
  dispatch(cartPacks_Clear_Items())

  setTimeout(() => {
    props.props.setCloseLoadingScreen()
  }, 500)
}
export const logoutTk = () => dispatch => {
  localStorage.removeItem('userInfo') // datos del usuario
  sessionStorage.removeItem('cartItems') // mi carrito producto
  sessionStorage.removeItem('cartItemsPack') // mi carrito paquete
  sessionStorage.removeItem('guessItems')
  sessionStorage.removeItem('cartItems')
  localStorage.removeItem('carritoId')
  sessionStorage.removeItem('carritoPackId')
  sessionStorage.removeItem('cartPacksItems')

  dispatch(logout_Auth())
  dispatch(user_Detail_Reset())
  dispatch(guest_Clear_Item())
  dispatch(cart_Clear_Items())
  dispatch(cartOrder_Remove())
  dispatch(cartPacks_Clear_Items())
}

export const registerUser = (sendData, props) => async dispatch => {
  console.log(props)
  debugger
  dispatch(userRegister_Init())
  props.props.setOpenLoadingScreen()
  if (sendData.txtContrasena === sendData.txtPassword) {
    requests
      .post(SIGNUP, sendData)
      .then(response => {
        dispatch(userRegister_Success(response))

        // dispatch(login_Succes(response))
        // localStorage.setItem('userInfo', JSON.stringify(response))

        const data = {
          messageES:
            'Usuario registrado con éxito revisa tu bandeja de entrada',
          messageEN: 'User successfully registered check your inbox',
        }
        const message = (
          <Alerts severity="success" variant="filled" mensaje={data} />
        )
        dispatch(AlertSucces(true))
        dispatch(userRegister_MssSucces(message))
        props.props.setCloseLoadingScreen()

        setTimeout(() => {
          props.previousStep()
        }, '2500')
      })
      .catch(error => {
        const data = {
          messageES: 'El email ya existe, intente con otro',
          messageEN: 'The email already exists, try another',
        }
        const message = (
          <Alerts severity="error" variant="filled" mensaje={data} />
        )
        dispatch(AlertSucces(true))
        dispatch(userRegister_MssSucces(message))
        props.props.setCloseLoadingScreen()
        // dispatch(
        // 	userRegister_Fail(
        // 		error.response && error.message ? error.message : error.message
        // 	)
        // )
      })
  } else {
    const data = {
      messageES: 'Verficar Contraseña',
      messageEN: 'Verify Password',
    }
    const message = <Alerts severity="error" variant="filled" mensaje={data} />
    dispatch(AlertSucces(true))
    dispatch(userRegister_MssSucces(message))
    props.props.setCloseLoadingScreen()
  }
}

export const getUserDetails = (id, props) => async (dispatch, getState) => {
  props.setOpenLoadingScreen()
  try {
    dispatch(user_Detail_Init())
    return requests.get(USERID + id).then(response => {
      dispatch(user_Detail_Succes(response.data))
      // props.props.setCloseLoadingScreen()
      props.setCloseLoadingScreen()
      return getState().userDetail
    })
  } catch (error) {
    if ((error.status = 401)) {
      dispatch(logout())
    }
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch(user_Detail_Failt(message))
  }
}

export const updateUserProfile = user => async (dispatch, getState) => {
  try {
    // dispatch({
    // 	type: USER_UPDATE_PROFILE_REQUEST,
    // })
    dispatch(user_Update_Profile_Init())

    // const {
    // 	userLogin: { userInfo },
    // } = getState()

    // const config = {
    // 	headers: {
    // 		'Content-Type': 'application/json',
    // 		Authorization: `Bearer ${userInfo.token}`,
    // 	},
    // }

    // const { data } = await axios.put(
    // 	`${process.env.REACT_APP_BACKEND_API}/api/users/profile`,
    // 	user,
    // 	config
    // )

    // // dispatch({
    // // 	type: USER_UPDATE_PROFILE_SUCCESS,
    // // 	payload: data,
    // // })
    // dispatch(user_Update_Profile_Succes(data))

    // ! No existe el reducer Login_Success en el Slices
    // dispatch({
    // 	type: USER_LOGIN_SUCCESS,
    // 	payload: data,
    // })
    //sessionStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    // dispatch({
    // 	type: USER_UPDATE_PROFILE_FAIL,
    // 	payload: message,
    // })
    dispatch(user_Update_Profile_Failt(message))
  }
}

export const listUsers = () => async (dispatch, getState) => {
  try {
    // dispatch({
    // 	type: USER_LIST_REQUEST,
    // })
    dispatch(user_List_Init())

    // const {
    // 	userLogin: { userInfo },
    // } = getState()

    // const config = {
    // 	headers: {
    // 		Authorization: `Bearer ${userInfo.token}`,
    // 	},
    // }

    // const { data } = await axios.get(
    // 	`${process.env.REACT_APP_BACKEND_API}/api/users`,
    // 	config
    // )

    // dispatch({
    // 	type: USER_LIST_SUCCESS,
    // 	payload: data,
    // })
    //dispatch(user_List_Succes(data))
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    // dispatch({
    // 	type: USER_LIST_FAIL,
    // 	payload: message,
    // })
    dispatch(user_List_Failt(message))
  }
}

export const deleteUser = id => async (dispatch, getState) => {
  try {
    // dispatch({
    // 	type: USER_DELETE_REQUEST,
    // })
    dispatch(user_Delete_Init())

    // const {
    // 	userLogin: { userInfo },
    // } = getState()

    // const config = {
    // 	headers: {
    // 		Authorization: `Bearer ${userInfo.token}`,
    // 	},
    // }

    // await axios.delete(
    // 	`${process.env.REACT_APP_BACKEND_API}/api/users/${id}`,
    // 	config
    // )

    // dispatch({ type: USER_DELETE_SUCCESS })
    dispatch(user_Delete_Succes())
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    // dispatch({
    // 	type: USER_DELETE_FAIL,
    // 	payload: message,
    // })
    dispatch(user_Delete_Failt(message))
  }
}

export const updateUser = (user, props) => async (dispatch, getState) => {
  try {
    dispatch(user_Update_Init())
    // dispatch(user_Delete_Failt(user_Update_Init))
    props.setOpenLoadingScreen()
    const {
      user: { userInfo },
    } = getState()

    return requests.put(USERUPDATE, user).then(response => {
      const message = (
        <AlertCollapse
          severity="success"
          variant="filled"
          mensaje={response}
          // open={true}
        />
      )
      dispatch(user_Update_Succes(message))
      dispatch(user_Detail_Succes(response))
      dispatch(user_Detail_Reset())
      dispatch(getUserDetails(userInfo.id))
      dispatch(AlertSucces(true))
      props.setCloseLoadingScreen()
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch(user_Update_Failt(message))
    props.setCloseLoadingScreen()
  }
}
