import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	loading: false,
	discount: '',
	error: '',
}

export const getMatrixDiscountSlice = createSlice({
	name: 'getMatrixDiscount',
	initialState,
	reducers: {		
		getMatrixDiscount_Init: (state) => {
			return { ...state, loading: true }
		},
		getMatrixDiscount_Success: (state, action) => {
			return { ...state, loading: true, discount: action.payload }
		},
		getMatrixDiscount_Fail: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
	},
})

export const {
	getMatrixDiscount_Success,
	getMatrixDiscount_Init,
	getMatrixDiscount_Fail,
} = getMatrixDiscountSlice.actions
export default getMatrixDiscountSlice.reducer
