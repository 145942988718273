import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	product: '',
	loading: false,
	success: false,
	error: '',
}

export const productCreteSlice = createSlice({
	name: 'ProductCreate',
	initialState,
	reducers: {
		//product Create
		product_Create_Init: (state) => {
			return { ...state, loading: true }
		},
		product_Create_Success: (state, action) => {
			return {
				...state,
				loading: false,
				success: true,
				product: action.payload,
			}
		},
		product_Create_Fail: (state, action) => {
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		},
		product_Create_Reset: () => {
			return {}
		},
	},
})

export const {
	//product Create
	product_Create_Init,
	product_Create_Success,
	product_Create_Fail,
	product_Create_Reset,
} = productCreteSlice.actions
export default productCreteSlice.reducer
