import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	cartOrderpacks: '',
}

export const orderByCartPacksIdSlice = createSlice({
	name: 'orderByCartPacksId',
	initialState,
	reducers: {
		orderByCartPacksId_Success: (state, action) => {
			const item = action.payload
			return {
				...state,
				cartOrderpacks: item,
			}
		},
	},
})

export const { orderByCartPacksId_Success } = orderByCartPacksIdSlice.actions
export default orderByCartPacksIdSlice.reducer
