// Cambios
import React, { Suspense, lazy, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Theme from './Styles/Theme'
import '../src/Styles/GlobalStyle.css'
import LoadingSpinnner from './Components/Utils/LoadingSpinnner'
import MySnackBar from './Utils/MySnackBar'
import Header from '../src/Layout/Header'

// import Body from '../src/Layout/Body'
import ResetPassword from './Components/Login/ResetPassword'
import ConfirmEmail from './Components/Login/ConfirmEmail'
import PaymentStepper from './Components/PaymentFlow/PaymentStepper'
import Confirmation3Ds from './Components/PaymentFlow/Confirmation3Ds'
import St7Confirmation from './Components/PaymentFlow/St7Confirmation'
import Profile from './Components/Profile/Profile'

import Footer from '../src/Layout/Footer'
import ScrollToTop from './ScrollToTop'
import actions from './Components/AxiosCalls/AxiosAuth'
import { logoutTk } from './Actions/UserActions'
// const Footer =lazy(()=>import( '../src/Layout/Footer'))
const Body =lazy(()=>import( '../src/Layout/Body'))


const App = (props) => {
	const dispatch = useDispatch()
	const [LSopen, setLSOpen] = useState(false)
	const [SBOpen, setSBOpen] = useState(false)
	const [message, setMessage] = useState('')
	const [Msj, setMsj] = useState('')
	const [type, setType] = useState('')

	const tok = JSON.parse(localStorage.getItem('userInfo'))
	if (tok !== null) {
		actions
			.get('/Clients/Valid/')
			.then((response) => {
				if (response === 0) {
					dispatch(logoutTk())
				}
			})
			.catch((error) => {})
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setSBOpen(false)
	}

	const openLoadingScreen = () => {
		setLSOpen(true)
	}

	const closeLoadingScreen = () => {
		setLSOpen(false)
	}

	return (
		<ThemeProvider theme={Theme}>
			<BrowserRouter>
				<MySnackBar
					Open={SBOpen}
					Type={type}
					Message={message}
					HandleClose={handleClose}
				/>
				{/*  */}
				<LoadingSpinnner open={LSopen} />
					<Header
						setMsjLoadingScreen={setMsj}
						setOpenLoadingScreen={openLoadingScreen}
						setCloseLoadingScreen={closeLoadingScreen}
						setOpenSnackBar={setSBOpen}
						setMessageSnackBar={setMessage}
						setTypeSnackBar={setType}
					/>

				<ScrollToTop />
					
					<Routes>
						<Route
							path='/'
							element={
								<div>
									{/* <LoadingSpinnner open={LSopen} /> */}
									<Suspense fallback={<div></div>}>

										<Body
											setMsjLoadingScreen={setMsj}
											setOpenLoadingScreen={openLoadingScreen}
											setCloseLoadingScreen={closeLoadingScreen}
											setOpenSnackBar={setSBOpen}
											setMessageSnackBar={setMessage}
											setTypeSnackBar={setType}
										/>

									</Suspense>

								</div>
							}
						/>
						<Route
							path='/config'
							element={
								<div>
									{/* <Header /> */}
									{/* <LoadingSpinnner open={LSopen} /> */}

										<ResetPassword
											setMsjLoadingScreen={setMsj}
											setOpenLoadingScreen={openLoadingScreen}
											setCloseLoadingScreen={closeLoadingScreen}
											setOpenSnackBar={setSBOpen}
											setMessageSnackBar={setMessage}
											setTypeSnackBar={setType}
										/>
									
										<Footer />
								</div>
							}
						/>
						<Route
							path='/confirmail'
							element={
								<div>
									{/* <Header /> */}
									{/* <LoadingSpinnner open={LSopen} /> */}
										
										<ConfirmEmail
											setMsjLoadingScreen={setMsj}
											setOpenLoadingScreen={openLoadingScreen}
											setCloseLoadingScreen={closeLoadingScreen}
											setOpenSnackBar={setSBOpen}
											setMessageSnackBar={setMessage}
											setTypeSnackBar={setType}
										/>
										<Footer />
								</div>
							}
						/>

						<Route
							path='/Pay'
							element={
								<div>
										
										<PaymentStepper
											setMsjLoadingScreen={setMsj}
											setOpenLoadingScreen={openLoadingScreen}
											setCloseLoadingScreen={closeLoadingScreen}
											setOpenSnackBar={setSBOpen}
											setMessageSnackBar={setMessage}
											setTypeSnackBar={setType}
										/>
										{/* <Header /> */}
										{/* <LoadingSpinnner open={LSopen} /> */}
										<Footer />
								</div>
							}
						/>
						<Route
							path='/ComfirmationPayment/:reference'
							element={
								<div>
										
										{/* <Header /> */}
										<Confirmation3Ds
											setMsjLoadingScreen={setMsj}
											setOpenLoadingScreen={openLoadingScreen}
											setCloseLoadingScreen={closeLoadingScreen}
											setOpenSnackBar={setSBOpen}
											setMessageSnackBar={setMessage}
											setTypeSnackBar={setType}
										/>
									
										<Footer />
								</div>
							}
						/>
						<Route
							path='/Confirmation'
							element={
								<div>
									{/* <Header /> */}
										
										<St7Confirmation
											setMsjLoadingScreen={setMsj}
											setOpenLoadingScreen={openLoadingScreen}
											setCloseLoadingScreen={closeLoadingScreen}
											setOpenSnackBar={setSBOpen}
											setMessageSnackBar={setMessage}
											setTypeSnackBar={setType}
										/>

										<Footer />
								</div>
							}
						/>
						<Route
							path='/Profile'
							element={
								<div>
									{/* <Header /> */}
										<Profile
											setMsjLoadingScreen={setMsj}
											setOpenLoadingScreen={openLoadingScreen}
											setCloseLoadingScreen={closeLoadingScreen}
											setOpenSnackBar={setSBOpen}
											setMessageSnackBar={setMessage}
											setTypeSnackBar={setType}
										/>

										<Footer />
								</div>
							}
						/>
					</Routes>

			</BrowserRouter>
		</ThemeProvider>
	)
}

export default App
