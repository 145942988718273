import React, { useState, useEffect } from "react"
import "../../Styles/Login/Login.css"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import Button from "@mui/material/Button"
// import logo from "../../img/logo-login.webp"
import logo from "../../img/Rosanegrabeach_negro.webp"

import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Link from "@mui/material/Link"
import SignIn from "./SignIn"
import EmailResetPassword from "./EmailResetPassword"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { login } from "../../Actions/UserActions"
import Message from "./../Utils/Message"
import LoadingSpinnner from "./../Utils/LoadingSpinnner"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import { AlertReset } from "../../Slices/Alert/alertSlice"
import { LazyLoadImage } from "react-lazy-load-image-component"

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& label.Mui-focused": {
    color: "#545454",
    fontWeight: "600",
  },
  "& label": {
    color: "#545454",
    fontWeight: "600",
  },
  "& label .MuiInputLabel-asterisk": {
    color: "red",
    // fontSize: window.screen.width <= 600 ? 18 : 20,
    // order: -1,
  },
  "& .MuiFilledInput-root": {
    border: 0,
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#0000000f",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:hover": {},
    "&.Mui-focused": {
      backgroundColor: "#0000000f",
      boxShadow: 0,
      borderColor: "#0000000f",
    },
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const Login = (props) => {
  const [t, i18n] = useTranslation("global")
  const navigate = useNavigate()
  const [openRegis, setOpenRegis] = useState(false)
  const [openRecovery, setOpenRecovery] = useState(false)
  const [values, setValues] = useState({ showPassword: false })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleOpen = () => {
    if (openRegis) {
      setOpenRegis(!openRegis)
    }
  }
  const handleOpenRecovery = () => {
    setOpenRecovery(!openRecovery)
  }

  const handleOpenRegsiter = () => {
    setOpenRegis(!openRegis)
    onClose()
  }

  const onClose = () => {
    props.onClose()
    dispatch(AlertReset(false))
  }

  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)
  const { loading, error, userInfo, msssuccess } = user

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("El formato del correo no es valido")
        .required("El correo nombre es requerido"),
      password: yup.string().required("El campo contraseña es reqiuerido"),
    }),
    onSubmit: (values) => {
      dispatch(login(values, props))
    },
  })

  useEffect(() => {
    if (userInfo) {
      setTimeout(() => {
        onClose()
      }, 1500)
    }
  }, [userInfo])

  return (
    <>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose ? props.onClose : null}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background:
              window.screen.width <= 600
                ? "white"
                : "linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)",
          },
        }}
        className={"container-modal, backdropfilter"}
      >
        <div className="Container-Modal-Login">
          <div className="Header-Modal-Login flex">
            <p className="co-white fs18 font-weight6 upperCase">
              {t("Login.Title")}
            </p>
          </div>
          <div className="Body-Modal-Login flex-column text-center">
            <LazyLoadImage src={logo} className="logo-login" alt="LogoTaboo" />
            <p className="font-weight7 co-black my1 titleLogin">
              {t("Login.Body-title")}
            </p>
            <span className="fs18">{t("Login.Body-Subtitle")}</span>

            {error}
            {msssuccess}
            <div className="mt2">
              <Box component="form" noValidate onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <RedditTextField
                      variant="filled"
                      required
                      fullWidth
                      id="EmailLogin"
                      label={t("Login.Email")}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <RedditTextField
                      variant="filled"
                      required
                      fullWidth
                      name="password"
                      id="password"
                      label={t("Login.Password")}
                      type={values.showPassword ? "text" : "password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowPassword}
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <div className="flex-between mt2 gap1">
                  <Button
                    className="btn-login-acept"
                    variant="contained"
                    type="submit"

                    // onClick={handleOpenProfile}
                  >
                    {t("Login.Btn-Login")}
                  </Button>
                  <Button
                    onClick={onClose}
                    className="btn-login-cancel"
                    variant="outlined"
                  >
                    {t("Login.Btn-Cancel")}
                  </Button>
                </div>
              </Box>

              <div className="flex-column mt3">
                <div className="flex gap1">
                  <p className="my0 text-login-account font-weight5">
                    {t("Login.Notice")}
                  </p>
                  <Link
                    component="button"
                    className="text-signUp-login"
                    color="inherit"
                    underline="none"
                    onClick={() => {
                      handleOpenRegsiter()
                    }}
                  >
                    {t("Login.SingUp")}
                  </Link>
                </div>
                <Link
                  component="button"
                  color="inherit"
                  className="text-signUp-login"
                  underline="none"
                  onClick={() => {
                    handleOpenRecovery()
                    onClose()
                  }}
                >
                  <p className="my0 text-login-forgot">
                    {t("Password.Recovery")}
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <SignIn open={openRegis} onCloseR={handleOpen} props={props.props} />
      <EmailResetPassword
        open={openRecovery}
        onClose={handleOpenRecovery}
        props={props.props}
      />
    </>
  )
}

export default Login
