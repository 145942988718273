import {
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	CART_SAVE_DATE,
	CART_GUESTPRICE_REQUEST,
	PRODUCT_GUESTPRICE_SUCCESS,
	PRODUCT_GUESTPRICE_FAIL,
} from '../Constants/cartConstants'
import {
	PRODUCT_BY_ID,
	PRODUCT_BY_ID_AND_QTY,
	GET_HORARIOS_BY_ID
} from '../Constants/routesConstants'
import actions from '../Components/AxiosCalls/AxiosAuth'

import {
	cart_Add_Item,
	cart_Remove_Item,
	cart_Clear_Items,
	cart_Save_Date,
	cart_Save_Hour,
	cart_Remove_Item_Product
} from '../Slices/CartSlice/cartSlice'

import {
	cartGuestPrice_Init,
	cartGuestPrice_Success,
	cartGuestPrice_Fail,
	cartGuestPrice_Item_Init,
	cartGuestPrice_Item_Success,
	cartGuestPrice_Item_Fail,
	
} from '../Slices/CartSlice/cartGuestPriceSlice'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
const cartItemsFromStorage = sessionStorage.getItem('cartItems')
	? JSON.parse(sessionStorage.getItem('cartItems'))
	: []

export const addToCart =(id,uuid,qty, qtyPeople, Fulldate, subtotal, FullHour) => async (dispatch, getState) => {	
	// actions.getBody(PRODUCT_BY_ID, { id: id, date: Fulldate })
const dataSchedule = {
	hrHorarioBc: "",
}

actions
	.get(GET_HORARIOS_BY_ID, FullHour)
	.then((response) => {
		dataSchedule.hrHorarioBc = response.hrHorarioBc
		actions	
	.getBody(PRODUCT_BY_ID, { id: id, date: Fulldate })
		.then((response) => {
			const data = {
				productId: response.data.lngIdProductos,
				Nombre: response.data.txtNombreProducto,
				precio: response.data.dblPrecio,
				countInStock: response.data.numStock,
				qty,
				qtyPeople,
				fechCompra: Fulldate.toISOString(),
				descripcion: response.data.txtDescripCorta,
				subtotal,
				numCapacidad: response.data.numCapacidad,
				numExtraPax: response.data.numExtraPax,
				uuid: uuid ===null ? uuidv4():uuid,
				Hora: FullHour,
				Horario: dataSchedule.hrHorarioBc.slice(0,5)
			}
			dispatch(cart_Add_Item(data))
			sessionStorage.setItem(
				'cartItems',
				JSON.stringify(getState().cart.cartItems)
			)
		})
		.catch((error) => {})
	})
	.catch((error) => {
		console.info('Error: ' + error)
	})

}
export const removeCartIdProduct = (id) => (dispatch, getState) => {
			
	dispatch(cart_Remove_Item_Product(id))

	sessionStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
	
}
export const removeFromCart = (uuid,props) => (dispatch, getState) => {

	dispatch(cart_Remove_Item(uuid))

	sessionStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
	
}
export const cleanCart = () => (dispatch) => {
	dispatch(cart_Clear_Items())
	sessionStorage.removeItem('cartItems')
}

export const saveDate = (data) => (dispatch) => {
	dispatch(cart_Save_Date(data))

	sessionStorage.setItem('ReserDate', JSON.stringify(data))
}

export const saveHour = (data) => (dispatch) => {
	dispatch(cart_Save_Hour(data))

	sessionStorage.setItem('ReserHour', JSON.stringify(data))
}

// place count people
export const guestPriceAction =	(id, qytPeople, date, props) => async (dispatch, getState) => {
	props.props.setOpenLoadingScreen()
	dispatch(cartGuestPrice_Init())
	return actions
		.post(PRODUCT_BY_ID_AND_QTY, {
			lngIdProductos: id,
			qty: qytPeople,
			date: date,
		})
		.then((response) => {
			
			dispatch(cartGuestPrice_Success(response.data))
			const stado = getState()
			props.props.setCloseLoadingScreen()
			return stado.guestPrice
		})
		.catch((error) => {
			dispatch(cartGuestPrice_Fail(error))
		})
} 

export const guestCartPriceAction =	(id, qytPeople, date,props) => async (dispatch, getState) => {

	props.props.setOpenLoadingScreen()

	dispatch(cartGuestPrice_Init())
	return actions
		.post(PRODUCT_BY_ID_AND_QTY, {
			lngIdProductos: id,
			qty: qytPeople,
			date:  date,
			
		})
		.then((response) => {				
			dispatch(cartGuestPrice_Success(response.data))			
			const stado = getState()
			props.props.setCloseLoadingScreen()
			return stado.guestPrice
			
		})
	
		.catch((error) => {				
			dispatch(cartGuestPrice_Fail(error))
		})

}