import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	cartItemsPacks: [],
}

export const cartPacksSlice = createSlice({
	name: 'cartPacks',
	initialState,
	reducers: {
		cartPacks_Add_Item: (state, action) => {
			const item = action.payload

			const existItem = state.cartItemsPacks.find(
				(x) => x.productId === item.productId
			)

			if (existItem) {
				const t = existItem.qty + item.qty
				item.qty = t
				return {
					...state,
					cartItemsPacks: state.cartItemsPacks.map((x) =>
						x.productId === existItem.productId ? item : x
					),
				}
			} else {
				return { ...state, cartItemsPacks: [...state.cartItemsPacks, item] }
			}
		},
		cartPacks_Remove_Item: (state, action) => {
			const item = action.payload
			// const existItem = state.cartItemsPacks.find((x) => x.productId === item)

			// const t = existItem.qty - 1
			// existItem.qty = t
			// if (parseInt(t) > 0) {
			// 	return {
			// 		...state,
			// 		cartItemsPacks: state.cartItemsPacks.map((x) =>
			// 			x.productId === item ? {
			// 											...x,qty:x.qty - 1
			// 										} : x
			// 		),
			// 	}
			// } else {
			// 	return {
			// 		...state,
			// 		cartItemsPacks: state.cartItemsPacks.filter(
			// 			(x) => x.productId !== action.payload
			// 		),
			// 	}
			// }
			// if (existItem) {

			return {
				...state,
				cartItemsPacks: state.cartItemsPacks.map((x) =>
					x.productId === item
						? {
								...x,
								qty: x.qty - 1,
						  }
						: x
				),
			}
			// 			}else{
			// 				return {
			// 					...state,
			// 					cartItemsPacks: state.cartItemsPacks.filter((x) => x.productId !== action.payload),
			// 				}

			// 			}
			//}
		},
		cartPacks_Remove: (state, action) => {
			return {
				...state,
				cartItemsPacks: state.cartItemsPacks.filter(
					(x) => x.productId !== action.payload
				),
			}
		},
		cartPacks_Clear_Items: (state, action) => {
			return {
				...state,
				cartItemsPacks: [],
			}
		},
	},
})

export const {
	cartPacks_Add_Item,
	cartPacks_Remove_Item,
	cartPacks_Remove,
	cartPacks_Clear_Items,
} = cartPacksSlice.actions
export default cartPacksSlice.reducer
