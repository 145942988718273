/* eslint-disable no-lone-blocks */
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { useNavigate, useLocation } from 'react-router-dom'
import requests from '../../Components/AxiosCalls/AxiosAuth'
import { UPDATE_PASSWORD } from '../../Constants/routesConstants'
import { useForm } from 'react-hook-form'
import { HOME } from '../../Constants/routesConstants'

const RedditTextField = styled((props) => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'label + &': {
		marginTop: theme.spacing(3),
	},
	'& .MuiInputBase-input': {
		fontSize: window.screen.width <= 600 ? 13 : 18,
		padding: window.screen.width <= 600 ? '20px 7px 3px' : null,
	},
	'& label.Mui-focused': {
		color: '#545454',
		fontWeight: '600',
	},
	'& label': {
		color: '#545454',
		fontWeight: '600',
		fontSize: window.screen.width <= 600 ? 13 : 18,
	},
	'& .MuiFilledInput-root': {
		border: 0,
		overflow: 'hidden',
		borderRadius: 4,
		backgroundColor: '#0000000f',
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),

		'&:hover': {
			// backgroundColor: 'transparent',
		},
		'&.Mui-focused': {
			backgroundColor: '#0000000f',
			boxShadow: 0,
			borderColor: '#0000000f',
		},
	},
}))

const ResetPassword = (props) => {
	let navigate = useNavigate()
	const [t, i18n] = useTranslation('global')
	const valores = useLocation()
	var url = valores.search
	const str = url.slice(8)

	const methods = useForm()
	const { handleSubmit } = methods

	const [changePas, setChangePas] = useState({
		codeID: str,
		code: '',
		password: '',
		confirmPassword: '',
	})

	const handleChangePassword = (event) => {
		setChangePas({
			...changePas,
			[event.target.name]: event.target.value,
		})
	}
	/*navegacion */
	const navigateHome = () => {
		navigate(HOME)
	}

	const changePassword = () => {
		props.setOpenLoadingScreen()
		requests
			.post(UPDATE_PASSWORD, changePas)
			.then((response) => {
				props.setTypeSnackBar('success')
				{
					i18n.language === 'En'
						? props.setMessageSnackBar(response.messageEN)
						: props.setMessageSnackBar(response.messageES)
				}
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				navigateHome()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				{
					i18n.language === 'En'
						? props.setMessageSnackBar(error.data.messageEN)
						: props.setMessageSnackBar(error.data.messageES)
				}
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<div className='ContainerForgotPassword animated fadeIn fast w50 center'>
			<p className='text-center font-weight6 fs25 toUpperCase mt5'>
				{t('Profile.Reset')}
			</p>
			<Box
				component='form'
				noValidate
				onSubmit={handleSubmit(changePassword)}
				sx={{ mb: 5 }}
			>
				<div className='flex-column mt3'>
					<RedditTextField
						value={changePas.code}
						required
						className='TextFieldReset'
						variant='filled'
						focused
						label={t('Password.Code')}
						id='code'
						name='code'
						onChange={handleChangePassword}
					/>
					<RedditTextField
						value={changePas.password}
						required
						variant='filled'
						focused
						label={t('Password.Pass')}
						className='TextFieldReset'
						id='password'
						name='password'
						onChange={handleChangePassword}
					/>
					<RedditTextField
						value={changePas.confirmPassword}
						required
						variant='filled'
						focused
						label={t('Password.ConfirmPass')}
						className='TextFieldReset'
						id='confirmPassword'
						name='confirmPassword'
						onChange={handleChangePassword}
					/>
				</div>
				<div className='flex mt3'>
					<Button className='btn-profile' variant='contained' type='submit'>
						{t('Profile.BtnSaveChanges')}
					</Button>
				</div>
			</Box>
		</div>
	)
}

export default ResetPassword
