import React, { useEffect, useState,Suspense,lazy } from 'react'
import '../../Styles/Profile/TableBilling.css'
import Paper from '@mui/material/Paper'
// import { format, compareAsc } from 'date-fns'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
// import CloseIcon from '@mui/icons-material/Close'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PurchaseDetail from '../Modals/PurchaseDetail'
import { useTranslation } from 'react-i18next'
import requests from '../AxiosCalls/AxiosCall'
import {
	// ORDER_LIST_BY_ID_CLIENT,
	// BILLS_BY_ID_ORDER,
	ORDER_LIST_BY_ID_CLIENT_STATUS,
} from '../../Constants/routesConstants'
import { useSelector } from 'react-redux'
import { NumericFormat } from 'react-number-format'
import { styled } from '@mui/material/styles'
import SkeletonTableBilling from '../Skeleton/SkeletonTableBilling'
// import InvoiceBilling from '../Modals/InvoiceBilling'
import ModaRefusedInboice from '../../Components/Modals/ModaRefusedInboice'
import AlertInfo from '../../Components/Alerts/AlertInfo'

const InvoiceBilling=lazy(()=>import('../Modals/InvoiceBilling'))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		// backgroundColor: theme.palette.common.white,
		// color: '#545454',
		backgroundColor: '#544331',
		color: '#FFFFFF',
		textTransform: 'uppercase',
		fontWeight: '600',
		fontSize: window.screen.width <= 600 ? 9 : null,
		border: 0,
	},
	[`&.${tableCellClasses.body}`]: {
		color: '#545454',
		padding: window.screen.width <= 600 ? 13 : null,
		fontSize: window.screen.width <= 600 ? 8 : null,
		border: 0,
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}))

const TableBilling = (props) => {
	const [t, i18n] = useTranslation('global')
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(5)
	const [openViewPurchases, setOpenViewPurchases] = useState(false)
	const [openViewInvoice, setOpenViewInvoice] = useState(false)
	const [openRefusedInboice, setOpenRefusedInboice] = useState(false)
	const [BillingId, setBillingId] = useState(null)
	const [response, setResponse] = useState(false)
	const userLogin = useSelector((state) => state.user)
	const { userInfo } = userLogin
	const [_Registros, setRegistros] = useState([])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const handleOpenViewPurchases = (id) => {
		if (openViewPurchases === false) {
			setOpenViewPurchases(!openViewPurchases)
			setBillingId(id)
		} else {
			setOpenViewPurchases(!openViewPurchases)
			setBillingId(null)
		}
	}
	const handleOpenViewInvoice = (id) => {
		if (openViewInvoice === false) {
			setOpenViewInvoice(!openViewInvoice)

			setBillingId(id)
		} else {
			setOpenViewInvoice(!openViewInvoice)
			setBillingId(null)
		}
	}

	const handleRefusedInboice = (id) => {
		if (openRefusedInboice === false) {
			setOpenRefusedInboice(!openRefusedInboice)
			setBillingId(id)
		} else {
			setOpenRefusedInboice(!openRefusedInboice)
			setBillingId(null)
		}
	}

	const columns = [
		{
			id: 'order',
			label: t('Profile-Billing.Order'),
			minWidth: window.screen.width <= 600 ? 45 : 80,
		},
		{
			id: 'date',
			label: t('Profile-Billing.Date'),
			minWidth: window.screen.width <= 600 ? 40 : 100,
		},
		{
			id: 'name',
			label: t('Profile-Billing.Name'),
			minWidth: window.screen.width <= 600 ? 65 : 100,
		},
		{
			id: 'total',
			label: t('Profile-Billing.Total'),
			minWidth: window.screen.width <= 600 ? 40 : 90,
		},
		{
			id: 'icondetail',
			label: '',
			minWidth: window.screen.width <= 600 ? 40 : 90,
		},
		{
			id: 'iconinvoice',
			label: '',
			minWidth: window.screen.width <= 600 ? 40 : 90,
		},
	]

	function createData(order, date, name, total, icondetail, iconinvoice) {
		return { order, date, name, total, icondetail, iconinvoice }
	}

	const getRegistros = () => {
		setResponse(false)
		requests
			.get(ORDER_LIST_BY_ID_CLIENT_STATUS + userInfo.id)
			.then((response) => {
				setRegistros(response)
				setResponse(true)
				setBillingId(null)
			})
			.catch((error) => {
				setResponse(true)
			})
	}
	useEffect(() => {
		getRegistros()
	}, [])

	//	:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::	ABRIR ALERTA DE SELECCIONAR HORA DE LLEGADA
	const [openAlert, setOpenAlert] = useState(false)
	const [mensajeAlert, setMensajeAlert] = useState('')
	const [typeAlert, setTypeAlert] = useState('')
	const handleOpenAlert = () => {
		setOpenAlert(!openAlert)
	}

	// LAs facturas solicitados los ultimos 3 dias, deshabilitar la regla de los primeros 2 dias.
	// 1	CREADO
	// 2	SOLICITADO
	// 3	APROBADO
	// 4	RECHAZADO
	// 5	PENDIENTE
	// 6	COMPLETADO
	// 7	EXPIRADO
	return (
		<div className='ContainerTableBilling animated fadeIn fast'>
			<p className='text-center font-weight6 my1'>
				{t('Profile-Billing.Title')}
			</p>
			<Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
				{_Registros.length === 0 ? (
					<>
						{response === false ? (
							<SkeletonTableBilling />
						) : (
							<p className='text-center font-weight6 my1'>
								{t('Profile-Billing.NoitemsPurchase')}
							</p>
						)}
					</>
				) : (
					<TableContainer sx={{ maxHeight: 500 }}>
						<Table stickyHeader aria-label='sticky table'>
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<StyledTableCell
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
										>
											{column.label}
										</StyledTableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{_Registros
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										return (
											<StyledTableRow
												hover
												role='checkbox'
												tabIndex={-1}
												key={index}
												className='font-weight6'
											>
												<StyledTableCell>{row.txtFolio}</StyledTableCell>
												<StyledTableCell>{row.fecCompra}</StyledTableCell>
												<StyledTableCell>{row.espacio}</StyledTableCell>
												<StyledTableCell>
													<NumericFormat
														value={row.dblTotal}
														displayType={'text'}
														fixedDecimalScale={true}
														decimalScale={2}
														thousandSeparator={true}
														prefix={' $'}
													/>
												</StyledTableCell>

												<StyledTableCell>
													<Button
														onClick={function () {
															handleOpenViewPurchases(row.lngIdOrdenCompra)
														}}
														size='small'
														className='btn-table-billing'
														startIcon={<VisibilityOutlinedIcon />}
													>
														{t('Profile-Billing.Btn-Detail')}
													</Button>
												</StyledTableCell>

												{/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
												<StyledTableCell>
													{/* CREADO    ----	Retorna un mensaje de podras solicitar tu facturas en 2 dias */}
													{row.intIdStatusParaFact === 1 ? (
														<Button
															onClick={function () {
																handleOpenAlert()
																setTypeAlert('warning')
																setMensajeAlert(
																	t('Profile-Billing.MsjInboiceCreate')
																)
															}}
															size='small'
															className='btn-table-Create'
															startIcon={<HelpOutlineIcon />}
														>
															{t('Profile-Billing.Btn-Create')}
														</Button>
													) : null}

													{/* SOLICITADO    ----	Retorna un mensaje de podras solicitar tu facturas en 2 dias */}
													{row.intIdStatusParaFact === 2 ? (
														<Button
															onClick={function () {
																handleOpenAlert()
																setTypeAlert('info')
																setMensajeAlert(
																	t('Profile-Billing.MsjInboiceRequested')
																)
															}}
															size='small'
															className='btn-table-Create'
															startIcon={<HelpOutlineIcon />}
														>
															{t('Profile-Billing.Btn-Solicitado')}
														</Button>
													) : null}

													{/* APROBADO ---- Primera vez que se crea la orden de compra -- Aprobado*/}
													{row.intIdStatusParaFact === 3 ? (
														<Button
															onClick={function () {
																handleOpenViewInvoice(row.lngIdOrdenCompra)
															}}
															size='small'
															className='btn-table-Invoice'
															startIcon={<DescriptionOutlinedIcon />}
														>
															{t('Profile-Billing.Btn-Invoice')}
														</Button>
													) : null}

													{/* RECHAZADO	----	Solicitud de Factura rechazadas */}
													{row.intIdStatusParaFact === 4 ? (
														<Button
															onClick={function () {
																handleRefusedInboice(row.lngIdOrdenCompra)
															}}
															size='small'
															className='btn-table-Rechazado'
															startIcon={<HelpOutlineIcon />}
														>
															{t('Profile-Billing.Btn-Rechazado')}
														</Button>
													) : null}

													{/* PENDIENTE	----	Ya se encuentra en desarrollo la facturacion */}
													{row.intIdStatusParaFact === 5 ? (
														<Button
															onClick={function () {
																handleOpenAlert()
																setTypeAlert('info')
																setMensajeAlert(
																	t('Profile-Billing.MsjProcessing')
																)
															}}
															size='small'
															className='btn-table-Pendiente'
															startIcon={<HourglassTopIcon />}
														>
															{t('Profile-Billing.Btn-Pendiente')}
														</Button>
													) : null}

													{/* COMPLETADO	----	Factura Generado y terminado */}
													{row.intIdStatusParaFact === 6 ? (
														<Button
															onClick={function () {
																handleOpenAlert()
																setTypeAlert('success')
																setMensajeAlert(
																	t('Profile-Billing.MsjInboiceSuccess')
																)
															}}
															size='small'
															className='btn-table-Completado'
															startIcon={<CheckCircleOutlineIcon />}
														>
															{t('Profile-Billing.Btn-Completado')}
														</Button>
													) : null}

													{/* EXPIRADO	----	Factura Generado y terminado */}
													{row.intIdStatusParaFact === 7 ? (
														<Button
															onClick={function () {
																handleOpenAlert()
																setTypeAlert('success')
																setMensajeAlert(
																	t('Profile-Billing.MsjInboiceExpired')
																)
															}}
															size='small'
															className='btn-table-Expired'
															startIcon={<CheckCircleOutlineIcon />}
														>
															{t('Profile-Billing.Btn-Expirado')}
														</Button>
													) : null}
												</StyledTableCell>
											</StyledTableRow>
										)
									})}
							</TableBody>
						</Table>
					</TableContainer>
				)}
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component='div'
					count={_Registros.length}
					// count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

			<PurchaseDetail
				BillingId={BillingId}
				open={openViewPurchases}
				onClose={handleOpenViewPurchases}
			/>

			<Suspense fallback={<div></div> }>
			
				<InvoiceBilling
					BillingId={BillingId}
					open={openViewInvoice}
					onClose={handleOpenViewInvoice}
					props={props}
					getRegistros={getRegistros}
				/>
			</Suspense>

			{BillingId !== null ? (
				<ModaRefusedInboice
					BillingId={BillingId}
					open={openRefusedInboice}
					onClose={handleRefusedInboice}
					props={props}
					getRegistros={getRegistros}
				/>
			) : null}

			<AlertInfo
				severity={typeAlert}
				variant='filled'
				mensaje={mensajeAlert}
				open={openAlert}
				setOpen={handleOpenAlert}
			/>
		</div>
	)
}

export default TableBilling
