import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	loading: false,
	users: '',
	error: '',
}
export const userListSlice = createSlice({
	name: 'userList',
	initialState,
	reducers: {
		user_List_Init: (state) => {
			return { ...state, loading: true }
		},
		user_List_Succes: (state, action) => {
			return { ...state, loading: false, users: action.payload }
		},
		user_List_Failt: (state, action) => {
			return { ...state, loading: false, error: action.payload }
		},
		user_List_Auth: (state) => {
			return { ...state, users: [] }
		},
	},
})

export const {
	user_List_Init,
	user_List_Succes,
	user_List_Failt,
	user_List_Auth,
} = userListSlice.actions
export default userListSlice.reducer
